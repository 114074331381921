import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { getDefaultLanguageFromData } from '../../utils/functions';
import { AUTOKEY_REDIRECT_URL, getItemLocalStorage } from '../../utils/helper';

const SimulationError = () => {
  const { theme } = useTheme();
  const defaultLanguage = getDefaultLanguageFromData(theme);
  const textObject = theme.error_page_sections.texts;
  const navigate = useNavigate();

  const handleErrorNavigation = () => {
    const storedURL = getItemLocalStorage(AUTOKEY_REDIRECT_URL);
    if (storedURL === null || storedURL === undefined) {
      navigate('/bio');
      return;
    }
    window.location.href = storedURL;
  };

  return (
    <Fragment>
      <div className="success-screen d-flex flex-column align-items-center justify-content-center col-xxl-6 col-xl-6 col-md-7 col-sm-10 col-12 m-auto">
        <div className="success-img">
          <img
            src={require('../../assets/frontend/images/Vector_Error.png')}
            alt=""
          />
        </div>
        <h4 className="success-heading">
          {textObject[theme.error_page_sections.title][defaultLanguage]}
        </h4>
        <p className="success-text">
          {textObject[theme.error_page_sections.description][defaultLanguage]}
        </p>
        <button
          onClick={handleErrorNavigation}
          className="btn success-button d-block"
        >
          {
            textObject[theme.error_page_sections.buttons[0].text][
              defaultLanguage
            ]
          }
        </button>
      </div>
    </Fragment>
  );
};

export default SimulationError;
