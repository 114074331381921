const RefreshRateButton = ({
  customClassName,
  activeTextColor,
  refreshing,
  refreshRate,
}) => {
  return (
    <span
      className={
        (refreshing
          ? 'refresh-button-transparent-border'
          : 'refresh-button-border') +
        ' ' +
        (customClassName ? customClassName : '')
      }
    >
      <button
        className={'refresh-button'}
        type="button"
        style={{ color: refreshing ? 'inherit' : activeTextColor }}
        disabled={refreshing}
        onClick={() => refreshRate()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.8334 1.9834C3.30284 1.9834 3.6834 2.36396 3.6834 2.8334V4.11085C4.87476 2.80411 6.59107 1.9834 8.50006 1.9834C11.8239 1.9834 14.5654 4.47108 14.9664 7.68655C15.0244 8.15239 14.6939 8.57711 14.2281 8.6352C13.7622 8.69329 13.3375 8.36274 13.2794 7.89691C12.9832 5.52177 10.9557 3.6834 8.50006 3.6834C6.96352 3.6834 5.59387 4.40304 4.71149 5.52506H6.37506C6.84451 5.52506 7.22506 5.90562 7.22506 6.37506C7.22506 6.84451 6.84451 7.22506 6.37506 7.22506H2.8334C2.36396 7.22506 1.9834 6.84451 1.9834 6.37506V2.8334C1.9834 2.36396 2.36396 1.9834 2.8334 1.9834ZM2.77206 8.36493C3.23789 8.30684 3.66262 8.63738 3.7207 9.10322C4.01688 11.4783 6.04444 13.3167 8.50006 13.3167C10.0366 13.3167 11.4062 12.5971 12.2886 11.4751H10.6251C10.1556 11.4751 9.77506 11.0945 9.77506 10.6251C9.77506 10.1556 10.1556 9.77506 10.6251 9.77506H14.1667C14.6362 9.77506 15.0167 10.1556 15.0167 10.6251V14.1667C15.0167 14.6362 14.6362 15.0167 14.1667 15.0167C13.6973 15.0167 13.3167 14.6362 13.3167 14.1667V12.8893C12.1254 14.196 10.4091 15.0167 8.50006 15.0167C5.17623 15.0167 2.43473 12.529 2.03377 9.31358C1.97568 8.84774 2.30622 8.42302 2.77206 8.36493Z"
            fill="currentColor"
          />
        </svg>
        {refreshing ? 'Recalculando' : 'Recalcular'}
      </button>
    </span>
  );
};

export default RefreshRateButton;
