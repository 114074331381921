import withFormLayout from '../HOC/withFormLayout';
import {
  setOptionForRender,
  getOptionsFromAppropriateSource,
  isNullOrUndefined,
} from '../utils';

const SelectInput = ({
  //mandatory
  inputSpecs,
  value,
  placeholder,
  onChange,
  optionData,
  optionLabel,
  //optional
  optionsArray,
  isDisabled,
  onBlur,
  //comes from HOC
  namespace,
  styles,
  inputRef,
}) => {
  const options = getOptionsFromAppropriateSource(
    inputSpecs,
    optionsArray,
    namespace
  );

  if (isNullOrUndefined(options)) return <></>;

  return (
    <div className="d-flex  flex-column">
      <select
        className={`form-select ${styles}`}
        ref={inputRef}
        name={namespace}
        id={namespace}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => {
          const { data, label } = setOptionForRender(
            option,
            optionData,
            optionLabel
          );
          return (
            <option key={data} value={data}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default withFormLayout(SelectInput);
