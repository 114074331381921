import { Modal } from 'react-bootstrap';
import { useTheme } from '../../../contexts/ThemeContext';
import { useEffect } from 'react';

const FormModal = ({ open, closeModal, FormComponent }) => {
  const { theme } = useTheme();

  const setCssVariables = (theme) => {
    const root = document.documentElement;
    for (const [key, value] of Object.entries(theme.colors)) {
      root.style.setProperty(key, value);
    }
    for (const [key, value] of Object.entries(theme.fonts)) {
      root.style.setProperty(key, value);
    }
    root.style.setProperty(
      '--border-radius-button',
      theme.border_radius.button
    );
  };

  useEffect(() => {
    setCssVariables(theme);
  }, [theme]);

  return (
    <Modal
      show={open}
      backdrop="static"
      keyboard={false}
      animation={false}
      centered
      onHide={closeModal}
    >
      <Modal.Header
        className="d-flex flex-column align-items-center"
        closeButton
      ></Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center form-modal-container">
        <FormComponent />
      </Modal.Body>
    </Modal>
  );
};

export default FormModal;
