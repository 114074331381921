import { useTheme } from '../../../../contexts/ThemeContext';
import RefreshRateButton from './RefreshRateButton';

const ProductHeader = ({
  isSelected,
  setSelectedProduct,
  productInfo,
  setTotalAmount,
  hasProperRate,
  refreshing,
  refreshRate,
}) => {
  const { theme } = useTheme();
  const {
    colors: { '--color-radiobuttons-checkboxes': UIColor },
  } = theme;
  const textRefobject = theme.form_sections.texts;
  const defaultLanguage = theme.default_language;
  const secondStepSpecs = theme.form_sections.steps_section.steps.sort(
    (a, b) => a.order_index - b.order_index
  )[1];
  const headerStyle = {
    backgroundColor: isSelected
      ? 'var(--color-radiobuttons-checkboxes)'
      : 'var(--color-grey400)',
  };

  const CheckMarkSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className="selected-product-checkmark"
    >
      <circle cx="7" cy="7" r="7" fill="white" />
      <path
        d="M3.5 6L6.2 9L11 5"
        stroke={UIColor}
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );

  const { key, name, rate, frequency, secondInstallment } = productInfo;
  const showSecondInstallment = secondInstallment > 0 && hasProperRate;

  return (
    <th
      className="d-flex flex-column justify-content-center align-items-center coverage-header"
      style={headerStyle}
      onClick={(e) => {
        if (e.target.closest('.refresh-button')) return;
        setSelectedProduct(key);
        setTotalAmount(rate);
      }}
    >
      {isSelected ? <CheckMarkSVG /> : null}
      <p className="m-0 coverage-header-title">{name}</p>
      {hasProperRate ? (
        <p className="m-0 coverage-header-price-tag">{`${rate} €`}</p>
      ) : (
        <RefreshRateButton
          activeTextColor={UIColor}
          refreshing={refreshing}
          refreshRate={refreshRate}
        />
      )}
      <p className="m-0 coverage-header-frequency-label">{frequency}</p>
      {showSecondInstallment ? (
        <p className="m-0 coverage-header-frequency-label">
          {textRefobject[secondStepSpecs.installment][defaultLanguage]}
          <span style={{ fontFamily: 'Bold' }}>{` ${secondInstallment} `}</span>
          €
        </p>
      ) : null}
    </th>
  );
};

export default ProductHeader;
