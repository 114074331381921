import noOfferAvailable from '../assets/frontend/images/no_offer_available.png';
import errorOnSubscription from '../assets/frontend/images/error_on_subscription.png';
import bullet01 from '../assets/frontend/images/leadBioSvgs/BULLET_01.svg';
import bullet02 from '../assets/frontend/images/leadBioSvgs/BULLET_02.svg';
import bullet03 from '../assets/frontend/images/leadBioSvgs/BULLET_03.svg';
import bullet04 from '../assets/frontend/images/leadBioSvgs/BULLET_04.svg';

export const validation = {
  mandatory: [{ regex: /\S/, message: 'error_message_mandatory' }],
  driver_nif: [
    {
      regex: /^$|^(1\d{8}|2\d{8}|3\d{8}|5\d{8}|70\d{7}|74\d{7}|75\d{7})$/,
      message: 'error_message_invalid_nif',
    },
  ],
  driver_name: [
    {
      regex: /^$|^[a-zA-ZáàâãéèêíìîóòôõúùûÁÀÂÃÉÈÊÍÌÎÓÒÔÕÚÙÛçÇ' ]*$/,
      message: 'error_message_text_only',
    },
    { regex: /^$|^.{0,40}$/, message: 'error_message_max_forty' },
    { regex: /^$|\b\w+\b.*\b\w+\b/, message: 'error_message_full_name' },
  ],
  driver_address: [
    { regex: /^$|^.{0,40}$/, message: 'error_message_max_forty' },
  ],
  name: [
    { regex: /^$|^.{0,40}$/, message: 'error_message_max_forty' },
    { regex: /^$|\b\w+\b.*\b\w+\b/, message: 'error_message_full_name' },
  ],
  nif: [
    {
      regex: /^$|^(1\d{8}|2\d{8}|3\d{8}|5\d{8}|70\d{7}|74\d{7}|75\d{7})$/,
      message: 'error_message_invalid_nif',
    },
  ],
  address: [{ regex: /^$|^.{0,40}$/, message: 'error_message_max_forty' }],

  email: [
    {
      regex:
        /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'error_message_email_only',
    },
  ],
  driver_email: [
    {
      regex:
        /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'error_message_email_only',
    },
  ],
  client_email: [
    {
      regex:
        /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'error_message_email_only',
    },
  ],
};

export const errorMessages = {
  error_message_mandatory: 'Este campo é obrigatório',
  error_message_text_only: 'Este campo só aceita texto',
  error_message_max_forty: 'Este campo só aceita até 40 caracteres',
  error_message_full_name: 'Escreva o nome completo',
  error_message_email_only: 'Este campo só aceita email',
  error_message_invalid_nif: 'NIF inválido',
  error_message_fill_one: 'Por favor preencha pelo menos um campo',
};

export const errorModals = {
  no_offer_available: {
    image: noOfferAvailable,
    title:
      'Não nos é possivel apresentar uma oferta para a viatura em questão.',
    description:
      'Verifique se as informações estão correctas e tente novamente ou, contacte-nos através de:',
  },
  error_on_subscription: {
    image: errorOnSubscription,
    title:
      'Estamos com dificuldade na ligação técnica com a área de subscrição.',
    description: 'Tente mais tarde ou contacte-nos via: ',
  },
  none: {
    image: '',
    title: '',
    description: '',
  },
};

export const DISCOUNT_STATES = {
  SUCCESS: 'success',
  NOT_FOUND: 'notFound',
  BEST_OFFER: 'bestOffer',
};

export const LEAD_HARDCODED_BIO = {
  bio_sections: {
    texts: {
      bio_main_subtitle: {
        en: '',
        pt: 'Angariação de consentimento de cliente para receber informações de Seguro Auto',
      },
      bio_tiles_section_title: {
        en: '',
        pt: 'Angariação de forma simples e descomplicada em 4 passos:',
      },
      bio_tile_0_subtitle: {
        en: '',
        pt: 'Consentimento do Cliente',
      },
      bio_tile_0_info_text: {
        en: '',
        pt: 'Antes de iniciar deverá pedir o consentimento do cliente para a angariação de dados e o propósito dos mesmos',
      },
      bio_tile_1_subtitle: {
        en: '',
        pt: 'Preenchimento de Dados',
      },
      bio_tile_1_info_text: {
        en: '',
        pt: 'Preencher os dados, previamente autorizados e, fornecidos pelo cliente',
      },
      bio_tile_2_subtitle: {
        en: '',
        pt: 'Habit Envia Angariação',
      },
      bio_tile_2_info_text: {
        en: '',
        pt: 'A Habit, na função de mediador, envia por SMS e Email uma proposta de seguro ao cliente',
      },
      bio_tile_3_subtitle: {
        en: '',
        pt: 'Cliente Finaliza',
      },
      bio_tile_3_info_text: {
        en: '',
        pt: 'O cliente recebe a simulação e pode proceder ao pagamento ou alteração dos dados de simulação',
      },
      insurance_information: {
        en: '',
        pt: '',
      },
      legal_information_title: {
        en: '',
        pt: '',
      },
      legal_information_text: {
        en: '',
        pt: '',
      },
    },
    tiles_section: {
      section_title: 'bio_tiles_section_title',
      tiles: [
        {
          order_index: 0,
          tile_type: 'half_width',
          tile_icon: 'bio_tile_0_icon',
          tile_title: '',
          tile_subtitle: 'bio_tile_0_subtitle',
          tile_info_text: 'bio_tile_0_info_text',
          tile_bullet_points: [],
        },
        {
          order_index: 1,
          tile_type: 'half_width',
          tile_icon: 'bio_tile_1_icon',
          tile_title: '',
          tile_subtitle: 'bio_tile_1_subtitle',
          tile_info_text: 'bio_tile_1_info_text',
          tile_bullet_points: [],
        },
        {
          order_index: 2,
          tile_type: 'half_width',
          tile_icon: 'bio_tile_2_icon',
          tile_title: '',
          tile_subtitle: 'bio_tile_2_subtitle',
          tile_info_text: 'bio_tile_2_info_text',
          tile_bullet_points: [],
        },
        {
          order_index: 3,
          tile_type: 'half_width',
          tile_icon: 'bio_tile_3_icon',
          tile_title: '',
          tile_subtitle: 'bio_tile_3_subtitle',
          tile_info_text: 'bio_tile_3_info_text',
          tile_bullet_points: [],
        },
      ],
    },
  },
  images: {
    bio_tile_0_icon: bullet01,
    bio_tile_1_icon: bullet02,
    bio_tile_2_icon: bullet03,
    bio_tile_3_icon: bullet04,
  },
};
