import { useEffect, useMemo, useRef } from 'react';
import Skeleton from '../Inputs/Skeleton';
import { isNullOrUndefined, conditionalStyles } from '../utils';

const scrollInputWithErrorIntoView = (errorList, inputRef) => {
  if (
    errorList !== undefined &&
    errorList.length > 0 &&
    !isNullOrUndefined(inputRef.current)
  ) {
    inputRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

const InputLabel = ({ namespace, label, isoptional }) => {
  return (
    <label htmlFor={namespace}>{`${label}${
      isoptional === true ? ' ( Opcional )' : ''
    }`}</label>
  );
};

const withFormLayout = (WrappedInput) => {
  const GeneralFormLayoutComponent = (props) => {
    const inputRef = useRef(null);
    const { inputSpecs, value, isoptional, error, readOnly, noLabel } = props;
    const { namespace, label } = inputSpecs || { namespace: '', label: '' };
    const errorList = useMemo(
      () => error?.[namespace] ?? [],
      [error, namespace]
    );
    const hasError = errorList.length > 0;

    useEffect(() => {
      if (isNullOrUndefined(inputSpecs)) return;
      scrollInputWithErrorIntoView(errorList, inputRef);
    }, [inputSpecs, errorList]);

    const isLoading = isNullOrUndefined(inputSpecs);
    if (isLoading) return <Skeleton />;

    const hasLabel = !(noLabel === true) && !isNullOrUndefined(label);
    const styles = conditionalStyles(value, hasError, readOnly);

    return (
      <div className="d-flex flex-column flex-grow-1">
        {hasLabel && (
          <InputLabel
            namespace={namespace}
            label={label}
            isoptional={isoptional}
          />
        )}
        <WrappedInput
          {...props}
          inputRef={inputRef}
          styles={styles}
          namespace={namespace}
        />{' '}
        {hasError
          ? errorList?.map((error, index) => (
              <label key={index} className="error-color">
                {error}
              </label>
            ))
          : null}
      </div>
    );
  };
  return GeneralFormLayoutComponent;
};

export default withFormLayout;
