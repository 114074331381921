import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/bio');
  }, []);

  return <h1>Home Page</h1>;
};

export default Home;
