import React from 'react';
import InputMask from 'react-input-mask';
import { ReactComponent as CloseInput } from './SVGs/CloseInput.svg';

const RegistrationInput = ({
  index,
  value,
  onChange,
  closeInput,
  needsRefreshing,
}) => {
  const hasFullValue = value && !value.includes('_');
  return (
    <div className="registration-input">
      <label htmlFor={`registration${index + 1}`}>{index + 1}ª Matrícula</label>
      <div className="input-and-close">
        <InputMask
          name={`registration${index + 1}`}
          className={`me-auto${value ? ' has-value' : ''}`}
          disabled={!needsRefreshing && hasFullValue}
          value={value}
          mask={'**-**-**'}
          onChange={onChange}
          placeholder={'XX-XX-XX'}
        />
        <CloseInput className="close-input-button" onClick={closeInput} />
      </div>
    </div>
  );
};

export default RegistrationInput;
