import PhoneInputComponent from 'react-phone-number-input';
import withFormLayout from '../HOC/withFormLayout';

const PhoneInput = ({
  //mandatory
  value,
  onChange,
  //optional
  isoptional,
  //comes from HOC
  namespace,
  styles,
}) => {
  return (
    <PhoneInputComponent
      className={`form-control ${styles}`}
      defaultCountry={'PT'}
      international={false}
      options={['PT']}
      name={namespace}
      placeholder="000 000 000"
      value={value}
      onChange={onChange}
      isoptional={isoptional}
    />
  );
};

export default withFormLayout(PhoneInput);
