import { ThreeDots } from 'react-loader-spinner';

const Loading = ({ isLoading, dotColor }) => {
  return (
    <>
      {isLoading ? (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color={dotColor ? dotColor : '#ffffff'}
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      ) : null}
    </>
  );
};

export default Loading;
