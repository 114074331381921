import {
  setItemLocalStorage,
  getItemLocalStorage,
  removeItemLocalStorage,
  clearLocalStorage,
  AUTOKEY_TYPE,
  AUTOKEY_ACCESSTOKEN,
  AUTOKEY_DISTRIBUTORSELLERID,
  AUTOKEY_DISTRIBUTIONPOINTID,
  AUTOKEY_REGISTRATION,
  AUTOKEY_SALESCHANNEL,
  AUTOKEY_INVITE,
  AUTOKEY_QUOTEID,
  AUTOKEY_COUNTER,
  AUTOKEY_MBPAYMENT,
  AUTOKEY_VEHICLE_DATA,
  AUTOKEY_X_FINGERPRINT,
  AUTOKEY_CONTRIBUTIONID,
} from '../../utils/helper';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Banner from './bio/BioBanner';
import Body from './bio/BioBody';
import Footer from './bio/BioFooter';
import Loading from './shared/Loading';
import { useTheme } from '../../contexts/ThemeContext';
import { LEAD_HARDCODED_BIO } from '../../utils/constants';

const Bio = () => {
  const { theme, setTheme } = useTheme();
  const { CONFIG } = theme;
  const { PROXY_PATH, VERSION } = CONFIG;
  const { state } = useLocation();
  getItemLocalStorage(AUTOKEY_TYPE);
  const [documents, setDocuments] = useState([]);
  const [insuranceProduct, setInsuranceProduct] = useState({});
  const [isLoader, setisLoader] = useState(false);
  getItemLocalStorage(AUTOKEY_DISTRIBUTIONPOINTID);
  getItemLocalStorage(AUTOKEY_DISTRIBUTORSELLERID);

  useEffect(() => {
    if (getItemLocalStorage(AUTOKEY_TYPE) === 'lead') setLeadTheme();
    if (state === null) {
      clearLocalStorage();
    }

    if (
      getItemLocalStorage(AUTOKEY_TYPE) === null ||
      getItemLocalStorage(AUTOKEY_TYPE) === undefined
    ) {
      setItemLocalStorage(AUTOKEY_TYPE, 'guest');
      authGuests();
    } else {
      if (getItemLocalStorage(AUTOKEY_TYPE) === 'guest') {
        removeItemLocalStorage(AUTOKEY_INVITE);
        removeItemLocalStorage(AUTOKEY_QUOTEID);
        removeItemLocalStorage(AUTOKEY_COUNTER);
        removeItemLocalStorage(AUTOKEY_MBPAYMENT);
        removeItemLocalStorage(AUTOKEY_ACCESSTOKEN);
        removeItemLocalStorage(AUTOKEY_VEHICLE_DATA);
        removeItemLocalStorage(AUTOKEY_REGISTRATION);
        removeItemLocalStorage(AUTOKEY_SALESCHANNEL);
        removeItemLocalStorage(AUTOKEY_X_FINGERPRINT);
        removeItemLocalStorage(AUTOKEY_CONTRIBUTIONID);
        removeItemLocalStorage(AUTOKEY_DISTRIBUTORSELLERID);
        removeItemLocalStorage(AUTOKEY_DISTRIBUTIONPOINTID);
        authGuests();
      } else {
        getQuoteSpecs(getItemLocalStorage(AUTOKEY_X_FINGERPRINT));
      }
    }
  }, []);

  const setLeadTheme = () => {
    setTheme({
      ...theme,
      bio_sections: {
        ...theme.bio_sections,
        tiles_section: LEAD_HARDCODED_BIO.bio_sections.tiles_section,
        texts: {
          ...theme.bio_sections.texts,
          ...LEAD_HARDCODED_BIO.bio_sections.texts,
        },
      },

      images: {
        ...theme.images,
        ...LEAD_HARDCODED_BIO.images,
      },
    });
  };

  const authGuests = () => {
    setisLoader(true);
    fetch(PROXY_PATH + '/auth-guests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setItemLocalStorage(AUTOKEY_X_FINGERPRINT, res.fingerprint);
        getQuoteSpecs(res.fingerprint);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getQuoteSpecs = (fingerprint_val) => {
    var headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getItemLocalStorage(AUTOKEY_ACCESSTOKEN),
      'X-FINGERPRINT': fingerprint_val,
    };

    if (getItemLocalStorage(AUTOKEY_TYPE) === 'guest') {
      headers = {
        'Content-Type': 'application/json',
        'X-FINGERPRINT': fingerprint_val,
      };
    }

    setisLoader(true);
    fetch(PROXY_PATH + '/versions/' + VERSION + '/quote-specs', {
      method: 'GET',
      headers: headers,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if ((res.code !== undefined) & (res.code === 9909)) {
          toast.error(res.text);
        } else {
          setInsuranceProduct(res.insurance_product);
          const documents = res.insurance_product.documents;
          setDocuments(documents.at_product_info);
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  return (
    <>
      <Loading isLoading={isLoader} />
      <Banner />
      <Body documents={documents} />
      <Footer insuranceProduct={insuranceProduct} />
    </>
  );
};

export default Bio;
