import { useState } from 'react';
import { useTheme } from '../../../../contexts/ThemeContext';

const CoverageLabel = ({
  label,
  description,
  isBaseCoverage,
  coverageState,
  productCode,
  handleCheckboxChange,
  refreshing,
  screenCut,
}) => {
  const { theme } = useTheme();
  const {
    colors: {
      '--color-secondary': secondaryColor,
      '--color-radiobuttons-checkboxes': UIColor,
    },
  } = theme;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const CheckMarkSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      className={`d-block d-${screenCut}-none me-2`}
      style={{ flexShrink: '0' }}
    >
      <path
        d="M3.54163 10.0776L6.37496 12.911L13.4583 5.82764"
        stroke="#4A5568"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const TooltipSVG = ({ fillColor }) => (
    <div
      className={`tooltip-container d-none d-${screenCut}-block ms-2 me-1`}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        style={{ flexShrink: '0' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49996 2.83335C5.37035 2.83335 2.83329 5.37041 2.83329 8.50002C2.83329 11.6297 5.37035 14.1667 8.49996 14.1667C11.6296 14.1667 14.1666 11.6297 14.1666 8.50002C14.1666 5.37041 11.6296 2.83335 8.49996 2.83335ZM1.41663 8.50002C1.41663 4.58801 4.58794 1.41669 8.49996 1.41669C12.412 1.41669 15.5833 4.58801 15.5833 8.50002C15.5833 12.4121 12.412 15.5834 8.49996 15.5834C4.58795 15.5834 1.41663 12.4121 1.41663 8.50002ZM7.79163 5.66669C7.79163 5.27549 8.10876 4.95835 8.49996 4.95835H8.50704C8.89824 4.95835 9.21538 5.27549 9.21538 5.66669C9.21538 6.05789 8.89824 6.37502 8.50704 6.37502H8.49996C8.10876 6.37502 7.79163 6.05789 7.79163 5.66669ZM7.08329 8.50002C7.08329 8.10882 7.40042 7.79169 7.79163 7.79169H8.49996C8.89116 7.79169 9.20829 8.10882 9.20829 8.50002V10.625C9.59949 10.625 9.91663 10.9422 9.91663 11.3334C9.91663 11.7246 9.59949 12.0417 9.20829 12.0417H8.49996C8.10876 12.0417 7.79163 11.7246 7.79163 11.3334V9.20835C7.40042 9.20835 7.08329 8.89122 7.08329 8.50002Z"
          fill={fillColor}
        />
      </svg>
      {isTooltipVisible && (
        <div className="tooltip-text-container">
          <p className="m-0">{description}</p>
        </div>
      )}
    </div>
  );

  return (
    <>
      <td
        className={`d-flex d-${screenCut}-none align-items-center justify-content-between description-cell"`}
      >
        {isBaseCoverage ? (
          <CheckMarkSVG />
        ) : !refreshing ? (
          <input
            className="optional-checkbox me-3"
            type="checkbox"
            checked={coverageState && coverageState.selected === true}
            onChange={() => {
              handleCheckboxChange(coverageState.code, productCode);
            }}
          />
        ) : null}
        <p className="m-0 me-auto">{label}</p>
        {description && (
          <TooltipSVG fillColor={isTooltipVisible ? secondaryColor : UIColor} />
        )}
      </td>
      <td
        className={`d-none d-${screenCut}-flex align-items-center justify-content-between description-cell-large`}
      >
        {isBaseCoverage ? (
          <CheckMarkSVG />
        ) : !refreshing ? (
          <input
            className="optional-checkbox-large me-3"
            type="checkbox"
            checked={coverageState && coverageState.selected === true}
            onChange={() => {
              handleCheckboxChange(coverageState.code, productCode);
            }}
          />
        ) : null}
        <p className="m-0 me-auto">{label}</p>
        {description && (
          <TooltipSVG fillColor={isTooltipVisible ? secondaryColor : UIColor} />
        )}
      </td>
    </>
  );
};

export default CoverageLabel;
