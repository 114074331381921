import withFormLayout from '../HOC/withFormLayout';

const NumberInput = ({
  namespace,
  placeholder,
  onChange,
  value,
  styles,
  onBlur,
  inputRef,
}) => {
  return (
    <input
      type="number"
      ref={inputRef}
      className={`form-control ${styles}`}
      id={namespace}
      name={namespace}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onWheel={(e) => e.target.blur()}
    />
  );
};

export default withFormLayout(NumberInput);
