const OpenEmailFormButton = ({ openModal }) => {
  const EnvelopeSVG = () => (
    <div className="me-2">
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.32031 4L8.70782 9.2604C9.33679 9.7083 10.1562 9.7083 10.7852 9.2604L18.1727 4M3.1928 15H16.3002C17.3343 15 18.1727 14.1046 18.1727 13V3C18.1727 1.89543 17.3343 1 16.3002 1H3.1928C2.15865 1 1.32031 1.89543 1.32031 3V13C1.32031 14.1046 2.15865 15 3.1928 15Z"
          stroke="#4F60D2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );

  return (
    <button
      type="button"
      className={
        'btn open-email-form-button d-flex flex-row align-items-center justify-content-center'
      }
      onClick={openModal}
    >
      {EnvelopeSVG()}Enviar simulação por email
    </button>
  );
};

export default OpenEmailFormButton;
