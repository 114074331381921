export const baseRequestHandler = (request) => async (params) => {
  try {
    const response = await request(params);
    return {
      code: 'success',
      data: response.data,
    };
  } catch (error) {
    return {
      code: 'error',
      error: error,
    };
  }
};
