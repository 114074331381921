import React from 'react';
import { Fragment } from 'react';

const PrivacyPolicy = () => {
    return (
        <Fragment>
            <h1>Privacy Page</h1>
        </Fragment>
    );
}



export default PrivacyPolicy;

