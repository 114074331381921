import { getDefaultLanguageFromData } from '../../../utils/functions';
import { useTheme } from '../../../contexts/ThemeContext';

const Footer = ({ insuranceProduct }) => {
  const { theme } = useTheme();
  const defaultLanguage = getDefaultLanguageFromData(theme);

  const productDisclaimer = insuranceProduct?.legal_disclaimers?.find(
    (disclaimer) => disclaimer.type === 'by_product'
  );
  // const globalDisclaimer = insuranceProduct.legal_disclaimers.find(
  //   (disclaimer) => disclaimer.type === 'global'
  // );

  if (productDisclaimer === undefined) return <></>;

  return (
    <>
      <div className="d-md-none global-disclaimer-mobile">
        {productDisclaimer.text}
        {/* {theme.bio_sections.texts.global_disclaimer[defaultLanguage]} */}
      </div>
      <div className="d-none d-md-block global-disclaimer">
        {productDisclaimer.text}
        {/* {theme.bio_sections.texts.global_disclaimer[defaultLanguage]} */}
      </div>
    </>
  );
};

export default Footer;
