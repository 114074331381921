import DatePickerComponent from 'react-date-picker';
import withFormLayout from '../HOC/withFormLayout';
import { clearErrorStateNamespace } from '../../../../utils/functions';

const DatePicker = ({
  // mandatory
  value,
  dateFormat,
  dateSetterFunction,
  setError,
  // optional
  readOnly,
  // comes from HOC
  styles,
  namespace,
}) => {
  const handleDateChange = (date) => {
    dateSetterFunction(date);
    setError((prevErrorState) =>
      clearErrorStateNamespace(prevErrorState, namespace)
    );
  };
  return (
    <DatePickerComponent
      className={`form-control ${styles}`}
      id={namespace}
      format={dateFormat}
      locale="pt"
      dayPlaceholder="dd"
      monthPlaceholder="mm"
      yearPlaceholder="yyyy"
      value={value}
      readOnly={readOnly}
      onChange={handleDateChange}
      openCalendarOnFocus={false}
    />
  );
};

export default withFormLayout(DatePicker);
