import { useState } from 'react';
import { addOpacityToHexColor } from '../../../../utils/functions';
import { useTheme } from '../../../../contexts/ThemeContext';
import { isNullOrUndefined } from '../utils';
import './coverageTable.css';
import StyledSelect from '../../shared/StyledSelect';

const VoidContent = ({ screenCut }) => {
  return <p className={`d-none d-${screenCut}-block m-0 void-dash`}>-</p>;
};

const CheckContent = ({ screenCut }) => {
  const { theme } = useTheme();
  const {
    colors: { '--color-secondary': secondaryColor },
  } = theme;

  const checkStyle = { width: '20px', height: '20px', flexShrink: '0' };
  return (
    <svg
      className={`d-none d-${screenCut}-block`}
      width="19"
      height="19"
      style={checkStyle}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 13C8.15 13 7.91667 12.8833 7.68333 12.65L5.35 10.3167C4.88333 9.85 4.88333 9.15 5.35 8.68333C5.81667 8.21667 6.51667 8.21667 6.98333 8.68333L8.5 10.2L12.35 6.35C12.8167 5.88333 13.5167 5.88333 13.9833 6.35C14.45 6.81667 14.45 7.51667 13.9833 7.98333L9.31667 12.65C9.08333 12.8833 8.85 13 8.5 13Z"
        fill={secondaryColor}
      />
    </svg>
  );
};
const SingleOptionContent = ({ option, screenCut }) => {
  return (
    <>
      <p
        className={`d-none d-${screenCut}-block m-0 single-option-content-large`}
      >
        {option?.value ? option.value : ''}
      </p>
      <p className={`d-block d-${screenCut}-none m-0 single-option-content`}>
        {option?.value ? option.value : ''}
      </p>
    </>
  );
};

const SelectContent = ({
  optionsArray,
  productData,
  optionCode,
  selectedCoverageData,
  setUICoveragesByQuote,
  coveragecodesFromSamePack,
  screenCut,
}) => {
  const [selectedCoverage, setSelectedCoverage] = useState(
    optionsArray.find((option) => option.id === selectedCoverageData)?.id
  );

  const formattedOptionsArray = optionsArray.map((option) => {
    return {
      label: option.value,
      data: option.id,
    };
  });

  const handleSelectChange = (newValue) => {
    setSelectedCoverage(newValue);
    setUICoveragesByQuote((previousState) => {
      const updatedState = { ...previousState };
      updatedState[productData] = updatedState[productData].map((coverage) => {
        if (coveragecodesFromSamePack?.includes(coverage.code) === true)
          return { ...coverage, [optionCode]: newValue };
        return coverage;
      });
      return updatedState;
    });
  };

  return (
    <>
      <StyledSelect
        optionsArray={formattedOptionsArray}
        value={selectedCoverage}
        setValue={handleSelectChange}
        fontSize={'14px'}
        screenCut={screenCut}
        customClass={`d-block d-${screenCut}-none`}
      />
      <StyledSelect
        optionsArray={formattedOptionsArray}
        value={selectedCoverage}
        setValue={handleSelectChange}
        fontSize={'12px'}
        screenCut={screenCut}
        customClass={`d-none d-${screenCut}-block`}
      />
    </>
  );
};

const CoverageCell = ({
  isLast,
  coverage,
  selected,
  coverageCode,
  productData,
  optionCode,
  selectedCoverageData,
  setUICoveragesByQuote,
  refreshing,
  cellsState,
  handleCheckboxChange,
  hasOptionalCheckbox,
  coveragecodesFromSamePack,
  screenCut,
}) => {
  const { theme } = useTheme();
  const {
    colors: { '--color-radiobuttons-checkboxes': UIColor },
  } = theme;

  const cellStyle = {
    backgroundColor: selected
      ? addOpacityToHexColor(UIColor, 15)
      : 'var(--color-grey100)',
    color: UIColor,
    borderBottomRightRadius: isLast ? '11px' : '0px',
    borderBottomLeftRadius: isLast ? '11px' : '0px',
  };

  const AdaptableContent = ({ coverage, refreshing, screenCut }) => {
    const hasCoverage = !(
      isNullOrUndefined(coverage) || isNullOrUndefined(coverage.options)
    );
    if (!hasCoverage) return <VoidContent screenCut={screenCut} />;

    const filteredOptions = coverage.options.filter(
      (option) => option.type === coverage.type
    );

    const onlyShowCheckmark = filteredOptions.length === 0;
    const singleOptionCoverage = filteredOptions.length === 1;
    const multipleOptionsCoverage = filteredOptions.length >= 2;

    if (onlyShowCheckmark)
      return cellsState?.selected ? (
        <CheckContent screenCut={screenCut} />
      ) : (
        <VoidContent screenCut={screenCut} />
      );

    if (singleOptionCoverage)
      return (
        <SingleOptionContent
          option={filteredOptions[0]}
          screenCut={screenCut}
        />
      );

    if (multipleOptionsCoverage)
      return !refreshing ? (
        <SelectContent
          optionsArray={filteredOptions}
          productData={productData}
          optionCode={optionCode}
          selectedCoverageData={selectedCoverageData}
          setUICoveragesByQuote={setUICoveragesByQuote}
          coveragecodesFromSamePack={coveragecodesFromSamePack}
          screenCut={screenCut}
        />
      ) : (
        <SingleOptionContent
          screenCut={screenCut}
          option={filteredOptions.find(
            (option) => option.id === selectedCoverageData
          )}
        />
      );
  };

  return (
    <>
      <td
        className={`d-none d-${screenCut}-flex justify-content-center align-items-center coverage-cell-large`}
        style={cellStyle}
      >
        {hasOptionalCheckbox && !refreshing ? (
          <input
            className="optional-checkbox-large"
            type="checkbox"
            checked={cellsState && cellsState.selected}
            onChange={() => handleCheckboxChange(coverageCode, productData)}
          />
        ) : null}
        <AdaptableContent
          coverage={coverage}
          refreshing={refreshing}
          cellsState={cellsState}
          coveragecodesFromSamePack={coveragecodesFromSamePack}
          screenCut={screenCut}
        />
      </td>

      <td
        className={`d-flex d-${screenCut}-none justify-content-end align-items-center w-100 coverage-cell`}
      >
        <AdaptableContent
          coverage={coverage}
          refreshing={refreshing}
          cellsState={cellsState}
          coveragecodesFromSamePack={coveragecodesFromSamePack}
          screenCut={screenCut}
        />
      </td>
    </>
  );
};

export default CoverageCell;
