import React from 'react';
import './RegistrationDiscount.css';
import AskDiscountPanel from './AskDiscountPanel';
import GetDiscountPanel from './GetDiscountPanel';

// Using the hook:
// import { useRegistrations } from './useRegistrations';
// const registrationsHook = useRegistrations()

const RegistrationDiscount = ({
  isRefreshing,
  refreshQuoteProperties,
  registrationsHook,
}) => {
  const {
    registrations,
    needsRefreshing,
    latestCalculation,
    discount,
    addRegistration,
    updateRegistration,
    createNewList,
    eraseList,
    closeInput,
  } = registrationsHook;

  return (
    <div className="license-plate-discount">
      {registrations.length === 0 ? (
        <AskDiscountPanel createNewList={createNewList} />
      ) : (
        <GetDiscountPanel
          registrations={registrations}
          discountResult={latestCalculation?.discountResult}
          discount={discount}
          updateRegistration={updateRegistration}
          addRegistration={addRegistration}
          refreshQuoteProperties={refreshQuoteProperties}
          eraseList={eraseList}
          closeInput={closeInput}
          needsRefreshing={needsRefreshing}
          isRefreshing={isRefreshing}
        />
      )}
    </div>
  );
};

export default RegistrationDiscount;
