export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const isEmpty = (value) => {
  if (typeof value === 'string') return value.trim() === '';
  return isNullOrUndefined(value);
};

export const convertToSymbol = (inputString) => {
  return inputString.replace(/<=/g, '≤');
};

export const getOptionsFromAppropriateSource = (
  inputSpecs,
  optionsArray,
  namespace
) => {
  if (!isNullOrUndefined(inputSpecs.options)) return inputSpecs.options;
  if (!isNullOrUndefined(optionsArray)) return optionsArray;
  console.error(
    `There are no valid options source for this input: ${namespace}`
  );
  return null;
};

export const setOptionForRender = (option, optionData, optionLabel) => {
  const isOptionAnObject = optionData !== '' || optionLabel !== '';
  return isOptionAnObject
    ? { data: option[optionData], label: option[optionLabel] }
    : { data: option, label: option };
};

export const conditionalStyles = (value, showRedBorder, readOnly) => {
  const selectedStyle = `${!isEmpty(value) ? 'selected' : ''}`;
  const invalidStyle = `${showRedBorder ? 'invalid' : ''}`;
  const readOnlyStyle = `${!isNullOrUndefined(readOnly) ? 'readOnly' : ''}`;

  return `${selectedStyle} ${invalidStyle} ${readOnlyStyle}`;
};

export const getTextFromLanguageArray = (textArray, globalLanguageState) => {
  if (isNullOrUndefined(textArray)) return '';

  const text = textArray.find((text) => text.language === globalLanguageState);
  return isNullOrUndefined(text) ? '' : text.data;
};
