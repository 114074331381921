import withFormLayout from '../HOC/withFormLayout';

const TextInput = ({
  //mandatory
  value,
  placeholder,
  onChange,
  //optional
  readOnly,
  isoptional,
  isDisabled,
  onBlur,
  //comes from HOC
  namespace,
  styles,
  inputRef,
}) => {
  return (
    <input
      type="text"
      className={`form-control ${styles}`}
      id={namespace}
      name={namespace}
      value={value}
      readOnly={readOnly}
      placeholder={placeholder}
      disabled={isDisabled}
      onChange={onChange}
      onBlur={onBlur}
      isoptional={isoptional}
      ref={inputRef}
    />
  );
};

export default withFormLayout(TextInput);
