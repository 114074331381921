import {
  getItemLocalStorage,
  AUTOKEY_MBPAYMENT,
  AUTOKEY_TYPE,
  AUTOKEY_REDIRECT_URL,
} from '../../utils/helper';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { getDefaultLanguageFromData } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

const SimulationSuccess = () => {
  const [mb, setMb] = useState({});

  const { theme } = useTheme();
  const defaultLanguage = getDefaultLanguageFromData(theme);
  const textObject = theme.success_page_sections.texts;
  const navigate = useNavigate();

  useEffect(() => {
    const mbPaymentData = JSON.parse(
      getItemLocalStorage(AUTOKEY_MBPAYMENT) ?? '{}'
    );
    const displayedProperties = ['entity', 'reference', 'amount'];
    if (displayedProperties.every((key) => mbPaymentData.hasOwnProperty(key)))
      setMb(mbPaymentData);
  }, []);

  const handleSuccessNavigation = () => {
    const userType = getItemLocalStorage(AUTOKEY_TYPE);
    const storedURL = getItemLocalStorage(AUTOKEY_REDIRECT_URL);
    if (storedURL === null || storedURL === undefined || userType === 'user') {
      navigate('/bio');
      return;
    }
    window.location.href = storedURL;
  };

  return (
    <Fragment>
      <div className="success-screen d-flex flex-column align-items-center justify-content-center col-xxl-6 col-xl-6 col-md-7 col-sm-10 col-12 m-auto">
        <div className="success-img">
          <img
            src={require('../../assets/frontend/images/Vector_(Stroke).png')}
            alt=""
          />
        </div>
        <h4 className="success-heading">
          {textObject[theme.success_page_sections.title][defaultLanguage]}
        </h4>
        {(mb.entity ?? false) &&
        (mb.reference ?? false) &&
        (mb.amount ?? false) ? (
          <p className="success-text">
            {/* hardcoded !!! */}
            Poderá efetuar o pagamento durante as próximas 48 horas.
          </p>
        ) : getItemLocalStorage(AUTOKEY_TYPE) !== 'lead' ? (
          <p className="success-text">
            {
              textObject[theme.success_page_sections.description][
                defaultLanguage
              ]
            }{' '}
            <br />
            <br />
            {
              textObject[theme.success_page_sections.acknowledgment][
                defaultLanguage
              ]
            }
          </p>
        ) : (
          ''
        )}

        {(mb.entity ?? false) &&
          (mb.reference ?? false) &&
          (mb.amount ?? false) && (
            <div className="shadow p-5 mt-3">
              <h2 style={{ fontSize: 19 + 'px' }}>
                {
                  textObject[theme.success_page_sections.pm_data.title][
                    defaultLanguage
                  ]
                }
              </h2>
              <ul className="refrence-items">
                <li className="mb-1">
                  <span>
                    {
                      textObject[
                        theme.success_page_sections.pm_data.entity_label
                      ][defaultLanguage]
                    }{' '}
                  </span>{' '}
                  <span className="fw-bold">{mb && mb.entity}</span>
                </li>
                <li className="mb-1">
                  <span>
                    {
                      textObject[
                        theme.success_page_sections.pm_data.reference_label
                      ][defaultLanguage]
                    }
                  </span>{' '}
                  <span className="fw-bold">{mb && mb.reference}</span>
                </li>
                <li className="mb-1">
                  <span>
                    {
                      textObject[
                        theme.success_page_sections.pm_data.price_label
                      ][defaultLanguage]
                    }
                  </span>{' '}
                  <span className="fw-bold">{mb && mb.amount} €</span>
                </li>
              </ul>
            </div>
          )}
        <button
          onClick={handleSuccessNavigation}
          className="btn success-button d-block"
        >
          {
            textObject[theme.success_page_sections.buttons[0].text][
              defaultLanguage
            ]
          }
        </button>
      </div>
    </Fragment>
  );
};

export default SimulationSuccess;
