import { useState } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';

const LanguageSelector = () => {
  const { theme, setTheme } = useTheme();
  const {
    default_language: defaultLanguage,
    supported_languages: supportedLanguages,
    colors: { '--color-secondary': secondaryColor },
  } = theme;

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
    setTheme({ ...theme, default_language: selectedLanguage });
    setIsOpen(false);
  };

  const arrowDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
    >
      <path d="M4 4L0.535899 0.249999L7.4641 0.25L4 4Z" fill={secondaryColor} />
    </svg>
  );

  const arrowUp = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
    >
      <path d="M4 0L0.535899 3.750001L7.4641 3.75L4 0Z" fill={secondaryColor} />
    </svg>
  );

  const ShowLanguageSelect = Object.keys(supportedLanguages).length > 1;

  return ShowLanguageSelect ? (
    <div className="shortened-select d-flex flex-column align-items-end">
      <div
        className={`selected-language d-flex flex-row align-items-center justify-content-center mb-2 ${
          isOpen ? 'opened' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{`${selectedLanguage.toUpperCase()}`}</div>
        <div>{isOpen ? arrowUp : arrowDown}</div>
      </div>
      {isOpen ? (
        <ul className="supported-languages-list">
          {Object.entries(supportedLanguages).map(([code, label]) => (
            <li
              className="d-flex flex-row"
              key={code}
              value={code}
              onClick={() => handleLanguageChange(code)}
            >
              <span className="me-2" style={{ fontWeight: 'bold' }}>
                {code.toUpperCase()}
              </span>
              {'  '}
              {label}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  ) : null;
};

export default LanguageSelector;
