import React from 'react';
import RegistrationInput from './RegistrationInput';
import ResultMessage from './ResultMessage';
import { ReactComponent as CloseButton } from './SVGs/CloseButton.svg';
import RefreshButton from './RefreshButton';

const GetDiscountPanel = ({
  registrations,
  discountResult,
  discount,
  updateRegistration,
  addRegistration,
  refreshQuoteProperties,
  eraseList,
  closeInput,
  needsRefreshing,
  isRefreshing,
}) => {
  return (
    <div className="get-discount-banner">
      <ResultMessage result={discountResult} discount={discount} />
      {discountResult !== 'success' && (
        <CloseButton className="close-button" onClick={eraseList} />
      )}
      <div className="registrations-form">
        <div className="input-list">
          {registrations.map((registration, index) => (
            <RegistrationInput
              key={index}
              index={index}
              value={registration}
              onChange={(e) => updateRegistration(index, e.target.value)}
              closeInput={() => closeInput(index)}
              needsRefreshing={needsRefreshing}
            />
          ))}
        </div>
        {registrations.length < 3 && (
          <button type="button" className="btn-add" onClick={addRegistration}>
            <p className="d-block d-md-none">+ Matrícula</p>
          </button>
        )}
        <RefreshButton
          isRefreshing={isRefreshing}
          needsRefreshing={needsRefreshing}
          onClick={refreshQuoteProperties}
        />
      </div>
    </div>
  );
};

export default GetDiscountPanel;
