import withFormLayout from '../HOC/withFormLayout';
import {
  setOptionForRender,
  getOptionsFromAppropriateSource,
  isNullOrUndefined,
  convertToSymbol,
} from '../utils';

const RadioInput = ({
  // mandatory
  inputSpecs,
  value,
  onChange,
  optionData,
  optionLabel,
  // optional
  isoptional,
  optionsArray,
  onBlur,
  // comes from HOC
  namespace,
  styles,
  inputRef,
}) => {
  const options = getOptionsFromAppropriateSource(
    inputSpecs,
    optionsArray,
    namespace
  );

  if (isNullOrUndefined(options)) return <></>;

  return (
    <div className={`d-flex ${styles} border-0`}>
      {options.map((option) => {
        const { data, label } = setOptionForRender(
          option,
          optionData,
          optionLabel
        );
        return (
          <div key={data} className="form-check mr-2">
            <input
              type="radio"
              className="form-check-input"
              name={namespace}
              value={data}
              checked={data === value}
              onChange={onChange}
              onBlur={onBlur}
              isoptional={isoptional}
              ref={inputRef}
            />
            <label className="form-check-label">{convertToSymbol(label)}</label>
          </div>
        );
      })}
    </div>
  );
};

export default withFormLayout(RadioInput);
