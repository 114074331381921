export const AUTOKEY_TYPE = 'Auto-type';
export const AUTOKEY_INVITE = 'Auto-invite';
export const AUTOKEY_QUOTEID = 'Auto-quote_id';
export const AUTOKEY_MBPAYMENT = 'Auto-mbpayment';
export const AUTOKEY_COUNTER = 'Auto-checkcounter';
export const AUTOKEY_FINGERPRINT = 'Auto-fingerprint';
export const AUTOKEY_ACCESSTOKEN = 'Auto-access_token';
export const AUTOKEY_VEHICLE_DATA = 'Auto-vehicle_data';
export const AUTOKEY_REGISTRATION = 'Auto-registration';
export const AUTOKEY_SALESCHANNEL = 'Auto-sales_channel';
export const AUTOKEY_X_FINGERPRINT = 'Auto-X-fingerprint';
export const AUTOKEY_CONTRIBUTIONID = 'Auto-contributionsid';
export const AUTOKEY_DISTRIBUTORSELLERID = 'Auto-distributorseller_id';
export const AUTOKEY_DISTRIBUTIONPOINTID = 'Auto-distributionpoint_id';
export const AUTOKEY_REDIRECT_URL = 'Auto-redirect_url';

export const clearLocalStorage = () => {
  removeItemLocalStorage(AUTOKEY_TYPE);
  removeItemLocalStorage(AUTOKEY_INVITE);
  removeItemLocalStorage(AUTOKEY_QUOTEID);
  removeItemLocalStorage(AUTOKEY_COUNTER);
  removeItemLocalStorage(AUTOKEY_MBPAYMENT);
  removeItemLocalStorage(AUTOKEY_FINGERPRINT);
  removeItemLocalStorage(AUTOKEY_ACCESSTOKEN);
  removeItemLocalStorage(AUTOKEY_VEHICLE_DATA);
  removeItemLocalStorage(AUTOKEY_REGISTRATION);
  removeItemLocalStorage(AUTOKEY_SALESCHANNEL);
  removeItemLocalStorage(AUTOKEY_X_FINGERPRINT);
  removeItemLocalStorage(AUTOKEY_CONTRIBUTIONID);
  removeItemLocalStorage(AUTOKEY_DISTRIBUTORSELLERID);
  removeItemLocalStorage(AUTOKEY_DISTRIBUTIONPOINTID);
  removeItemLocalStorage(AUTOKEY_REDIRECT_URL);
};

export const setItemLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItemLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeItemLocalStorage = (key) => {
  return localStorage.removeItem(key);
};
