import {
  setItemLocalStorage,
  getItemLocalStorage,
  AUTOKEY_TYPE,
  AUTOKEY_X_FINGERPRINT,
  AUTOKEY_ACCESSTOKEN,
  AUTOKEY_REGISTRATION,
} from '../../../utils/helper.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../shared/SubmitButton.jsx';
import './whitelabel.css';
import { useTheme } from '../../../contexts/ThemeContext.js';
import { getDefaultLanguageFromData } from '../../../utils/functions.js';
import ReactInputMask from 'react-input-mask';
import { vehicleByRegistrationRequest } from '../../../api/apiRequests.js';
import Loading from '../shared/Loading.jsx';
import HabitTag from './HabitTag.jsx';
import LanguageSelector from './LanguageSelector.jsx';

const fetchVehicleByRegistrationAsync = async (
  url,
  registration_number,
  access_token,
  fingerprint
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
    'X-FINGERPRINT': fingerprint,
  };

  if (getItemLocalStorage(AUTOKEY_TYPE) === 'guest') {
    headers['Authorization'] = undefined;
  }

  const formData = {
    data: {
      registration: registration_number,
    },
  };

  const vehicleResponse = await vehicleByRegistrationRequest({
    url: url,
    params: formData,
    config: { headers: headers },
  });
  return vehicleResponse;
};

const Banner = () => {
  const navigate = useNavigate();
  const fingerprint = getItemLocalStorage(AUTOKEY_X_FINGERPRINT);
  const access_token = getItemLocalStorage(AUTOKEY_ACCESSTOKEN);
  const userType = getItemLocalStorage(AUTOKEY_TYPE);
  const [dataSharingConsent, setDataSharingConsent] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [overrideRegistration, setOverrideRegistration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useTheme();
  const { CONFIG } = theme;
  const { PROXY_PATH } = CONFIG;

  const defaultLanguage = getDefaultLanguageFromData(theme);
  const { image_desktop, image_mobile, title, subtitle, description } =
    theme.bio_sections.bio_header;

  const { card_title, input_label, simulate_button } =
    theme.bio_sections.bio_header_card;

  const bannerStyle = {
    backgroundImage: `url(${theme.images[image_desktop]})`,
  };

  const bannerMobileStyle = {
    backgroundImage: `url(${theme.images[image_mobile]})`,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !overrideRegistration &&
      (registrationNumber === '' || registrationNumber.includes('_'))
    )
      return;
    setIsLoading(true);
    if (overrideRegistration) {
      navigate('/simulation-form');
      return;
    }
    const vehicleResponse = await fetchVehicleByRegistrationAsync(
      PROXY_PATH + '/custom-actions/get-vehicle-by-registration/execute',
      registrationNumber,
      access_token,
      fingerprint
    );
    if (vehicleResponse.code === 'error') {
      console.error(vehicleResponse.error);
      setIsLoading(false);
      return null;
    }
    if (vehicleResponse.code === 'success') {
      setItemLocalStorage(AUTOKEY_REGISTRATION, registrationNumber);
      navigate('/simulation-form');
    }
  };

  return (
    <>
      <Loading
        isLoading={isLoading}
        dotColor={theme.colors['--color-primary']}
      />
      {/* absolute positioned */}
      <LanguageSelector />
      {theme.habit_tag && <HabitTag />}
      {/* mobile Banner */}
      <div
        className="d-flex flex-column align-items-center d-md-none banner-container-mobile"
        style={bannerMobileStyle}
      >
        <p className="banner-title-mobile">
          {theme.bio_sections.texts[title][defaultLanguage]}
        </p>
        <p className="banner-second-title-mobile">
          {theme.bio_sections.texts[subtitle][defaultLanguage]}
        </p>
        <div className="banner-card-mobile d-flex flex-column">
          <p className="align-self-center banner-card-title">
            {theme.bio_sections.texts[card_title][defaultLanguage]}
          </p>
          <form
            className="d-flex flex-column flex-grow-1"
            onSubmit={handleSubmit}
            action=""
          >
            <label className="banner-input-label">
              {theme.bio_sections.texts[input_label][defaultLanguage]}
            </label>
            <ReactInputMask
              mask="**-**-**"
              className="banner-input"
              placeholder="XX-XX-XX"
              value={overrideRegistration ? '' : registrationNumber}
              disabled={overrideRegistration}
              onChange={(e) => {
                setRegistrationNumber(e.target.value.toUpperCase());
              }}
            />
            <div className="d-flex align-items-top justify-content-between">
              <input
                type="checkbox"
                name="overrideRegistration"
                checked={overrideRegistration}
                onChange={() => setOverrideRegistration(!overrideRegistration)}
                className="align-self-start banner-checkbox me-2 flex-shrink-0"
              />
              <label
                htmlFor="overrideRegistration"
                className="banner-checkbox-label me-auto"
                onClick={() => setOverrideRegistration(!overrideRegistration)}
              >
                Não tenho matrícula
              </label>
            </div>
            {userType === 'lead' && (
              <div className="d-flex align-items-top justify-content-between">
                <input
                  type="checkbox"
                  name="dataSharingConsent"
                  checked={dataSharingConsent}
                  onChange={() => setDataSharingConsent(!dataSharingConsent)}
                  className="align-self-start banner-checkbox me-2 flex-shrink-0"
                />
                <label
                  htmlFor="dataSharingConsent"
                  className="banner-checkbox-label me-auto"
                  onClick={() => setDataSharingConsent(!dataSharingConsent)}
                >
                  Tenho o consentimento do cliente para angariação de dados
                </label>
              </div>
            )}
            <SubmitButton
              customClassName={'btn mt-auto align-self-center w-100'}
              isDisabled={
                (userType === 'lead' && !dataSharingConsent) ||
                (!overrideRegistration &&
                  (registrationNumber === '' ||
                    registrationNumber.includes('_')))
              }
              text={theme.bio_sections.texts[simulate_button][defaultLanguage]}
            />
          </form>
        </div>
      </div>
      {/* PC Banner */}
      <div
        className="d-none d-md-flex justify-content-between banner-container"
        style={bannerStyle}
      >
        <div className="d-flex flex-column justify-content-between banner-text-container">
          <div className="d-flex flex-column justify-content-between p-0">
            <p className="banner-title m-0 p-0 mb-2">
              {theme.bio_sections.texts[title][defaultLanguage]}
            </p>
            <p
              className="banner-second-title m-0"
              style={{ fontSize: userType === 'lead' ? '30px' : '45px' }}
            >
              {theme.bio_sections.texts[subtitle][defaultLanguage]}
            </p>
          </div>
          <p className="banner-description mt-auto mb-0 pb-0">
            {theme.bio_sections.texts[description][defaultLanguage]}
          </p>
        </div>
        <div className="banner-card d-flex flex-column">
          <p className="align-self-center banner-card-title">
            {theme.bio_sections.texts[card_title][defaultLanguage]}
          </p>
          <form
            className="d-flex flex-column flex-grow-1"
            onSubmit={handleSubmit}
            action=""
          >
            <label className="banner-input-label">
              {theme.bio_sections.texts[input_label][defaultLanguage]}
            </label>
            <ReactInputMask
              mask="**-**-**"
              className="banner-input"
              placeholder="XX-XX-XX"
              value={overrideRegistration ? '' : registrationNumber}
              disabled={overrideRegistration}
              onChange={(e) => {
                setRegistrationNumber(e.target.value.toUpperCase());
              }}
            />
            <div className="d-flex align-items-top justify-content-between">
              <input
                type="checkbox"
                name="overrideRegistration"
                checked={overrideRegistration}
                onChange={() => setOverrideRegistration(!overrideRegistration)}
                className="align-self-start banner-checkbox me-2 flex-shrink-0"
              />
              <label
                htmlFor="overrideRegistration"
                className="banner-checkbox-label me-auto"
                onClick={() => setOverrideRegistration(!overrideRegistration)}
              >
                Não tenho matrícula
              </label>
            </div>
            {userType === 'lead' && (
              <div className="d-flex align-items-top justify-content-between">
                <input
                  type="checkbox"
                  name="dataSharingConsent"
                  checked={dataSharingConsent}
                  onChange={() => setDataSharingConsent(!dataSharingConsent)}
                  className="align-self-start banner-checkbox me-2 flex-shrink-0"
                />
                <label
                  htmlFor="dataSharingConsent"
                  className="banner-checkbox-label me-auto"
                  onClick={() => setDataSharingConsent(!dataSharingConsent)}
                >
                  Tenho o consentimento do cliente para angariação de dados
                </label>
              </div>
            )}
            <SubmitButton
              customClassName={'mt-auto align-self-center w-100'}
              isDisabled={
                (userType === 'lead' && !dataSharingConsent) ||
                (!overrideRegistration &&
                  (registrationNumber === '' ||
                    registrationNumber.includes('_')))
              }
              text={theme.bio_sections.texts[simulate_button][defaultLanguage]}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Banner;
