import { useEffect, useState } from 'react';
import { getSVG } from '../../../api/apiRequests';
import { isNullOrUndefined } from '../Form/utils';
const DynamicColorSVG = ({ src, newFillColor, size }) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const fetchSVG = async () => {
      if (src === '' || isNullOrUndefined(src)) {
        setSvgContent(null);
        return;
      }
      const SVGResponse = await getSVG(src);
      if (SVGResponse.code === 'error') {
        setSvgContent(null);
      }
      if (SVGResponse.code === 'success') {
        const modifiedSVG = SVGResponse.data
          .replace(/fill="(.*?)"/g, (match, fillColor) => {
            const isWhiteFill = /^(white|#fff|#FFF|#ffffff|#FFFFFF)$/.test(
              fillColor.trim()
            );
            return isWhiteFill ? match : `fill="${newFillColor}"`;
          })
          .replace(/width="(.*?)"/, `width="${size}"`)
          .replace(/height="(.*?)"/, `height="${size}"`);
        setSvgContent(modifiedSVG);
        if (!modifiedSVG.includes('<svg')) {
          setSvgContent(null);
        }
      }
    };
    fetchSVG();
  }, [src, newFillColor, size]);

  return svgContent ? (
    <div className="me-3" dangerouslySetInnerHTML={{ __html: svgContent }} />
  ) : null;
};

export default DynamicColorSVG;
