import { Modal } from 'react-bootstrap';
import { useTheme } from '../../../contexts/ThemeContext';
import { useEffect } from 'react';

const ErrorModal = ({ modal, setModal, title, description, image }) => {
  const { theme } = useTheme();

  const setCssVariables = (theme) => {
    const root = document.documentElement;
    for (const [key, value] of Object.entries(theme.colors)) {
      root.style.setProperty(key, value);
    }
    for (const [key, value] of Object.entries(theme.fonts)) {
      root.style.setProperty(key, value);
    }
    root.style.setProperty(
      '--border-radius-button',
      theme.border_radius.button
    );
  };

  useEffect(() => {
    setCssVariables(theme);
  }, [theme]);

  return (
    <Modal
      show={modal.open}
      backdrop="static"
      keyboard={false}
      animation={false}
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center error-modal-container">
        <img src={image} alt="error" className="img-error" />
        <p className="error-modal-text-title">{title}</p>
        <p className="error-modal-text-description">{description}</p>
        <p className="error-modal-text-contact">
          email: <a href="mailto:clientes@habit.pt">clientes@habit.pt</a> ou
          pela linha de atendimento:{' '}
          <a href="tel:+351308804737">+351 308 804 737</a>
        </p>
        <button
          className="error-modal-button"
          onClick={() => setModal({ modal: 'none', open: false })}
        >
          OK
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
