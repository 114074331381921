import { createContext, useContext, useEffect, useState } from 'react';
import { isNullOrUndefined } from '../components/frontend/Form/utils';
import { fetchThemeAsync } from '../api/apiRequests';
import Loading from '../components/frontend/shared/Loading';
import { CDN_BASE_URL } from '../config';
// import initialTheme from './initialTheme.json';

const ThemeContext = createContext();

const updateThemePathsWithBaseUrl = (theme, cdnBaseUrl) => {
  const isAnUrlPath = (data) =>
    typeof data === 'string' && data.startsWith('/');
  const pathWithBaseUrl = (path) => cdnBaseUrl + path;

  const updateUrls = (objectBody) => {
    for (const dataKey in objectBody) {
      if (isAnUrlPath(objectBody[dataKey])) {
        objectBody[dataKey] = pathWithBaseUrl(objectBody[dataKey]);
      }
      if (typeof objectBody[dataKey] === 'object')
        updateUrls(objectBody[dataKey]);
    }
  };
  return {
    ...theme,
    ...updateUrls(theme.fonts),
    ...updateUrls(theme.images),
  };
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadTheme = async () => {
      const newTheme = await fetchThemeAsync();
      // const newTheme = initialTheme;
      if (!isNullOrUndefined(newTheme)) {
        newTheme.hasSlimStyle = false;
        setTheme(updateThemePathsWithBaseUrl(newTheme, CDN_BASE_URL));
      }

      setIsLoading(false);
    };
    loadTheme();
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {isLoading ? <Loading isLoading={true} /> : children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (isNullOrUndefined(context)) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
