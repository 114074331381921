import React, { useState } from 'react';
import TextInput from '../Form/Inputs/TextInput';
import SubmitButton from '../shared/SubmitButton';
import {
  AUTOKEY_ACCESSTOKEN,
  AUTOKEY_QUOTEID,
  AUTOKEY_X_FINGERPRINT,
  getItemLocalStorage,
} from '../../../utils/helper';
import emailImage from '../../../assets/frontend/images/email_img.png';
import Loading from '../shared/Loading';
import { useTheme } from '../../../contexts/ThemeContext';

const EmailForm = ({ closeModal }) => {
  const { theme } = useTheme();
  const { CONFIG } = theme;
  const { PROXY_PATH } = CONFIG;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState([]);
  const [email, setEmail] = useState('');
  const [isLoader, setIsLoader] = useState(false);

  const accessToken = getItemLocalStorage(AUTOKEY_ACCESSTOKEN);
  const fingerprint = getItemLocalStorage(AUTOKEY_X_FINGERPRINT);
  const quoteID = getItemLocalStorage(AUTOKEY_QUOTEID);

  const headers = {
    'Content-Type': 'application/json',
    'X-FINGERPRINT': fingerprint,
  };

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const buttonText = isSubmitted ? 'OK' : 'Enviar Simulação';
  const title = isSubmitted
    ? 'A simulação foi enviada com sucesso'
    : 'Enviar simulação por email';
  const description = isSubmitted
    ? ''
    : 'Inserir o email onde irá receber a sua simulação';

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoader(true);
    setError([]);
    if (isSubmitted) {
      closeModal();
      return;
    }

    const url = `${PROXY_PATH}/custom-actions/send-simulation-email/execute`;
    const data = {
      data: {
        quote_id: quoteID,
        email: email,
      },
    };
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(data),
    });
    const jsonResponse = await response.json();

    const isEmailSent = jsonResponse?.custom_data?.email_sent ?? false;
    const hasError = jsonResponse.text !== undefined;
    if (hasError || !isEmailSent) {
      const DEFAULT_MESSAGE = 'Algo errado ocorreu no envio do email.';
      const [message, namespace] = jsonResponse?.text?.split(':') ?? [];
      const errorMessage =
        namespace === 'email' ? message ?? DEFAULT_MESSAGE : DEFAULT_MESSAGE;
      setError((prevError) => {
        if (prevError.includes(errorMessage)) return prevError;
        return [...prevError, errorMessage];
      });
    }
    setIsSubmitted(isEmailSent);
    setIsLoader(false);
  };

  return (
    <>
      <Loading dotColor="white" isLoading={isLoader} />
      <img
        src={emailImage ?? ''}
        alt="img-email"
        width="60px"
        className="img-error"
      />
      <p className="form-modal-text-title">{title}</p>
      <p className="form-modal-text-description">{description}</p>
      <form className="w-100" onSubmit={handleSubmit}>
        <div className="row w-100 justify-content-center">
          <div
            className={`col-8 form-group mb-5${isSubmitted ? ' d-none' : ''}`}
          >
            <TextInput
              inputSpecs={{
                namespace: 'email',
                label: 'Email',
              }}
              error={{ email: error }}
              value={email}
              placeholder="Inserir..."
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="w-100 d-flex justify-content-center">
            <SubmitButton
              isDisabled={!email}
              text={buttonText}
              customStyle={{ minWidth: '200px' }}
              customClassName={'email-submit-button'}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailForm;
