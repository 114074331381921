import {
  setItemLocalStorage,
  getItemLocalStorage,
  AUTOKEY_TYPE,
  AUTOKEY_FINGERPRINT,
  AUTOKEY_ACCESSTOKEN,
  AUTOKEY_DISTRIBUTORSELLERID,
  AUTOKEY_QUOTEID,
  AUTOKEY_X_FINGERPRINT,
  AUTOKEY_DISTRIBUTIONPOINTID,
  AUTOKEY_REGISTRATION,
  AUTOKEY_REDIRECT_URL,
  removeItemLocalStorage,
  AUTOKEY_MBPAYMENT,
} from '../../utils/helper';
import {
  validation,
  errorMessages,
  errorModals,
  DISCOUNT_STATES,
} from '../../utils/constants';
import React, { Fragment, useEffect, useState, useRef } from 'react';
// humans vs toasts... humans win
// import { ToastContainer} from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import 'react-phone-number-input/style.css';
import 'react-phone-input-2/lib/style.css';
import TooltipButton from './shared/TooltipButton';
import tooltipTexts from '../../utils/tooltipTexts';
import SelectInput from './Form/Inputs/SelectInput';
import NumberInput from './Form/Inputs/NumberInput';
import RadioInput from './Form/Inputs/RadioInput';
import DatePicker from './Form/Inputs/DatePicker';
import InputMask from './Form/Inputs/InputMask';
import PhoneInput from './Form/Inputs/PhoneInput';
import TextInput from './Form/Inputs/TextInput';
import { isNullOrUndefined } from './Form/utils';
import { useTheme } from '../../contexts/ThemeContext';
import {
  clearErrorNamespaces,
  clearErrorStateNamespace,
  getDefaultLanguageFromData,
  handleResponseError,
  updateErrorState,
  arrayToCSV,
  dateToString,
  stringToDate,
} from '../../utils/functions';
import SubmitButton from './shared/SubmitButton';
import OpenEmailFormButton from './FormModal/OpenEmailFormButton';
import Loading from './shared/Loading';
import CoverageTable from './Form/CoverageTable/CoverageTable';
import ButtonCaroussel from './Form/CoverageTable/ButtonCaroussel';
import StyledSelect from './shared/StyledSelect';
import ErrorModal from './shared/ErrorModal';
import VersionListModal from './Form/VersionListModal';
import RegistrationModal from './shared/RegistrationModal';
import RefreshRateButton from './Form/CoverageTable/RefreshRateButton';
import SelectWithAutoComplete from './Form/Inputs/SelectWithAutoComplete';
import FormModal from './FormModal/FormModal';
import EmailForm from './FormModal/EmailForm';
import { RegistrationDiscount, useRegistrations } from './RegistrationDiscount';
import { getHeaders } from '../../api/helpers';
import { SmartlinkComponent } from '@habit.analytics/habit-smartlink-reactcomponent';

const SimulationForm = () => {
  const usertype = getItemLocalStorage(AUTOKEY_TYPE);
  const quoteid = getItemLocalStorage(AUTOKEY_QUOTEID);
  let fingerprint = getItemLocalStorage(AUTOKEY_X_FINGERPRINT);
  const getFingerprint = () => {
    fingerprint = getItemLocalStorage(AUTOKEY_X_FINGERPRINT);
    return;
  };
  const access_token = getItemLocalStorage(AUTOKEY_ACCESSTOKEN);
  const distributorseller_id = getItemLocalStorage(AUTOKEY_DISTRIBUTORSELLERID);
  const distributionpoint_id = getItemLocalStorage(AUTOKEY_DISTRIBUTIONPOINTID);
  const { theme, setTheme } = useTheme();
  const { CONFIG } = theme;
  // const fakeConfig = {
  //   PROXY_PATH:
  //     'https://api.distributors.habit.io/44444-habit-auto-sb-1b070e37',
  //   PUBLIC_URL: 'https://1b070e37.auto.product.sandbox.habit.io/',
  //   VERSION: '7bd8c17b-84d4-4629-b73b-6c4728469baa',
  // };
  // const { PROXY_PATH, VERSION } = { ...CONFIG, ...fakeConfig };
  const { PROXY_PATH, VERSION } = CONFIG;
  const registrationsHook = useRegistrations();
  const {
    registrations: old_registrations,
    needsRefreshing: isOldRegistrationsDifferent,
    updateResultMessage,
  } = registrationsHook;
  const primaryColor = theme.colors['--color-primary'];
  const secondaryColor = theme.colors['--color-secondary'];
  const defaultLanguage = getDefaultLanguageFromData(theme);

  const [firstStepSpecs, secondStepSpecs, thirdStepSpecs, fourthStepSpecs] =
    theme.form_sections.steps_section.steps.toSorted(
      (a, b) => a.order_index - b.order_index
    );

  const textRefobject = theme.form_sections.texts;

  const { title: stepOneTitleTag, fields: stepOneFields } = firstStepSpecs;
  const { label: editButtonLabel } =
    theme.form_sections.steps_section.buttons[0];

  const navigate = useNavigate();

  const IBANValidator = require('iban-validator-js');

  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (var x = thisYear; x >= 1900; x--) {
    allYears.push(x);
  }
  var months = [
    { label: 'janeiro', data: '01' },
    { label: 'fevereiro', data: '02' },
    { label: 'março', data: '03' },
    { label: 'abril', data: '04' },
    { label: 'maio', data: '05' },
    { label: 'junho', data: '06' },
    { label: 'julho', data: '07' },
    { label: 'agosto', data: '08' },
    { label: 'setembro', data: '09' },
    { label: 'outubro', data: '10' },
    { label: 'novembro', data: '11' },
    { label: 'dezembro', data: '12' },
  ];

  const [step, setStep] = useState(1);
  const [isVersionListOpen, setIsVersionListOpen] = useState(false);
  const [quoteSpecs, setQuoteSpecs] = useState(null);
  const [type, setType] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [show, setShow] = useState(false);
  const [user_id, setUserId] = useState('');
  const [documents, setDocuments] = useState([]);
  const [coveragesByProductArray, setCoveragesByProductArray] = useState([]);
  const [isLoader, setisLoader] = useState(false);
  const [driver_nif, setDriverNif] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [model_list, setModelList] = useState([]);
  const [brand_list, setBrandList] = useState([]);
  const [is_imported, setIsImported] = useState('');
  const [has_trailer, setHasTrailer] = useState('0');
  const [version_list, setVersionList] = useState([]);
  const [registration, setRegistration] = useState('');
  const [driver_gender, setDriverGender] = useState('');
  const [vehicle_types, setVehicleTypes] = useState([]);
  const [error, setError] = useState({});
  const [driver_birthdate, setDriverBirthdate] = useState('');
  const [manufacturer_year, setManufacturerYear] = useState('');
  const [productDisclaimer, setProductDisclaimer] = useState('');
  const [manufacturer_month, setManufacturerMonth] = useState('');
  const [properties_version, setPropertiesVersion] = useState('');
  const [quote_id, setQuoteId] = useState('');
  const [driver_email, setDriverEmail] = useState('');
  const [client_email, setClientEmail] = useState('');
  const [client_mobile, setClientMobile] = useState('');
  const [rate_by_product, setRateByProduct] = useState([]);
  const [is_postal_code, setIsPostalCode] = useState(true);
  const [chosen_products, setChosenProducts] = useState([]);
  const [protectedasset_id, setProtectedassetId] = useState('');
  const [privacy_policy_url, setPrivacyPolicyUrl] = useState('');
  const [driver_postal_code, setDriverPostalCode] = useState('');
  const [privacy_policy_name, setPrivacyPolicyName] = useState('');
  const [driver_license_date, setDriverLicenseDate] = useState('');
  const [data_sharing_confirmation, setDataSharingConfirmation] =
    useState(false);
  const [not_seller_confirmation, setNotSellerConfirmation] = useState(false);
  const [simulated_payment_frequency, setSimulatedPaymentFrequency] = useState(
    []
  );
  const [showVehicleFormOnStepThree, setShowVehicleFormOnStepThree] =
    useState(false);
  const [errorModal, setErrorModal] = useState({ modal: 'none', open: false });

  /**********Step 2 States************ */

  const [planlabel, setPlanlabel] = useState('');
  const [total_amount, setTotalAmount] = useState('0.00');
  const [chosen_product, setChosenProduct] = useState('');
  const [payment_frequency, setPaymentFrequency] = useState('');
  const [policy_start_date, setPolicyStartDate] = useState(`${new Date()}`);
  const [coveragesByQuote, setCoveragesByQuote] = useState({});
  const [UICoveragesByQuote, setUICoveragesByQuote] = useState({});
  const [isTableRefreshing, setIsTableRefreshing] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  /******** Step3 States****************/

  const [isRegistrationDialogOpen, setIsRegistrationDialogOpen] =
    useState(false);
  const [nif, setNif] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [driver_city, setDriverCity] = useState('');
  const [postal_code, setPostalCode] = useState('');
  const [driver_name, setDriverName] = useState('');
  const [driver_phone, setDriverPhone] = useState('');
  const [isButtonSendSMS, setIsButtonSendSMS] = useState(false);
  const [showSMSField, setShowSMSField] = useState(false);
  const [marital_state, setMaritalState] = useState('');
  const [driver_mobile, setDriverMobile] = useState('');
  const [driver_address, setDriverAddress] = useState('');
  const [policyholder_id, setPolicyholderId] = useState('');
  const [insuree_is_driver, setInsureeIsDriver] = useState('sim');
  const [privacy_policy_consent, setPrivacyPolicyConsent] = useState(false);
  const [driver_marital_state, setDriverMaritalState] = useState('');
  const [financialEntity, setFinancialEntity] = useState('');
  const [financialEntities, setFinancialEntities] = useState([]);
  const [hasFinancialEntity, setHasFinancialEntity] = useState(false);
  const [driver_license_number, setDriverLicenseNumber] = useState('');
  const [receive_email_marketing, setReceiveEmailMarketing] = useState(false);
  const [digital_signature_received, setDigitalSignatureReceived] =
    useState('');

  /*****Fourth setup state*****/
  const [paymentHash, setPaymentHash] = useState('');
  const [blockEdit, setBlockEdit] = useState(false);

  const checkFields = [
    'policy_date',
    'registration',
    'type',
    'manufacturer_year',
    'manufacturer_month',
    'brand',
    'model',
    'properties_version',
    'is_imported',
    'has_trailer',
    'driver_nif',
    'driver_birthdate',
    'driver_license',
    'driver_postal_code',
    'driver_email',
    'driver_gender',
    'client_email',
    'client_mobile',
    'digital_signature_received',
    'chosen_product',
    'payment_frequency',
    'insuree_is_driver',
    'name',
    'nif',
    'marital_state',
    'gender',
    'email',
    'phone',
    'mobile',
    'city',
    'postal_code',
    'address',
    'driver_name',
    'driver_marital_state',
    'driver_license_number',
    'driver_phone',
    'driver_mobile',
    'driver_address',
    'driver_city',
    'receive_email_marketing',
    'privacy_policy_consent',
    'confirm_membership',
  ];

  const prePaymentMethod = async (quoteID) => {
    const url = `${PROXY_PATH}/quotes/${quoteID}/submit-contract`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaders(fingerprint, access_token),
      body: JSON.stringify({}),
    });
    const data = await response.json();
    const firstPayment = data?.customized?.payments[0];
    const success = Boolean(firstPayment?.id);
    setBlockEdit((previous) => previous || success); //once is blocked, it can never be unblocked somewhere
    return { success, payment_id: firstPayment?.id ?? '' };
  };

  const onPaymentSuccess = (paymentData) => {
    if (paymentData !== undefined && paymentData !== 'undefined') {
      const paymentDataJson = paymentData ? JSON.parse(paymentData) : {};
      const mbDataKeys = [
        'mb_entity',
        'mb_reference',
        'mb_amount',
        'mb_validity',
      ];
      const hasAllKeys = mbDataKeys.every((key) =>
        paymentDataJson.hasOwnProperty(key)
      );
      if (hasAllKeys) {
        const treatedData = JSON.stringify({
          entity: paymentDataJson.mb_entity,
          reference: paymentDataJson.mb_reference,
          amount: paymentDataJson.mb_amount,
          validity: paymentDataJson?.mb_validity ?? '',
        });
        setItemLocalStorage(AUTOKEY_MBPAYMENT, treatedData);
      }
    }
    setisLoader(false);
    navigate('/success');
  };

  const validateFields = (formFields, initialErrorState, optionalFields) => {
    const fieldKeys = Object.keys(formFields);
    let updatedErrorState = { ...initialErrorState };
    fieldKeys.forEach((namespace) => {
      const fieldErrors = [];
      const fieldValue = formFields[namespace];

      const mandatoryFilters = validation.mandatory;
      if (!optionalFields.includes(namespace)) {
        mandatoryFilters.forEach(({ regex, message }) => {
          const stringFieldValue = (fieldValue) => {
            if (
              fieldValue === null ||
              fieldValue === undefined ||
              fieldValue === false
            )
              return '';
            if (fieldValue instanceof Date) return fieldValue.toISOString();
            return String(fieldValue);
          };

          if (!regex.test(stringFieldValue(fieldValue))) {
            fieldErrors.push(errorMessages[message]);
          }
        });
      }

      const filters = validation[namespace] || [];
      filters.forEach(({ regex, message }) => {
        const stringFieldValue =
          fieldValue === null
            ? ''
            : fieldValue instanceof Date
            ? fieldValue.toISOString()
            : String(fieldValue);
        if (!regex.test(stringFieldValue)) {
          fieldErrors.push(errorMessages[message]);
        }
      });
      updatedErrorState = updateErrorState(
        updatedErrorState,
        namespace,
        fieldErrors
      );
    });
    return {
      success: Object.values(updatedErrorState).every(
        (fieldErrors) => fieldErrors?.length === 0
      ),
      updatedErrorState: updatedErrorState,
    };
  };

  const mobileButtonRef = useRef(null);

  useEffect(() => {
    if ('parentIFrame' in window) {
      let timer;
      const INTERVALms = 500;
      const BOTTOM_OF_THE_PAGE = 0;

      window.parentIFrame.getPageInfo(function (pageInfo) {
        if (mobileButtonRef.current) {
          const moveUpBy = pageInfo.iframeHeight + pageInfo.offsetTop;
          const moveDownBy = pageInfo.windowHeight + pageInfo.scrollTop;
          const visibleHeight =
            moveUpBy - moveDownBy > BOTTOM_OF_THE_PAGE
              ? moveUpBy - moveDownBy
              : BOTTOM_OF_THE_PAGE;

          mobileButtonRef.current.style.opacity = 0;
          mobileButtonRef.current.style.bottom = `${visibleHeight}px`;
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            mobileButtonRef.current.style.opacity = 1;
          }, INTERVALms);
        }
      });
    }
  }, [step, chosen_product]);

  useEffect(() => {
    setPolicyStartDate(`${new Date()}`);
    if (getItemLocalStorage(AUTOKEY_REGISTRATION) !== null) {
      setRegistration(getItemLocalStorage(AUTOKEY_REGISTRATION));
      getVehicleData(getItemLocalStorage(AUTOKEY_REGISTRATION));
    }
    if (getItemLocalStorage(AUTOKEY_TYPE) === 'guest') {
      if ('parentIFrame' in window) {
        setTheme((prevTheme) => {
          const newTheme = { ...prevTheme, hasSlimStyle: true };
          return newTheme;
        });
      }
    }

    if (getItemLocalStorage(AUTOKEY_TYPE) !== 'user') {
      getVehicleTypes();
      getQuoteSpecs();
    }

    if (getItemLocalStorage(AUTOKEY_TYPE) === 'user') {
      authUser();
      setStep(2);
    }
  }, []);

  const checkProductsWithUI = () => {
    function areObjectsEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) return false;
      }
      return true;
    }

    const areArraysOfObjectsEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (!areObjectsEqual(arr1[i], arr2[i])) return false;
      }
      return true;
    };

    const result = {};

    for (const productCode in UICoveragesByQuote) {
      const uiCoverages = UICoveragesByQuote[productCode];
      const Coverages = coveragesByQuote[productCode];

      result[productCode] =
        Array.isArray(uiCoverages) &&
        Array.isArray(Coverages) &&
        areArraysOfObjectsEqual(uiCoverages, Coverages);
    }
    return result;
  };

  const productsCheckedWithUI = checkProductsWithUI();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const authUser = () => {
    const temporaryFingerprint = getItemLocalStorage(AUTOKEY_FINGERPRINT);
    setisLoader(true);
    const formData = JSON.stringify({ fingerprint: temporaryFingerprint });

    fetch(`${PROXY_PATH}/auth-users`, {
      method: 'POST',
      headers: getHeaders(),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.text !== undefined) {
          setError(
            handleResponseError(
              error,
              res.text,
              checkFields,
              'Algo errado ocorreu na autenticação'
            )
          );
          return false;
        }

        if (res.name !== undefined) {
          setItemLocalStorage(AUTOKEY_X_FINGERPRINT, res.fingerprint);
          removeItemLocalStorage(AUTOKEY_FINGERPRINT);
          getFingerprint();
          authQuote(res.fingerprint);
        }
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const authQuote = (fingerprint) => {
    fetch(`${PROXY_PATH}/quotes/${quoteid}`, {
      method: 'GET',
      headers: getHeaders(fingerprint, access_token),
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if (res.text !== undefined) {
          setError(
            handleResponseError(
              error,
              res.text,
              checkFields,
              'Algo errado ocorreu na requisição'
            )
          );
          return false;
        }
        if (res.quote.properties !== undefined) {
          setQuoteSpecs((prevQuoteSpecs) => ({
            ...(prevQuoteSpecs !== null ? prevQuoteSpecs : {}),
            simulated_quote_properties: res.quote.properties,
          }));

          const responsePolicyStartDate = res.quote.properties.find(
            (match) => match.namespace === 'policy_start_date'
          );
          if (responsePolicyStartDate) {
            const dateFromResponse = stringToDate(
              responsePolicyStartDate.data ?? ''
            );
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (dateFromResponse > today) setPolicyStartDate(dateFromResponse);
          }

          const responseCoveragesByQuote = res.quote.properties.find(
            (property) => property.namespace === 'coverages_by_quote'
          );
          if (responseCoveragesByQuote?.data) {
            setCoveragesByQuote(() => {
              return JSON.parse(responseCoveragesByQuote.data);
            });
            setUICoveragesByQuote(JSON.parse(responseCoveragesByQuote.data));
          }
          const ratebyproduct = res.quote.properties.find(
            (x) => x.namespace === 'rate_by_product'
          ).data;
          const ratebyproductData = JSON.parse(ratebyproduct);
          setRateByProduct(ratebyproductData);

          const chosenproductData = res.quote.properties.find(
            (x) => x.namespace === 'chosen_product'
          );
          setChosenProduct(chosenproductData.data);
          setChosenProducts(chosenproductData.options);
          const planLabels = res.quote.properties.find(
            (x) => x.namespace === 'payment_frequency'
          );
          const hasSetFrequency =
            planLabels.data !== '' && planLabels.data !== undefined;
          const firstPaymentFrequency = planLabels.options[0];
          const conditionalPaymentFrequency = hasSetFrequency
            ? planLabels
            : firstPaymentFrequency;
          setPaymentFrequency(conditionalPaymentFrequency.data);
          setPlanlabel(planLabels.options);
          setSimulatedPaymentFrequency(planLabels.options);

          var product = ratebyproductData[chosenproductData.data];
          setTotalAmount(
            product[conditionalPaymentFrequency.data].first_installment
          );

          getCcoveragesByQuote(quoteid, chosenproductData.data);

          getQuoteSpecs();
        }
        if (res.policy_holders.length > 0) {
          setPolicyholderId(res.policy_holders[0].id);
        }
        if (res.protected_assets !== undefined) {
          setProtectedassetId(res.protected_assets[0].id);

          const foundFinancialEntities =
            res.protected_assets[0].properties?.find(
              (property) => property.namespace === 'financial_entity'
            )?.options;

          if (foundFinancialEntities) {
            setFinancialEntities(foundFinancialEntities);
          }

          const findPropertyByNamespace = (namespace, array) =>
            array.find((match) => match.namespace === namespace);
          const protectedAssetProperties =
            res?.protected_assets?.[0]?.properties ?? [];

          // setType as user flwo
          let vehicletype =
            findPropertyByNamespace('vehicle_type', protectedAssetProperties)
              ?.data ?? '';
          setType(vehicletype);

          //registration as user flow
          let registrationdata = res.protected_assets[0].properties.find(
            (match) => match.namespace === 'registration'
          );
          setRegistration(registrationdata.data);
          getVehicleTypes();

          // driver_name as user flow
          let driverNameData = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'driver_name') {
              return x;
            }
          });
          setDriverName(driverNameData.data);

          let drivernif = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'driver_nif') {
              return x;
            }
          });
          setDriverNif(drivernif.data);

          let driverbirthdate = res.protected_assets[0].properties.find(
            (x) => x.namespace === 'driver_birthdate'
          );

          const createDateObject = (dateString) => {
            const [year, month, day] = dateString.split('-');
            const firstOfTheMonth = 1;
            const dateHasDay = day !== undefined;

            const dateWithUsersTimezone = new Date();
            dateWithUsersTimezone.setFullYear(year);
            dateWithUsersTimezone.setMonth(month - 1);
            dateWithUsersTimezone.setDate(dateHasDay ? day : firstOfTheMonth);

            return dateWithUsersTimezone;
          };

          setDriverBirthdate(createDateObject(driverbirthdate.data));

          let driverlicensedate = res.protected_assets[0].properties.find(
            (x) => {
              if (x.namespace === 'driver_license_date') {
                return x;
              }
            }
          );
          let driverlicensemonthyear = res.protected_assets[0].properties.find(
            (x) => {
              if (x.namespace === 'driver_license_month_year') {
                return x;
              }
            }
          );
          //driver lisenece date as user flow
          if (driverlicensedate.data === null) {
            setDriverLicenseDate(createDateObject(driverlicensemonthyear.data));
          } else {
            if (driverlicensedate.data.startsWith('-')) {
              setDriverLicenseDate(
                createDateObject(driverlicensedate.data.slice(1))
              );
            } else {
              setDriverLicenseDate(createDateObject(driverlicensedate.data));
            }
          }
          //deriver email as user flow
          let driveremail = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'driver_email') {
              return x;
            }
          });
          setDriverEmail(driveremail.data);
          //postal code as user flow
          let driverpostalcode = res.protected_assets[0].properties.find(
            (x) => {
              if (x.namespace === 'driver_postal_code') {
                return x;
              }
            }
          );
          setDriverPostalCode(driverpostalcode.data);
          //gender as user flow
          let drivergender = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'driver_gender') {
              return x;
            }
          });
          setDriverGender(drivergender.data);

          //manufacture year as user flow
          let manufactureryear = res.protected_assets[0].properties.find(
            (x) => x.namespace === 'manufacturer_year'
          ).data;
          setManufacturerYear(manufactureryear);

          //manufacturer month as user flow
          let manufacturermonth = res.protected_assets[0].properties.find(
            (x) => x.namespace === 'manufacturer_month'
          ).data;
          setManufacturerMonth(manufacturermonth);

          getUserBrands(vehicletype, manufactureryear, manufacturermonth);

          //brand as user flow
          let brandlistitem = res.protected_assets[0].properties.find(
            (x) => x.namespace === 'brand'
          ).data;
          setBrand(brandlistitem);
          getUserModels(
            vehicletype,
            brandlistitem,
            manufactureryear,
            manufacturermonth
          );
          // getVersions(model);
          //model as user flow
          let modeldata = res.protected_assets[0].properties.find(
            (x) => x.namespace === 'model'
          ).data;
          setModel(modeldata);
          getUserVersions(
            vehicletype,
            modeldata,
            manufactureryear,
            manufacturermonth,
            brandlistitem
          );

          //version as user flow
          let versiondata = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'version') {
              return x;
            }
          });
          setPropertiesVersion(versiondata.data);
          //is imported as user flow
          let isimported = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'is_imported') {
              return x;
            }
          });
          setIsImported(isimported.data);
          //has trailer as user flow
          let hastrailer = res.protected_assets[0].properties.find((x) => {
            if (x.namespace === 'has_trailer') {
              return x;
            }
          });
          setHasTrailer(hastrailer.data);
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const clearVehicleData = () => {
    setType('');
    setManufacturerMonth('');
    setManufacturerYear('');
    setBrand('');
    setModel('');
    setPropertiesVersion('');
    setBrandList([]);
    setModelList([]);
    setVersionList([]);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var checkBox = null;
    if (name !== 'digital_signature_received' && usertype === 'seller') {
      setIsButtonSendSMS(true);
      setShowSMSField(false);
    }
    if (error?.[name] && error[name].length > 0) {
      const checkErrors = validation[name]?.filter(({ regex }) => {
        return !regex.test(String(value));
      });
      setError({
        ...error,
        [name]: checkErrors?.map(({ message }) => errorMessages[message]),
      });
    }

    switch (name) {
      case 'client_email':
        setError((prevError) => {
          clearErrorNamespaces(prevError, ['client_email', 'client_mobile']);
        });
        setClientEmail(value);
        break;
      case 'client_mobile':
        setError((prevError) => {
          clearErrorNamespaces(prevError, ['client_email', 'client_mobile']);
        });
        setClientMobile(value);
        break;
      case 'digital_signature_received':
        setDigitalSignatureReceived(value);
        break;
      case 'registration':
        let upperRegistration = value.toUpperCase();
        setRegistration(upperRegistration);
        const isFullRegistration =
          upperRegistration !== '' && !upperRegistration.includes('_');
        if (isFullRegistration) getVehicleData(upperRegistration);
        break;
      case 'vehicle_type':
        setType(value);
        setBrand('');
        setModel('');
        setPropertiesVersion('');
        getBrands(manufacturer_year, manufacturer_month, value);
        break;
      case 'manufacturer_year':
        setManufacturerYear(value);
        setModel('');
        setPropertiesVersion('');
        getBrands(value, manufacturer_month, type);
        break;
      case 'manufacturer_month':
        setManufacturerMonth(value);
        setModel('');
        setPropertiesVersion('');
        getBrands(manufacturer_year, value, type);
        break;
      case 'brand':
        setBrand(value);
        setModel('');
        setPropertiesVersion('');
        getModels(value);
        break;
      case 'model':
        setModel(value);
        setPropertiesVersion('');
        getVersions(value);
        break;
      case 'properties_version':
        setPropertiesVersion(e.target.id);
        setStep(1);
        break;
      case 'is_imported':
        setIsImported(value);
        break;
      case 'has_trailer':
        setHasTrailer(value);
        break;
      case 'driver_nif':
        setDriverNif(value);
        break;
      case 'driver_gender':
        setDriverGender(value);
        break;
      case 'driver_postal_code':
        setDriverPostalCode(value);
        break;
      case 'driver_email':
        setDriverEmail(value);
        break;
      case 'chosen_product':
        setChosenProduct(value);
        var product = rate_by_product[value];
        setTotalAmount(product[payment_frequency].first_installment);
        break;
      case 'payment_frequency':
        setPaymentFrequency(value);
        var product = rate_by_product[chosen_product];
        setTotalAmount(product[value].first_installment);
        break;
      case 'insuree_is_driver':
        setInsureeIsDriver(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'nif':
        setNif(value);
        break;
      case 'marital_state':
        setMaritalState(value);
        break;
      case 'gender':
        setGender(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'mobile':
        setMobile(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'postal_code':
        setPostalCode(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'driver_name':
        setDriverName(value);
        break;
      case 'driver_marital_state':
        setDriverMaritalState(value);
        break;
      case 'driver_license_number':
        setDriverLicenseNumber(value);
        break;
      case 'driver_phone':
        setDriverPhone(value);
        break;
      case 'driver_mobile':
        setDriverMobile(value);
        break;
      case 'driver_address':
        setDriverAddress(value);
        break;
      case 'driver_city':
        setDriverCity(value);
        break;
      case 'receive_email_marketing':
        checkBox = document.getElementById('receive_email_marketing');
        if (checkBox.checked === true) {
          setReceiveEmailMarketing(true);
        } else {
          setReceiveEmailMarketing(false);
        }

        break;
      case 'privacy_policy_consent':
        checkBox = document.getElementById('privacy_policy_consent');
        if (checkBox.checked === true) {
          setPrivacyPolicyConsent(true);
        } else {
          setPrivacyPolicyConsent(false);
        }

        break;
      case 'not_seller_confirmation':
        checkBox = document.getElementById('not_seller_confirmation');
        if (checkBox.checked === true) {
          setNotSellerConfirmation(true);
        } else {
          setNotSellerConfirmation(false);
        }

        break;
      case 'data_sharing_confirmation':
        checkBox = document.getElementById('data_sharing_confirmation');
        if (checkBox.checked === true) {
          setDataSharingConfirmation(true);
        } else {
          setDataSharingConfirmation(false);
        }

        break;
      default:
      // code block
    }
  };

  const getQuoteSpecs = () => {
    setisLoader(true);
    fetch(PROXY_PATH + '/versions/' + VERSION + '/quote-specs', {
      method: 'GET',
      headers: getHeaders(fingerprint, access_token),
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if ((res.code !== undefined) & (res.code === 9909)) {
          setErrorModal({ modal: 'error_on_subscription', open: true });
          return false;
        }
        if (!res?.quote_specs?.specs) {
          console.error('Invalid quote specs format.');
          return false;
        }
        setQuoteSpecs((prevQuoteSpecs) => ({
          ...(prevQuoteSpecs !== null ? prevQuoteSpecs : {}),
          ...res?.quote_specs?.specs,
        }));
        const insurance_product = res.insurance_product;
        const documents = insurance_product.documents;
        // setDocuments(documents.at_product_info);
        setDocuments(documents.at_offer);

        const product_dis = insurance_product.legal_disclaimers.find(
          (x) => x.type === 'by_product'
        );
        setProductDisclaimer(product_dis);

        const privacy_policy_default = documents.at_privacy_policy_consent.find(
          (x) => x.description === 'privacy_policy_default'
        );
        setPrivacyPolicyUrl(privacy_policy_default.uri);
        setPrivacyPolicyName(privacy_policy_default.name);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getVehicleTypes = () => {
    var formData = JSON.stringify({ data: {} });

    setisLoader(true);
    fetch(PROXY_PATH + '/custom-actions/get-fixed-info/execute', {
      method: 'POST',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if (res.custom_data.vehicle_types.length === 0) {
          setType('');
        }

        setVehicleTypes(res.custom_data.vehicle_types);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getVehicleData = (registration) => {
    var formData = JSON.stringify({
      data: { registration: registration },
    });
    setisLoader(true);

    fetch(PROXY_PATH + '/custom-actions/get-vehicle-by-registration/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);

        const checkRegistrationWithExistingData = (vehicle_data) => {
          const existingData = {
            brandId: brand,
            isImported: is_imported,
            modelId: model,
            month: manufacturer_month,
            tylaCode: properties_version,
            vehicleTypeId: type,
            year: manufacturer_year,
          };
          const fecthedData = vehicle_data?.vehicle_information;

          if (Object.values(existingData).some((x) => !x)) return false;
          if (
            fecthedData === undefined ||
            Object.keys(fecthedData).length === 0
          )
            return true;

          return Object.keys(existingData).every((existingKey) => {
            if (fecthedData[existingKey] === undefined) return true;
            if (existingKey === 'isImported') {
              if (fecthedData[existingKey] === 'true')
                return existingData[existingKey] === 'sim';
              if (fecthedData[existingKey] === 'false')
                return existingData[existingKey] === 'nao';
              return false;
            }
            if (existingKey === 'month')
              return (
                fecthedData[existingKey].padStart(2, '0') ===
                existingData[existingKey].padStart(2, '0')
              );
            return existingData[existingKey] === fecthedData[existingKey];
          });
        };

        const updateVehicleData = (vehicle_data) => {
          const affectedKeys = [
            'vehicle_type',
            'manufacturer_year',
            'manufacturer_month',
            'brand',
            'model',
            'type',
            'is_imported',
            'has_trailer',
          ];
          affectedKeys.forEach((affectedKey) => {
            setError((prevError) =>
              clearErrorStateNamespace(prevError, affectedKey)
            );
          });
          const {
            brand_list: responseBrandList,
            model_list: responseModelList,
            version_list: responseVersionList,
            vehicle_information: {
              year: responseYear,
              month: responseMonth,
              brandId: responseBrandId,
              vehicleTypeId: responseVehicleTypeId,
              tylaCode: responseTylaCode,
              modelId: responseModelId,
              isImported: responseIsImported,
            } = {},
          } = vehicle_data || {};

          setManufacturerYear(responseYear || '');
          setManufacturerMonth(responseMonth?.padStart(2, '0') || '');
          setBrand(
            responseBrandList?.some(
              (foundBrand) => foundBrand.id === responseBrandId
            )
              ? responseBrandId
              : ''
          );
          setType(
            (responseVehicleTypeId !== '0' && responseVehicleTypeId) || ''
          );
          setPropertiesVersion(
            responseVersionList?.some(
              (foundVersion) => foundVersion.tylaCode === responseTylaCode
            )
              ? responseTylaCode
              : ''
          );
          setModel(
            responseModelList?.some(
              (foundModel) => foundModel.id === responseModelId
            )
              ? responseModelId
              : ''
          );
          setIsImported(
            responseIsImported === 'true'
              ? 'sim'
              : responseIsImported === 'false'
              ? 'nao'
              : ''
          );
          setBrandList(responseBrandList || []);
          setModelList(responseModelList || []);
          setVersionList(responseVersionList || []);
        };

        if ((res.code !== undefined) & (res.code === 9034)) {
          setErrorModal({ modal: 'error_on_subscription', open: true });
          clearVehicleData();
          throw new Error('Vehicle not found');
        }

        var vehicle_data = res.custom_data;
        if (step === 1) updateVehicleData(vehicle_data);
        if (step === 3) {
          const isFetchedDataOK =
            checkRegistrationWithExistingData(vehicle_data);
          if (!isFetchedDataOK) {
            setRegistration('');
            setIsRegistrationDialogOpen(true);
          }
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getBrands = (year, month, type) => {
    if (!(year && month && type)) {
      setBrand('');
      setModel('');
      setPropertiesVersion('');
      setBrandList([]);
      setModelList([]);
      setVersionList([]);
      return;
    }
    setisLoader(true);

    var formData = JSON.stringify({
      data: {
        vehicle_type_id: type,
        year: year,
        month: month,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-brands/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);

        if ((res.code !== undefined) & (res.code === 9034)) {
          setErrorModal({ modal: 'no_offer_available', open: true });
          setBrandList([]);
        } else {
          if (res.custom_data.brandList === null) {
            setBrandList([]);
          } else {
            setBrandList(res.custom_data.brandList);
          }
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getUserBrands = (type, year, month) => {
    setisLoader(true);
    var formData = JSON.stringify({
      data: {
        vehicle_type_id: type,
        year: year,
        month: month,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-brands/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        setBrandList(res.custom_data.brandList);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getModels = (brand_id) => {
    if (brand_id === '') {
      setModelList([]);
      setModel('');
      setVersionList([]);
      setPropertiesVersion('');
      return;
    }
    if (type !== '' && manufacturer_year !== '' && manufacturer_month !== '') {
      setisLoader(true);

      var formData = JSON.stringify({
        data: {
          brand_id: brand_id,
          vehicle_type_id: type,
          year: manufacturer_year,
          month: manufacturer_month,
        },
      });

      fetch(PROXY_PATH + '/custom-actions/get-models/execute', {
        method: 'post',
        headers: getHeaders(fingerprint, access_token),
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          setisLoader(false);

          if ((res.code !== undefined) & (res.code === 9034)) {
            setErrorModal({ modal: 'no_offer_available', open: true });
            setModelList([]);
          } else {
            if (res.custom_data.modelList === null) {
              setModelList([]);
            } else {
              setModelList(res.custom_data.modelList);
            }
          }
        })
        .catch((error) => {
          setisLoader(false);
        });
    }
  };

  const getUserModels = (
    type,
    brand_id,
    manufacturer_year,
    manufacturer_month
  ) => {
    setisLoader(true);
    var formData = JSON.stringify({
      data: {
        brand_id: brand_id,
        vehicle_type_id: type,
        year: manufacturer_year,
        month: manufacturer_month,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-models/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        setModelList(res.custom_data.modelList);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const getVersions = (model_id) => {
    if (
      type !== '' &&
      manufacturer_year !== '' &&
      manufacturer_month !== '' &&
      brand !== ''
    ) {
      if (model_id === '') {
        setVersionList([]);
        setPropertiesVersion('');
        return;
      }
      setisLoader(true);

      var formData = JSON.stringify({
        data: {
          brand_id: brand,
          model_id: model_id,
          vehicle_type_id: type,
          is_imported: 'false',
          year: manufacturer_year,
          month: manufacturer_month,
        },
      });

      fetch(PROXY_PATH + '/custom-actions/get-versions/execute', {
        method: 'post',
        headers: getHeaders(fingerprint, access_token),
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          setisLoader(false);

          if ((res.code !== undefined) & (res.code === 9034)) {
            setErrorModal({ modal: 'no_offer_available', open: true });
            setVersionList([]);
          } else {
            if (res.custom_data.versionList === null) {
              setVersionList([]);
            } else {
              // setPropertiesVersion(res.custom_data.versionList[0].tylaCode)
              setVersionList(res.custom_data.versionList);
            }
          }
        })
        .catch((error) => {
          setisLoader(false);
        });
    }
  };

  const getUserVersions = (
    type,
    model_id,
    manufacturer_year,
    manufacturer_month,
    brand
  ) => {
    setisLoader(true);
    var formData = JSON.stringify({
      data: {
        brand_id: brand,
        model_id: model_id,
        vehicle_type_id: type,
        is_imported: 'false',
        year: manufacturer_year,
        month: manufacturer_month,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-versions/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        setVersionList(res.custom_data.versionList);
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const isPostalCodeValid = (postal_code, namespace) => {
    setisLoader(true);
    var formData = JSON.stringify({
      data: {
        postal_code: postal_code,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-streets/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if (
          res.code !== undefined &&
          (res.code === 9913 || res.code === 9231)
        ) {
          setError(
            handleResponseError(
              error,
              res.text,
              checkFields,
              'Não foi possível encontrar um endereço para o código postal'
            )
          );
          return false;
        } else {
          const street = res.custom_data.street;
          if (Object.keys(street).length === 0) {
            setIsPostalCode(false);
            setError(
              updateErrorState(error, namespace, ['Código postal inválido'])
            );
          } else {
            setError(clearErrorStateNamespace(error, namespace));
            setIsPostalCode(true);
          }
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const enableButton = (error) =>
    Object.keys(error).length === 0 ||
    Object.keys(error).every(
      (key) => key === 'root' || error[key]?.length === 0
    );

  const handleSubmitFirstStep = async (e) => {
    e.preventDefault();
    setError({});
    let initialError = {};
    const formFields = {
      registration: registration,
      vehicle_type: type,
      manufacturer_year: manufacturer_year,
      manufacturer_month: manufacturer_month,
      brand: brand,
      model: model,
      version: properties_version,
      is_imported: is_imported,
      has_trailer: has_trailer,
      driver_name: driver_name,
      driver_nif: driver_nif,
      driver_birthdate: driver_birthdate,
      driver_gender: driver_gender,
      driver_license_month_year: driver_license_date,
      driver_postal_code: driver_postal_code,
      driver_email: driver_email,
    };
    const optionalFields = ['registration'];

    if (usertype === 'lead') {
      formFields['client_email'] = client_email;
      formFields['client_mobile'] = client_mobile;
      formFields['not_seller_confirmation'] = not_seller_confirmation;
      formFields['data_sharing_confirmation'] = data_sharing_confirmation;
      optionalFields.push('client_email', 'client_mobile');

      if (client_email === '' && client_mobile === '') {
        initialError['client_email'] = [errorMessages.error_message_fill_one];
        initialError['client_mobile'] = [errorMessages.error_message_fill_one];
      }
    }
    const emptyMandatoryFields = Object.keys(formFields).filter(
      (namespace) =>
        !formFields[namespace] && !optionalFields.includes(namespace)
    );
    if (emptyMandatoryFields.length > 0) {
      initialError = updateErrorState(initialError, 'root', [
        'Verifique os campos por preencher',
      ]);
    }
    const validation = validateFields(formFields, initialError, optionalFields);
    if (!validation.success) {
      setError(validation.updatedErrorState);
      return;
    }
    setIsTableRefreshing(false);
    firstFormSubmit();
  };

  const firstFormSubmit = () => {
    setShowVehicleFormOnStepThree(
      registration === '' || registration === '__-__-__'
    );
    const [driverLicenseYear, driverLicenseMonth] =
      formatDate(driver_license_date).split('-');
    const driverLicenseMonthYear = `${driverLicenseYear}-${driverLicenseMonth}`;
    const driverbirthdate = formatDate(driver_birthdate);
    const policy_date = formatDate(new Date());

    setisLoader(true);
    var distribution_details;
    const storedURL = getItemLocalStorage(AUTOKEY_REDIRECT_URL);
    const getSourceUriFromLocation = (url) => {
      const lastSlashIndex = url.lastIndexOf('/');
      return url.substring(0, lastSlashIndex);
    };
    const sourceUri = storedURL
      ? getSourceUriFromLocation(storedURL)
      : getSourceUriFromLocation(window.location.href);

    if (usertype === 'guest') {
      distribution_details = {
        flow: 'guest',
        distribution_point: {
          id: '',
        },
        seller: {
          id: '',
        },
        source_uri: sourceUri,
      };
    } else {
      distribution_details = {
        flow: usertype,
        distribution_point: {
          id: distributionpoint_id,
        },
        seller: {
          id: distributorseller_id,
        },
        source_uri: sourceUri,
      };
    }

    if (usertype === 'lead') {
      distribution_details = {
        flow: usertype,
        distribution_point: {
          id: distributionpoint_id,
        },
        seller: {
          id: distributorseller_id,
        },
        user_contact: {
          email: client_email,
          mobile: client_mobile,
        },
        source_uri: sourceUri,
      };
    }

    var quote_properties = [
      {
        namespace: 'distribution_details',
        data: JSON.stringify(distribution_details),
      },
      {
        namespace: 'sales_channel',
        data: 'b2c-store-habit',
      },
      {
        namespace: 'policy_start_date',
        data: policy_date,
      },
    ];

    var protectedasset_properties = [
      {
        namespace: 'registration',
        data: registration,
      },
      {
        namespace: 'vehicle_type',
        data: type,
      },
      {
        namespace: 'manufacturer_year',
        data: manufacturer_year,
      },
      {
        namespace: 'manufacturer_month',
        data: manufacturer_month,
      },
      {
        namespace: 'brand',
        data: brand,
      },
      {
        namespace: 'model',
        data: model,
      },
      {
        namespace: 'version',
        data: properties_version,
      },
      {
        namespace: 'is_imported',
        data: is_imported,
      },
      {
        namespace: 'has_trailer',
        data: has_trailer,
      },
      {
        namespace: 'driver_name',
        data: driver_name,
      },
      {
        namespace: 'driver_nif',
        data: driver_nif,
      },
      {
        namespace: 'driver_birthdate',
        data: driverbirthdate,
      },
      {
        namespace: 'driver_license_month_year',
        data: driverLicenseMonthYear,
      },
      {
        namespace: 'driver_postal_code',
        data: driver_postal_code,
      },
      {
        namespace: 'driver_email',
        data: driver_email,
      },
      {
        namespace: 'driver_gender',
        data: driver_gender,
      },
    ];

    var user = {
      name: driver_name,
      email: client_email === '' ? driver_email : client_email,
      mobile: client_mobile,
    };

    const isNewQuote = !(
      (Boolean(quote_id) && Boolean(protectedasset_id)) ||
      usertype === 'user'
    );
    const url = isNewQuote
      ? `${PROXY_PATH}/versions/${VERSION}/submit-quote`
      : `${PROXY_PATH}/protected-assets/${protectedasset_id}/update-properties`;
    const payload = isNewQuote
      ? {
          quote_properties: [...quote_properties],
          protectedasset_properties: [...protectedasset_properties],
          user: user,
        }
      : { properties: [...protectedasset_properties] };

    fetch(url, {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404 || response.status === 504)
            setErrorModal({ modal: 'error_on_subscription', open: true });
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((res) => {
        setisLoader(false);
        if (res.text !== undefined) {
          setError(
            handleResponseError(
              error,
              res.text,
              checkFields,
              'Algo errado ocorreu na validação'
            )
          );
          return false;
        }
        var simulated = res.simulated;

        if (simulated.error_response !== undefined) {
          if (simulated.error_response.code === 9034)
            setErrorModal({ modal: 'no_offer_available', open: true });
          setError(
            handleResponseError(
              error,
              simulated.error_response.text,
              checkFields,
              'Algo errado ocorreu na requisição'
            )
          );
          return false;
        }
        const quoteIDFromAPIOrCurrent =
          res.created !== undefined ? res.created.id : quoteid;
        if (!isNullOrUndefined(res.created)) {
          setProtectedassetId(res.created.protected_assets[0].id);
          setQuoteId(quoteIDFromAPIOrCurrent);
          setItemLocalStorage(AUTOKEY_QUOTEID, quoteIDFromAPIOrCurrent);
        }

        if (usertype === 'lead') navigate('/success');

        if (simulated.quote_properties !== undefined) {
          const currentSimulatedQuoteProperties =
            quoteSpecs.simulated_quote_properties === undefined
              ? []
              : quoteSpecs.simulated_quote_properties;

          const currentCoveragesByQuote = simulated.quote_properties.find(
            (property) => property.namespace === 'coverages_by_quote'
          );
          if (currentCoveragesByQuote?.data) {
            setCoveragesByQuote(() => {
              return JSON.parse(currentCoveragesByQuote.data);
            });
            setUICoveragesByQuote(JSON.parse(currentCoveragesByQuote.data));
          }
          const updatedQuoteProperties = currentSimulatedQuoteProperties.map(
            (existingProperty) => {
              const matchingProperty = simulated.quote_properties.find(
                (property) => property.namespace === existingProperty.namespace
              );
              if (matchingProperty !== undefined) return matchingProperty;
              return existingProperty;
            }
          );

          const newQuoteProperties = simulated.quote_properties.filter(
            (newProperty) =>
              !currentSimulatedQuoteProperties.some(
                (existingProperty) =>
                  existingProperty.namespace === newProperty.namespace
              )
          );

          setQuoteSpecs((previousQuoteSpecs) => ({
            ...previousQuoteSpecs,
            simulated_quote_properties: [
              ...updatedQuoteProperties,
              ...newQuoteProperties,
            ],
          }));

          const ratebyproduct = simulated.quote_properties.find(
            (x) => x.namespace === 'rate_by_product'
          )?.data;

          const ratebyproductDataFromAPIOrCurrent =
            ratebyproduct === undefined
              ? rate_by_product
              : JSON.parse(ratebyproduct);

          setRateByProduct(ratebyproductDataFromAPIOrCurrent);

          const chosenproductData = simulated.quote_properties.find(
            (x) => x.namespace === 'chosen_product'
          );

          const productsArrayFromAPIOrCurrent =
            chosenproductData === undefined
              ? chosen_products
              : chosenproductData.options;

          const chosenProductFromAPIOrCurrent =
            chosenproductData === undefined
              ? chosen_product
              : chosenproductData.data;

          setChosenProducts(productsArrayFromAPIOrCurrent);
          setChosenProduct(chosenProductFromAPIOrCurrent);

          const planLabels = simulated.quote_properties.find(
            (x) => x.namespace === 'payment_frequency'
          );
          const planLabelsFromAPIOrCurrent =
            planLabels === undefined ? planlabel : planLabels.options;

          setPlanlabel(planLabelsFromAPIOrCurrent);

          var product =
            ratebyproductDataFromAPIOrCurrent[chosenProductFromAPIOrCurrent];

          const paymentFrequencies = Object.keys(product);
          if (paymentFrequencies.length === 0) {
            setError(
              updateErrorState(error, 'root', [
                'Houve um erro obtendo-se as formas de pagamento',
              ])
            );
            console.error('properties w/o payment frequency list');
            return false;
          }
          const firstPaymentFrequency = paymentFrequencies[0];
          setPaymentFrequency(firstPaymentFrequency);
          setTotalAmount(product[firstPaymentFrequency].first_installment);

          const simulatedpaymentfrequency = simulated.quote_properties.find(
            (x) => x.namespace === 'payment_frequency'
          )?.options;
          const simulatedpaymentfrequencyFromAPIOrCurrent =
            simulatedpaymentfrequency === undefined
              ? simulated_payment_frequency
              : simulatedpaymentfrequency;
          setSimulatedPaymentFrequency(
            simulatedpaymentfrequencyFromAPIOrCurrent
          );
          getCcoveragesByQuote(
            quoteIDFromAPIOrCurrent,
            chosenProductFromAPIOrCurrent
          );

          const foundFinancialEntities =
            res.customized?.protected_asset_properties?.find(
              (property) => property.namespace === 'financial_entity'
            )?.options;

          if (foundFinancialEntities) {
            setFinancialEntities(foundFinancialEntities);
          }

          setStep(2);
          setisLoader(false);
          scrollToTop();
        }
      })
      .catch((error) => {
        setisLoader(false);
      });

    return false;
  };

  const getCcoveragesByQuote = (quoteid, selected_item) => {
    setisLoader(true);

    var formData = JSON.stringify({
      data: {
        quote_id: quoteid,
        filter_base_coverages: false,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-coverages-by-quote/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if (res.text !== undefined) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          setErrorModal({ modal: 'error_on_subscription', open: true });
        } else {
          var coveragesByProduct = res.custom_data.coverages_by_product;
          setCoveragesByProductArray(coveragesByProduct);
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const handleSubmitSecondStep = async (e) => {
    e.preventDefault();
    const isChosenProductChecked = productsCheckedWithUI[chosen_product];
    const result = await updatePropertiesSecondStep(isChosenProductChecked);
    setisLoader(false);
    if (result.success) {
      if (usertype === 'seller') setIsButtonSendSMS(true);
      if (isChosenProductChecked) setStep(3);
    }
    return false;
  };

  const updateOldRegistrations = async () => {
    const { SUCCESS, NOT_FOUND, BEST_OFFER } = DISCOUNT_STATES;
    const getDiscountRateFromValue = (withDiscount, ref) => {
      return Math.floor((100 * (ref - withDiscount)) / ref);
    };

    let quoteID = quote_id;
    const isQuoteIDSet = Boolean(quoteID);
    if (!isQuoteIDSet) quoteID = getItemLocalStorage(AUTOKEY_QUOTEID);
    setQuoteId(quoteID);

    const formerRateBase = total_amount;
    let result = BEST_OFFER;
    try {
      setIsTableRefreshing(true);

      const url = `${PROXY_PATH}/quotes/${quoteID}/update-properties`;
      const update = JSON.stringify({
        properties: [
          {
            namespace: 'old_registrations',
            data: arrayToCSV(old_registrations),
          },
          {
            namespace: 'policy_start_date',
            data: dateToString(policy_start_date),
          },
        ],
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(fingerprint, access_token),
        body: update,
      });

      if (!response.ok) {
        updateResultMessage(NOT_FOUND);
        if (response.status === 404 || response.status === 504) {
          setErrorModal({ modal: 'error_on_subscription', open: true });
        }
        throw new Error('Network response was not ok');
      }

      const responseJSON = await response.json();

      if (responseJSON.text !== undefined) {
        setIsTableRefreshing(false);
        updateResultMessage(NOT_FOUND);
        setError(
          handleResponseError(
            error,
            responseJSON.text,
            checkFields,
            'Algo errado ocorreu na validação'
          )
        );
        return false;
      }

      const simulatedQuoteProperties =
        responseJSON?.simulated?.quote_properties ?? [];
      if (simulatedQuoteProperties.length > 0) {
        setQuoteSpecs((previousQuoteSpecs) => {
          const previousQuoteProperties =
            previousQuoteSpecs.simulated_quote_properties;
          const notToBeUpdatedQuoteProperties = previousQuoteProperties.filter(
            (notUpdatedProperty) =>
              simulatedQuoteProperties.every(
                (newProperty) =>
                  newProperty.namespace !== notUpdatedProperty.namespace
              )
          );
          const updatedQuoteProperties = [
            ...simulatedQuoteProperties,
            ...notToBeUpdatedQuoteProperties,
          ];
          return {
            ...previousQuoteSpecs,
            simulated_quote_properties: [...updatedQuoteProperties],
          };
        });
      }
      const simulatedRateBase =
        simulatedQuoteProperties.find(
          (property) => property.namespace === 'rate_base'
        )?.data ?? formerRateBase;
      const { data: simulatedCoveragesByQuote } = simulatedQuoteProperties.find(
        (property) => property.namespace === 'coverages_by_quote'
      ) ?? { data: null };
      const { data: simulatedRateByProduct } = simulatedQuoteProperties.find(
        (property) => property.namespace === 'rate_by_product'
      ) ?? { data: null };

      if (simulatedRateBase < formerRateBase) result = SUCCESS;
      setTotalAmount(simulatedRateBase);
      updateResultMessage(
        result,
        getDiscountRateFromValue(simulatedRateBase, formerRateBase)
      );

      if (simulatedRateByProduct)
        setRateByProduct(JSON.parse(simulatedRateByProduct));

      if (simulatedCoveragesByQuote) {
        setCoveragesByQuote(JSON.parse(simulatedCoveragesByQuote));
        setUICoveragesByQuote(JSON.parse(simulatedCoveragesByQuote));
      }

      setIsTableRefreshing(false);
    } catch (error) {
      console.error('Failed to update registrations:', error);
      updateResultMessage(NOT_FOUND);
      setIsTableRefreshing(false);
    }
  };

  const updatePropertiesSecondStep = async (shouldLoad) => {
    setisLoader(shouldLoad);
    setError({});
    let initialError = {};
    const formFields = {
      chosen_product: chosen_product,
      payment_frequency: payment_frequency,
      policy_start_date: policy_start_date,
    };
    const optionalFields = [];
    const emptyMandatoryFields = Object.keys(formFields).filter(
      (namespace) =>
        !formFields[namespace] && !optionalFields.includes(namespace)
    );
    if (emptyMandatoryFields.length > 0) {
      initialError = updateErrorState(error, 'root', [
        'Verifique os campos por preencher',
      ]);
    }
    const validation = validateFields(formFields, initialError, optionalFields);
    if (!validation.success) {
      setError(validation.updatedErrorState);
      setisLoader(false);
      return;
    }
    try {
      if (isOldRegistrationsDifferent) updateOldRegistrations();

      setIsTableRefreshing(true);

      const formData = JSON.stringify({
        properties: [
          { namespace: 'chosen_product', data: chosen_product },
          { namespace: 'payment_frequency', data: payment_frequency },
          {
            namespace: 'policy_start_date',
            data: dateToString(policy_start_date),
          },
          {
            namespace: 'coverages_by_quote',
            data: JSON.stringify(UICoveragesByQuote),
          },
        ],
      });
      const isQuoteIDSet =
        quote_id !== '' && quote_id !== undefined && quote_id !== null;
      if (!isQuoteIDSet) setQuoteId(getItemLocalStorage(AUTOKEY_QUOTEID));

      var url = `${PROXY_PATH}/quotes/${
        isQuoteIDSet ? quote_id : getItemLocalStorage(AUTOKEY_QUOTEID)
      }/update-properties`;

      const response = await fetch(url, {
        method: 'post',
        headers: getHeaders(fingerprint, access_token),
        body: formData,
      });
      if (!response.ok) {
        if (response.status === 404 || response.status === 504)
          setErrorModal({ modal: 'error_on_subscription', open: true });
        throw new Error('Network response was not ok');
      }
      const responseJSON = await response.json();
      setisLoader(false);
      if (responseJSON.text !== undefined) {
        setError(
          handleResponseError(
            error,
            responseJSON.text,
            checkFields,
            'Algo errado ocorreu na validação'
          )
        );
        return false;
      }

      var simulated = responseJSON.simulated;
      if (simulated.error_response !== undefined) {
        setError(
          handleResponseError(
            error,
            simulated.error_response.text,
            checkFields,
            'Algo errado ocorreu na validação'
          )
        );
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return false;
      } else {
        if (usertype === 'seller') setIsButtonSendSMS(true);
        const responseQuoteProperties = simulated.quote_properties;
        const currentCoveragesByQuote = responseJSON.properties.find(
          (property) => property.namespace === 'coverages_by_quote'
        );

        if (currentCoveragesByQuote?.data) {
          setCoveragesByQuote(JSON.parse(currentCoveragesByQuote.data));
          setUICoveragesByQuote(JSON.parse(currentCoveragesByQuote.data));
        }

        const currentSimulatedQuoteProperties =
          quoteSpecs.simulated_quote_properties;

        const updatedQuoteProperties = currentSimulatedQuoteProperties.map(
          (existingProperty) => {
            const matchingProperty = responseQuoteProperties.find(
              (property) => property.namespace === existingProperty.namespace
            );
            if (matchingProperty !== undefined) return matchingProperty;
            return existingProperty;
          }
        );

        const newQuoteProperties = responseQuoteProperties.filter(
          (newProperty) =>
            !currentSimulatedQuoteProperties.some(
              (existingProperty) =>
                existingProperty.namespace === newProperty.namespace
            )
        );

        setQuoteSpecs((previousQuoteSpecs) => ({
          ...previousQuoteSpecs,
          simulated_quote_properties: [
            ...updatedQuoteProperties,
            ...newQuoteProperties,
          ],
        }));

        const ratebyproduct = responseQuoteProperties.find(
          (x) => x.namespace === 'rate_by_product'
        )?.data;

        if (ratebyproduct !== undefined) {
          const ratebyproductData = JSON.parse(ratebyproduct);
          setRateByProduct(ratebyproductData);
        }

        const rate_base = responseQuoteProperties.find(
          (x) => x.namespace === 'rate_base'
        );

        if (rate_base !== undefined) {
          setTotalAmount(rate_base.data);
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return { success: true, step: 3 };
      }
    } catch (error) {
      console.log(error);
      setIsTableRefreshing(false);
      return { success: false, step: 2 };
    } finally {
      setIsTableRefreshing(false);
    }
  };

  const handleSubmitThirdStep = (e) => {
    e.preventDefault();
    setError({});
    let initialError = {};

    if (usertype === 'seller' && isButtonSendSMS === false) {
      finishSubmission();
      return;
    }

    const formFields = {
      registration: registration,
      insuree_is_driver: insuree_is_driver,
      driver_name: driver_name,
      driver_nif: driver_nif,
      driver_birthdate: driver_birthdate,
      driver_marital_state: driver_marital_state,
      driver_license_date: driver_license_date,
      driver_license_number: driver_license_number,
      driver_gender: driver_gender,
      driver_email: driver_email,
      driver_phone: driver_phone,
      driver_mobile: driver_mobile,
      driver_address: driver_address,
      driver_postal_code: driver_postal_code,
      driver_city: driver_city,

      name: name,
      nif: nif,
      birthdate: birthdate,
      marital_state: marital_state,
      gender: gender,
      email: email,
      phone: phone,
      mobile: mobile,
      address: address,
      postal_code: postal_code,
      city: city,

      has_financial_entity: hasFinancialEntity,
      financial_entity: financialEntity,
    };
    const optionalFields = ['driver_phone', 'phone'];
    if (insuree_is_driver === 'sim') {
      optionalFields.push(
        'name',
        'nif',
        'birthdate',
        'marital_state',
        'gender',
        'email',
        'mobile',
        'address',
        'postal_code',
        'city'
      );
      optionalFields.forEach((field) => {
        if (field !== 'driver_phone') delete formFields[field];
      });
    }
    if (hasFinancialEntity === '0') {
      optionalFields.push('financial_entity');
    }
    const emptyMandatoryFields = Object.keys(formFields).filter(
      (namespace) =>
        !formFields[namespace] && !optionalFields.includes(namespace)
    );
    if (emptyMandatoryFields.length > 0)
      initialError = updateErrorState(error, 'root', [
        'Verifique os campos por preencher',
      ]);
    const validation = validateFields(formFields, initialError, optionalFields);
    if (!validation.success) {
      setError(validation.updatedErrorState);
      return;
    }

    if (is_postal_code === false) {
      setError(
        updateErrorState(error, 'driver_postal_code', [
          'Código postal inválido',
        ])
      );
      return false;
    }

    var driverbirthdate = formatDate(driver_birthdate);
    const [driverLicenseYear, driverLicenseMonth] =
      formatDate(driver_license_date).split('-');
    const driverLicenseMonthYear = `${driverLicenseYear}-${driverLicenseMonth}`;

    setisLoader(true);
    const formData = {
      properties: [
        {
          namespace: 'registration',
          data: registration,
        },
        {
          namespace: 'driver_name',
          data: driver_name,
        },
        {
          namespace: 'driver_nif',
          data: driver_nif,
        },
        {
          namespace: 'driver_birthdate',
          data: driverbirthdate,
        },
        {
          namespace: 'driver_marital_state',
          data: driver_marital_state,
        },
        {
          namespace: 'driver_license_date',
          data: driverLicenseMonthYear,
        },
        {
          namespace: 'driver_license_number',
          data: driver_license_number,
        },
        {
          namespace: 'driver_email',
          data: driver_email,
        },
        {
          namespace: 'driver_phone',
          data: driver_phone,
        },
        {
          namespace: 'driver_mobile',
          data: driver_mobile,
        },
        {
          namespace: 'driver_postal_code',
          data: driver_postal_code,
        },
        {
          namespace: 'driver_address',
          data: driver_address,
        },
        {
          namespace: 'driver_city',
          data: driver_city,
        },
        { namespace: 'has_financial_entity', data: hasFinancialEntity },
      ],
    };
    if (hasFinancialEntity === '1') {
      formData.properties.push({
        namespace: 'financial_entity',
        data: financialEntity,
      });
    }

    const formPayload = JSON.stringify(formData);

    fetch(
      PROXY_PATH +
        '/protected-assets/' +
        protectedasset_id +
        '/update-properties',
      {
        method: 'post',
        headers: getHeaders(fingerprint, access_token),
        body: formPayload,
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404 || response.status === 504) {
            setErrorModal({ modal: 'error_on_subscription', open: true });
            throw new Error('request was not ok');
          }
        }
        return response.json();
      })
      .then((res) => {
        const { simulated } = res;
        const [, inputWithError] = simulated?.error_response?.text?.split(
          ':'
        ) ?? [null, ''];
        if (
          simulated?.error_response &&
          inputWithError?.trim() !== 'digital_signature_received'
        ) {
          setError(
            handleResponseError(
              error,
              simulated.error_response.text,
              checkFields,
              'Algo errado ocorreu na validação'
            )
          );
        } else {
          const updatedRateBase = simulated?.quote_properties?.find(
            (property) => property.namespace === 'rate_base'
          );
          if (updatedRateBase !== undefined) setTotalAmount(updatedRateBase);
          const updatedPaymentHash = simulated?.smart_link?.hash;
          if (updatedPaymentHash) setPaymentHash(updatedPaymentHash);
        }
        policyHolderUpdate();
      })
      .catch((error) => {
        setisLoader(false);
      });
    return false;
  };

  const policyHolderUpdate = () => {
    const getProperties = () => {
      const commonProperties = {
        insuree_is_driver: insuree_is_driver,
        receive_email_marketing: receive_email_marketing.toString(),
        privacy_policy_consent: privacy_policy_consent.toString(),
      };
      const sensibleProperties =
        insuree_is_driver === 'sim'
          ? {
              name: driver_name,
              birthdate: formatDate(driver_birthdate),
              nif: driver_nif,
              marital_state: driver_marital_state,
              gender: driver_gender,
              email: driver_email,
              phone: driver_phone,
              mobile: driver_mobile,
              postal_code: driver_postal_code,
              address: driver_address,
              city: driver_city,
            }
          : {
              name,
              birthdate: formatDate(birthdate),
              nif,
              marital_state,
              gender,
              email,
              phone,
              mobile,
              postal_code,
              address,
              city,
            };

      return Object.entries({ ...commonProperties, ...sensibleProperties }).map(
        ([key, value]) => ({ namespace: key, data: value })
      );
    };

    const getBody = () => {
      if (usertype === 'user') {
        return JSON.stringify({
          quote_id: getItemLocalStorage(AUTOKEY_QUOTEID),
          policyholder_id: policyholder_id ?? '',
          properties: getProperties(),
        });
      }

      return JSON.stringify({
        quote_id: quote_id,
        policyholder_id: policyholder_id ?? '',
        properties: getProperties(),
      });
    };

    fetch(PROXY_PATH + '/versions/' + VERSION + '/policy-holders', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: getBody(),
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        setPolicyholderId(res.id ?? null);
        setUserId(res.user_id ?? null);
        setPaymentHash(res.simulated?.smart_link?.hash);
        const errorText =
          res?.text || res.simulated?.error_response?.text || '';
        const [, inputWithError] = errorText.split(':') ?? [null, ''];

        if (
          errorText !== '' &&
          inputWithError !== 'digital_signature_received'
        ) {
          setError(
            handleResponseError(
              error,
              errorText,
              checkFields,
              'Algo errado ocorreu na validação'
            )
          );
          return false;
        } else {
          if (isButtonSendSMS) {
            sendSmsCode();
          } else {
            finishSubmission();
          }
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const sendSmsCode = () => {
    setisLoader(true);
    var formData = JSON.stringify({
      data: {
        quote_id: quote_id,
      },
    });

    fetch(PROXY_PATH + '/custom-actions/get-sms-code/execute', {
      method: 'post',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        if (res.text !== undefined) {
          setError(
            handleResponseError(
              error,
              res.text,
              checkFields,
              'Algo errado ocorreu na validação do SMS'
            )
          );
          return false;
        } else {
          setIsButtonSendSMS(false);
          if (usertype === 'seller') setShowSMSField(true);
        }
      })
      .catch((error) => {
        setisLoader(false);
      });
  };

  const finishSubmission = () => {
    if (showSMSField === false) {
      setStep(4);
      return;
    }

    if (digital_signature_received === '' && usertype === 'seller') {
      setError(updateErrorState(error, 'digital_signature', 'Digite o SMS'));
      return false;
    }

    setisLoader(true);
    var formData = JSON.stringify({
      properties: [
        {
          namespace: 'digital_signature_received',
          data: digital_signature_received,
        },
        {
          namespace: 'policy_start_date',
          data: dateToString(policy_start_date),
        },
      ],
    });

    fetch(PROXY_PATH + '/quotes/' + quote_id + '/update-properties', {
      method: 'POST',
      headers: getHeaders(fingerprint, access_token),
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        setisLoader(false);
        const simulated = res.simulated;
        if (simulated.error_response !== undefined) {
          setError(
            handleResponseError(
              error,
              simulated.error_response.text,
              checkFields,
              'Algo errado ocorreu na validação'
            )
          );
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          return false;
        } else {
          const updatedPaymentHash = simulated?.smart_link?.hash ?? '';
          if (updatedPaymentHash) setPaymentHash(updatedPaymentHash);
          if (usertype === 'seller') setIsButtonSendSMS(true);
          setShowSMSField(false);
          setDigitalSignatureReceived('');
          setStep(4);
        }
      })
      .catch((error) => {
        setisLoader(false);
        console.log(error);
      });
  };

  const handleSubmitFourthStep = (event) => {
    event.preventDefault();
  };

  const mbnumberInput = document.querySelector('input[name="mbway_number"]');
  if (mbnumberInput !== null) {
    mbnumberInput.onkeypress = function () {
      return this.value.length >= 11 ? false : true;
    };
  }

  const getSelectedVersionValue = (version_list) => {
    if (version_list?.length === 0 || version_list?.length === undefined)
      return 'Selecione';
    const selectedVersion = version_list.find(
      (item) => item.tylaCode === properties_version
    );
    if (selectedVersion === undefined) return 'Selecione';
    return selectedVersion.version;
  };
  const formatPriceInEuros = (price) => {
    if (!price) return '--';
    //'looks for' no character boundaries every 3 digits and replaces them for a dot
    const dottedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${dottedPrice} €`;
  };

  const getMissingDataLabelForBrand = () => {
    const dictionary = {
      vehicle_type: type ?? '',
      manufacturer_year: manufacturer_year ?? '',
      manufacturer_month: manufacturer_month ?? '',
    };

    if (type === 0) dictionary.vehicle_type = '';
    const missingValuesKeys = Object.keys(dictionary).filter(
      (key) => dictionary[key] === ''
    );
    const missingLabels = missingValuesKeys?.map((key) => {
      const spec = getSpecsByNamespace('protectedassetproperty_specs', key);
      const specLabel = spec?.label ?? '';
      const firstWord = specLabel.split(' ')[0];
      return firstWord;
    });

    if (missingLabels && missingLabels.length > 1)
      return (
        missingLabels.slice(0, -1).join(', ') + ' e ' + missingLabels.slice(-1)
      );

    return missingLabels[0] || '';
  };

  const getSpecsByNamespace = (mainArray, namespace) => {
    const checkValue = (value, errorMessage) => {
      if (!value) {
        throw new Error(errorMessage);
      }
    };

    if (quoteSpecs === null) return null;

    const foundArray = quoteSpecs[mainArray];
    if (isNullOrUndefined(foundArray)) return null;

    const foundObject = foundArray.find((item) => item.namespace === namespace);
    checkValue(
      foundObject,
      `Namespace '${namespace}' not found in main array '${mainArray}'.`
    );

    return foundObject;
  };

  const mandatoryFieldsStepOne = [
    type,
    manufacturer_month,
    manufacturer_year,
    brand,
    model,
    properties_version,
    is_imported,
    has_trailer,
    driver_name,
    driver_nif,
    driver_birthdate,
    driver_gender,
    driver_license_date,
    driver_postal_code,
    driver_email,
  ];

  const mandatoryFieldsStepTwo = [
    chosen_product,
    payment_frequency,
    policy_start_date,
  ];

  const mandatoryFieldsStepThree = [
    driver_name,
    driver_nif,
    driver_birthdate,
    driver_marital_state,
    driver_license_date,
    driver_license_number,
    driver_email,
    driver_postal_code,
    driver_city,
    driver_mobile,
    privacy_policy_consent,
  ];

  if (insuree_is_driver === 'nao') {
    mandatoryFieldsStepThree.push(
      name,
      birthdate,
      nif,
      marital_state,
      gender,
      email,
      mobile,
      address,
      postal_code,
      city
    );
  }

  if (showSMSField) mandatoryFieldsStepThree.push(digital_signature_received);

  const emptyMandatoryFieldsStepOne = mandatoryFieldsStepOne.filter(
    (field) => !field
  );
  const emptyMandatoryFieldsStepTwo = mandatoryFieldsStepTwo.filter(
    (field) => !field
  );
  const emptyMandatoryFieldsStepThree = mandatoryFieldsStepThree.filter(
    (field) => !field
  );

  const isThirdStepButtonDisabled = emptyMandatoryFieldsStepThree.length > 0;

  const makeProperSpecsForUser = (planlabel) => {
    const initialSpecs = {
      namespace: 'payment_frequency',
      options: planlabel,
    };
    const simulatedSpecs = getSpecsByNamespace(
      'simulated_quote_properties',
      'payment_frequency'
    );

    if (!isNullOrUndefined(simulatedSpecs)) return simulatedSpecs;
    if (Array.isArray(initialSpecs.options)) return initialSpecs;
    return undefined;
  };

  const screenCut = (() => {
    if (step !== 2 || chosen_products?.length === undefined) return 'md';
    if (chosen_products?.length < 4) return 'md';
    if (chosen_products?.length === 4) return 'lg';
    return 'xl';
  })();

  const getDisplayClass = (displayForm, screenSize) => {
    const getMobileDisplayClass = (displayForm, screenCut) => {
      return `d-${displayForm} d-${screenCut}-none`;
    };
    const getPCDisplayClass = (displayForm, screenCut) => {
      return `d-none d-${screenCut}-${displayForm}`;
    };
    if (screenSize === 'mobile') {
      return getMobileDisplayClass(displayForm, screenCut);
    }
    return getPCDisplayClass(displayForm, screenCut);
  };

  return (
    <Fragment>
      <Loading isLoading={isLoader} dotColor={primaryColor} />
      <ErrorModal
        modal={errorModal}
        setModal={setErrorModal}
        title={errorModals[errorModal.modal].title}
        description={errorModals[errorModal.modal].description}
        image={errorModals[errorModal.modal].image}
      />
      <FormModal
        open={isEmailModalOpen}
        closeModal={() => setIsEmailModalOpen(false)}
        FormComponent={() =>
          EmailForm({
            closeModal: () => {
              setIsEmailModalOpen(false);
            },
          })
        }
      />
      <RegistrationModal
        isOpen={isRegistrationDialogOpen}
        setIsOpen={setIsRegistrationDialogOpen}
        setStep={setStep}
      />
      <VersionListModal
        isOpen={isVersionListOpen}
        setIsOpen={setIsVersionListOpen}
        versionList={version_list}
        propertiesVersion={properties_version}
        handleInputChange={handleInputChange}
        formatPriceInEuros={formatPriceInEuros}
      />
      <div className="simulation-data simulator-result d-flex align-items-stretch">
        <div className={`${step === 'versionList' ? '' : 'left-content'}`}>
          {step === 1 && (
            <div className="step-one">
              <div className="mobile-top-bar">
                <h3 className="top-bar-heading">
                  {textRefobject[stepOneTitleTag][defaultLanguage]}
                </h3>
                <ul className="nav">
                  <li className="nav-item active">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                </ul>
              </div>

              <div className="text-wrapper main-content mb-3">
                <form method="post" onSubmit={handleSubmitFirstStep} action="">
                  <div
                    className="left-content-heading d-flex align-items-center"
                    style={{ color: secondaryColor }}
                  >
                    <span className="me-3">1</span>
                    {textRefobject[stepOneTitleTag][defaultLanguage]}
                  </div>
                  <div
                    className="simulator-text"
                    style={{ color: secondaryColor }}
                  >
                    Dados do Veículo
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <InputMask
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'registration'
                        )}
                        mask="**-**-**"
                        placeholder="XX-XX-XX"
                        value={registration}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                          if (
                            e.target.value === '' ||
                            e.target.value.includes('_')
                          )
                            clearVehicleData();
                        }}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <SelectInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'vehicle_type'
                        )}
                        value={type}
                        placeholder="Selecione"
                        optionsArray={vehicle_types}
                        optionData="id"
                        optionLabel="type"
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      {isLoader ? (
                        <div className="form placeholder-glow">
                          <span className="placeholder col-3" />
                          <span className="placeholder mt-3 col-12" />
                        </div>
                      ) : (
                        <label htmlFor="manufacturer_year">
                          {
                            theme.form_sections.texts[stepOneFields[0].label][
                              defaultLanguage
                            ]
                          }
                        </label>
                      )}
                      <div className="d-flex justify-content-between">
                        <SelectInput
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'manufacturer_year'
                          )}
                          noLabel={true}
                          value={manufacturer_year}
                          placeholder="Selecione"
                          optionsArray={allYears}
                          optionData=""
                          optionLabel=""
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                        <div className="separator-x"></div>
                        <SelectInput
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'manufacturer_month'
                          )}
                          noLabel={true}
                          value={manufacturer_month}
                          placeholder="Selecione"
                          optionsArray={months}
                          optionData="data"
                          optionLabel="label"
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <SelectInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'brand'
                        )}
                        value={brand}
                        placeholder={'Selecione'}
                        optionsArray={brand_list}
                        optionData="id"
                        optionLabel="brand"
                        isDisabled={brand_list.length === 0}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <SelectInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'model'
                        )}
                        value={model}
                        placeholder={'Selecione'}
                        optionsArray={model_list}
                        isDisabled={model_list.length === 0}
                        optionData="id"
                        optionLabel="model"
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    {/* version table (Large Screen) */}
                    <div
                      className={`col-12 form-group ${getDisplayClass(
                        'block',
                        'pc'
                      )}`}
                    >
                      <table
                        className={`table table-striped table-borderless ${
                          (model === '' || model === '0') && 'collapse'
                        }`}
                      >
                        <thead>
                          <tr className="height-44">
                            <th className="col-6 version-table-header-title">
                              Versão
                            </th>
                            <th className="version-table-header-row">
                              Cilindrada
                            </th>
                            <th className="version-table-header-row">
                              Potência
                            </th>
                            <th className="version-table-header-row">
                              Nº Portas
                            </th>
                            <th className="version-table-header-row">
                              Nº Lugares
                            </th>
                            <th className="version-table-header-row">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {version_list.map(
                            ({
                              tylaCode,
                              version,
                              cc,
                              hp,
                              doors,
                              seats,
                              price,
                            }) => (
                              <tr
                                key={tylaCode}
                                className={`height-44 ${
                                  tylaCode === properties_version
                                    ? 'table-active-row'
                                    : ''
                                }`}
                              >
                                <td>
                                  <div className="form-check">
                                    <input
                                      className={`form-check-input`}
                                      type="radio"
                                      name="properties_version"
                                      id={tylaCode}
                                      checked={properties_version === tylaCode}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                    <label
                                      className="form-check-label margin-l-2 table-text"
                                      htmlFor={tylaCode}
                                    >
                                      {version}
                                    </label>
                                  </div>
                                </td>
                                <td className="table-text">{cc}</td>
                                <td className="table-text">{hp}</td>
                                <td className="table-text">{doors}</td>
                                <td className="table-text">{seats}</td>
                                <td className="table-text">
                                  {formatPriceInEuros(price)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* version select (Small Screen) */}
                    <div
                      className={` col-12 form-group ${getDisplayClass(
                        'block',
                        'mobile'
                      )} p-0`}
                    >
                      <label>Versão</label>
                      <select
                        disabled={!version_list.length > 0}
                        className={
                          properties_version === '' ||
                          properties_version === undefined ||
                          properties_version === null
                            ? 'form-select'
                            : 'form-select selected'
                        }
                        name="properties_version"
                        readOnly
                        onClick={() => {
                          if (version_list.length > 0)
                            setIsVersionListOpen(true);
                        }}
                        onMouseDown={() => {
                          if (version_list.length > 0)
                            setIsVersionListOpen(true);
                        }}
                      >
                        <option value="">
                          {getSelectedVersionValue(version_list)}
                        </option>
                      </select>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <RadioInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'is_imported'
                        )}
                        optionData="data"
                        optionLabel="label"
                        value={is_imported}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <RadioInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'has_trailer'
                        )}
                        optionData="data"
                        optionLabel="label"
                        value={has_trailer}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                  </div>
                  <div className="d-none d-md-block form-separator"></div>

                  <div
                    className="simulator-text"
                    style={{ color: secondaryColor }}
                  >
                    Dados do Condutor
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_name'
                        )}
                        placeholder="Inserir..."
                        value={driver_name}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                      {usertype !== 'lead' && (
                        <div className="driver-name-disclaimer-text">
                          <span>
                            {
                              textRefobject[firstStepSpecs.disclaimer.title][
                                defaultLanguage
                              ]
                            }
                            :{' '}
                          </span>
                          {
                            textRefobject[firstStepSpecs.disclaimer.text][
                              defaultLanguage
                            ]
                          }
                          {/* preencha o nome <b>completo</b> para que a Habit
                            possa apresentar <b>descontos de até 50%</b> */}
                        </div>
                      )}
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <NumberInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_nif'
                        )}
                        placeholder="Inserir..."
                        value={driver_nif}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <DatePicker
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_birthdate'
                        )}
                        dateFormat="dd-MM-y"
                        value={driver_birthdate}
                        dateSetterFunction={setDriverBirthdate}
                        error={error}
                        setError={setError}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <RadioInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_gender'
                        )}
                        optionData="data"
                        optionLabel="label"
                        value={driver_gender}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <DatePicker
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_license_month_year'
                        )}
                        dateFormat="MM-y"
                        value={driver_license_date}
                        dateSetterFunction={setDriverLicenseDate}
                        error={error}
                        setError={setError}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <InputMask
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_postal_code'
                        )}
                        mask="9999-999"
                        placeholder="0000-000"
                        value={driver_postal_code}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => {
                          if (
                            !e.target.value.includes('_') &&
                            e.target.value !== ''
                          )
                            isPostalCodeValid(
                              e.target.value,
                              'driver_postal_code'
                            );
                        }}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_email'
                        )}
                        placeholder="Inserir..."
                        value={driver_email}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>

                    {usertype === 'lead' && (
                      <>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 form-group">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="data_sharing_confirmation"
                              className={`form-check-input ${
                                error?.data_sharing_confirmation &&
                                error.data_sharing_confirmation.length > 0 &&
                                'invalid'
                              }`}
                              name="data_sharing_confirmation"
                              checked={
                                data_sharing_confirmation === true
                                  ? 'checked'
                                  : ''
                              }
                              onChange={(e) => handleInputChange(e)}
                            />
                            <label className="form-check-label">
                              Confirmo que a entidade final autoriza a partilha
                              dos dados com a Habit para fins de apresentação de
                              uma proposta de seguro.
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 form-group">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="not_seller_confirmation"
                              className={`form-check-input ${
                                error?.not_seller_confirmation &&
                                error.not_seller_confirmation.length > 0 &&
                                'invalid'
                              }`}
                              name="not_seller_confirmation"
                              checked={
                                not_seller_confirmation === true
                                  ? 'checked'
                                  : ''
                              }
                              onChange={(e) => handleInputChange(e)}
                            />
                            <label className="form-check-label d-block">
                              Tomei conhecimento que estou numa posição de
                              afiliado do distribuidor para a recomendação de
                              clientes e que, não sendo mediador de seguros, não
                              posso fazer qualquer aconselhamento sobre o
                              produto de seguro, as suas características,
                              coberturas ou prémios.
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 form-group">
                          <label className="lead-inputs">
                            Como pretende o cliente receber a simulação do
                            seguro?
                          </label>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 form-group">
                          <TextInput
                            inputSpecs={{
                              namespace: 'client_email',
                              label: 'Email',
                            }}
                            placeholder="Inserir..."
                            value={client_email}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 form-group">
                          <PhoneInput
                            inputSpecs={{
                              namespace: 'client_mobile',
                              label: 'SMS',
                            }}
                            value={client_mobile}
                            onChange={setClientMobile}
                            error={error}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`col-12 form-group ${getDisplayClass(
                      'flex',
                      'pc'
                    )} flex-column submit-button-container`}
                  >
                    {
                      <>
                        <button
                          type="submit"
                          className={`btn not-lead mt-2 ${
                            emptyMandatoryFieldsStepOne.length > 0 &&
                            'grey-button'
                          }`}
                          style={{ width: '200px' }}
                        >
                          {usertype === 'lead'
                            ? 'Enviar Simulação'
                            : textRefobject[firstStepSpecs.buttons[0].label][
                                defaultLanguage
                              ]}
                        </button>
                      </>
                    }
                  </div>
                </form>
              </div>
              {usertype !== 'lead' && (
                <>
                  <div
                    className={`box-wrapper mb-3 ${getDisplayClass(
                      'block',
                      'pc'
                    )}`}
                  >
                    <div className="box-wrapper-text d-flex align-items-center">
                      <span className="me-3">2</span>
                      <p className="m-0">
                        {textRefobject[secondStepSpecs.title][defaultLanguage]}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`box-wrapper mb-3 ${getDisplayClass(
                      'block',
                      'pc'
                    )}`}
                  >
                    <div className="box-wrapper-text d-flex align-items-center">
                      <span className="me-3">3</span>
                      <p className="m-0">Dados do Beneficiário</p>
                    </div>
                  </div>
                  <div
                    className={`box-wrapper ${getDisplayClass('block', 'pc')}`}
                  >
                    <div className="box-wrapper-text d-flex align-items-center">
                      <span className="me-3">4</span>
                      <p className="m-0">
                        {textRefobject[fourthStepSpecs.title][defaultLanguage]}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="step-two">
              <div className="mobile-top-bar">
                <h3 className="top-bar-heading">Resultado de Simulação</h3>
                <ul className="nav">
                  <li className="nav-item active-past">
                    <span></span>
                  </li>
                  <li className="nav-item active active_current">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                </ul>
              </div>
              <div
                className={`box-wrapper mb-3 ${getDisplayClass('block', 'pc')}`}
              >
                <div className="box-wrapper-text active d-flex align-items-center">
                  <i
                    className="fas fa-check-circle me-3"
                    style={{ color: secondaryColor }}
                  ></i>
                  <p className="m-0 color-blue">
                    {textRefobject[stepOneTitleTag][defaultLanguage]}
                  </p>
                  <button
                    type="click"
                    onClick={() => {
                      setStep(1);
                    }}
                    className="ms-auto m-0 editor-btn text-decoration-none "
                  >
                    <i className="fas fa-pen"></i>{' '}
                    {textRefobject[editButtonLabel][defaultLanguage]}
                  </button>
                </div>
              </div>
              <div
                className={`mobile-price-bar ${getDisplayClass(
                  'block',
                  'mobile'
                )}`}
              >
                <div className="d-flex" value="">
                  <div className="mobile-price-heading col-auto">
                    Valor a Pagar
                  </div>
                  <TooltipButton
                    className="mobile-price-heading col-1 ms-2 me-auto"
                    dropdownText={tooltipTexts.simulationInfo}
                  />
                  <StyledSelect
                    optionsArray={simulated_payment_frequency}
                    value={payment_frequency}
                    setValue={setPaymentFrequency}
                    screenCut={screenCut}
                  />
                </div>
              </div>
              <div className="d-block d-md-none">
                <RegistrationDiscount
                  registrationsHook={registrationsHook}
                  isRefreshing={isTableRefreshing}
                  refreshQuoteProperties={updateOldRegistrations}
                />
              </div>
              <span className={getDisplayClass('block', 'mobile')}>
                {quoteSpecs === null ? null : (
                  <ButtonCaroussel
                    productsArray={chosen_products}
                    paymentFrequency={payment_frequency}
                    selectedProduct={chosen_product}
                    setSelectedProduct={setChosenProduct}
                    productsCheckedWithUI={productsCheckedWithUI}
                    simulatedQuoteProperties={
                      quoteSpecs.simulated_quote_properties
                    }
                    setTotalAmount={setTotalAmount}
                    refreshing={isTableRefreshing}
                    refreshRate={async () =>
                      await updatePropertiesSecondStep(false)
                    }
                  />
                )}
              </span>

              <div className="text-wrapper main-content mb-3">
                <div
                  className={`left-content-heading d-flex align-items-center`}
                  style={{ color: secondaryColor }}
                >
                  <span className="me-3">2</span>
                  {textRefobject[secondStepSpecs.title][defaultLanguage]}
                </div>
                <form method="post" onSubmit={handleSubmitSecondStep} action="">
                  <div className="d-none d-md-block">
                    <RegistrationDiscount
                      registrationsHook={registrationsHook}
                      isRefreshing={isTableRefreshing}
                      refreshQuoteProperties={updateOldRegistrations}
                    />
                  </div>
                  <div
                    className={`col-12 col-md-6 form-group mt-3  ${getDisplayClass(
                      'block',
                      'pc'
                    )}`}
                  >
                    <RadioInput
                      inputSpecs={makeProperSpecsForUser(planlabel)}
                      optionData="data"
                      optionLabel="label"
                      value={payment_frequency}
                      onChange={(e) => handleInputChange(e)}
                      error={error}
                    />
                  </div>
                  <div className="d-none d-md-block form-separator m-b-l"></div>
                  {quoteSpecs ? (
                    <CoverageTable
                      paymentFrequency={payment_frequency}
                      coveragesByProductArray={coveragesByProductArray}
                      simulatedQuoteProperties={
                        quoteSpecs.simulated_quote_properties
                      }
                      chosenProduct={chosen_product}
                      setChosenProduct={setChosenProduct}
                      setTotalAmount={setTotalAmount}
                      UICoveragesByQuote={UICoveragesByQuote}
                      setUICoveragesByQuote={setUICoveragesByQuote}
                      refreshing={isTableRefreshing}
                      refreshRate={async () =>
                        await updatePropertiesSecondStep(false)
                      }
                      productsCheckedWithUI={productsCheckedWithUI}
                    />
                  ) : null}
                  <div className="d-none d-md-block form-separator m-t-xl m-b-xl-l"></div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group mt-4">
                    <DatePicker
                      inputSpecs={getSpecsByNamespace(
                        'quoteproperty_specs',
                        'policy_start_date'
                      )}
                      dateFormat="dd-MM-y"
                      value={policy_start_date}
                      dateSetterFunction={setPolicyStartDate}
                      error={error}
                      setError={setError}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <OpenEmailFormButton
                      openModal={async () => {
                        await updatePropertiesSecondStep(true);
                        setIsEmailModalOpen(true);
                      }}
                    />
                  </div>
                  <div
                    className={`col-12 form-group ${getDisplayClass(
                      'flex',
                      'pc'
                    )} flex-column m-t-xxl`}
                  >
                    <div className="d-flex flex-row flex-wrap justify-content-start">
                      {productsCheckedWithUI[chosen_product] ? (
                        <button
                          type="submit"
                          onClick={
                            emptyMandatoryFieldsStepTwo.length > 0
                              ? () => updatePropertiesSecondStep(false)
                              : (e) => handleSubmitSecondStep(e)
                          }
                          disabled={isTableRefreshing}
                          className={`btn m-e-s m-b-s ${
                            emptyMandatoryFieldsStepTwo.length > 0
                              ? 'grey-button'
                              : ''
                          }`}
                          style={{
                            background:
                              emptyMandatoryFieldsStepTwo.length > 0
                                ? 'var(--grey400)'
                                : primaryColor,
                            width: '200px',
                          }}
                        >
                          {
                            textRefobject[secondStepSpecs.buttons[0].label][
                              defaultLanguage
                            ]
                          }
                        </button>
                      ) : (
                        <RefreshRateButton
                          customClassName={
                            'align-self-start me-3 mb-3 ' +
                            (isTableRefreshing ? '' : 'border border-5')
                          }
                          activeTextColor={'#000'}
                          refreshRate={async () =>
                            await updatePropertiesSecondStep(false)
                          }
                          refreshing={isTableRefreshing}
                        />
                      )}{' '}
                      <OpenEmailFormButton
                        openModal={async () => {
                          await updatePropertiesSecondStep(true);
                          setIsEmailModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div
                className={`box-wrapper mb-3 ${getDisplayClass('block', 'pc')}`}
              >
                <div className="box-wrapper-text d-flex align-items-center">
                  <span className="me-3">3</span>
                  <p className="m-0">
                    {textRefobject[thirdStepSpecs.title][defaultLanguage]}
                  </p>
                </div>
              </div>
              <div className={`box-wrapper ${getDisplayClass('block', 'pc')}`}>
                <div className="box-wrapper-text d-flex align-items-center">
                  <span className="me-3">4</span>
                  <p className="m-0">
                    {textRefobject[fourthStepSpecs.title][defaultLanguage]}
                  </p>
                </div>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="step-three">
              <div className="mobile-top-bar">
                <h3 className="top-bar-heading">Dados do Tomador</h3>
                <ul className="nav">
                  <li className="nav-item active-past">
                    <span></span>
                  </li>
                  <li className="nav-item active-past">
                    <span></span>
                  </li>
                  <li className="nav-item active active_current">
                    <span></span>
                  </li>
                  <li className="nav-item">
                    <span></span>
                  </li>
                </ul>
                <label className="d-block text-center">
                  Preencha os dados que estão em falta.
                </label>
              </div>
              <div
                className={`box-wrapper mb-3 ${getDisplayClass('block', 'pc')}`}
              >
                <div className="box-wrapper-text active d-flex align-items-center">
                  <i
                    className="fas fa-check-circle me-3"
                    style={{ color: secondaryColor }}
                  ></i>
                  <p
                    className="m-0 color-blue"
                    style={{ color: secondaryColor }}
                  >
                    {textRefobject[stepOneTitleTag][defaultLanguage]}
                  </p>
                  <button
                    type="click"
                    onClick={() => {
                      setStep(1);
                    }}
                    className="ms-auto m-0 editor-btn text-decoration-none "
                  >
                    <i className="fas fa-pen"></i>{' '}
                    {textRefobject[editButtonLabel][defaultLanguage]}
                  </button>
                </div>
              </div>
              <div
                className={`box-wrapper mb-3 ${getDisplayClass('block', 'pc')}`}
              >
                <div className="box-wrapper-text active d-flex align-items-center">
                  <i
                    className="fas fa-check-circle me-3"
                    style={{ color: secondaryColor }}
                  ></i>
                  <p className="m-0" style={{ color: secondaryColor }}>
                    {textRefobject[secondStepSpecs.title][defaultLanguage]}
                  </p>
                  <button
                    type="click"
                    onClick={() => {
                      setStep(2);
                    }}
                    className="ms-auto m-0 editor-btn text-decoration-none "
                  >
                    <i className="fas fa-pen"></i>{' '}
                    {textRefobject[editButtonLabel][defaultLanguage]}
                  </button>
                </div>
              </div>
              <div className="text-wrapper main-content mb-3">
                <div
                  className="left-content-heading d-flex align-items-center"
                  style={{ color: secondaryColor }}
                >
                  <span className="me-3" style={{ color: secondaryColor }}>
                    3
                  </span>
                  {textRefobject[thirdStepSpecs.title][defaultLanguage]}
                </div>
                <form method="post" onSubmit={handleSubmitThirdStep} action="">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group m-0 mt-3">
                    <RadioInput
                      inputSpecs={getSpecsByNamespace(
                        'policyholderproperty_specs',
                        'insuree_is_driver'
                      )}
                      optionData="data"
                      optionLabel="label"
                      value={insuree_is_driver}
                      onChange={(e) => handleInputChange(e)}
                      error={error}
                    />
                  </div>
                  {showVehicleFormOnStepThree && (
                    <div className="row">
                      <div className="mt-0 simulator-text mb-3 color-blue">
                        Dados do Veículo
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <InputMask
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'registration'
                          )}
                          mask="**-**-**"
                          placeholder="XX-XX-XX"
                          value={registration}
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <label htmlFor="month-and-year">
                          Ano e mês de construção
                        </label>
                        <input
                          type="text"
                          id="month-and-year"
                          className="form-control selected"
                          disabled
                          value={`${manufacturer_year}-${manufacturer_month}`}
                        />
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <TextInput
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'brand'
                          )}
                          isDisabled={true}
                          placeholder="Inserir..."
                          value={
                            brand_list.find(({ id }) => id === brand)?.brand
                          }
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                      <div className="col-12 col-md-6 form-group">
                        <TextInput
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'model'
                          )}
                          isDisabled={true}
                          placeholder="Inserir..."
                          value={
                            model_list.find(({ id }) => id === model)?.model
                          }
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <TextInput
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'version'
                          )}
                          isDisabled={true}
                          placeholder="Inserir..."
                          value={
                            version_list.find(
                              ({ tylaCode }) => tylaCode === properties_version
                            )?.version
                          }
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                    </div>
                  )}
                  {insuree_is_driver === 'nao' && (
                    <>
                      <div className="row">
                        <div className="simulator-text color-blue">
                          Dados Pessoais do Tomador
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <TextInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'name'
                            )}
                            placeholder="Inserir..."
                            value={name}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <NumberInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'nif'
                            )}
                            placeholder="Inserir..."
                            value={nif}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <DatePicker
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'birthdate'
                            )}
                            dateFormat="dd-MM-y"
                            value={birthdate}
                            dateSetterFunction={setBirthdate}
                            error={error}
                            setError={setError}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <SelectInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'marital_state'
                            )}
                            value={marital_state}
                            placeholder="Selecionar..."
                            optionData="data"
                            optionLabel="label"
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group Sim">
                          <RadioInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'gender'
                            )}
                            optionData="data"
                            optionLabel="label"
                            value={gender}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>

                        <div className="simulator-text m-t-md-s">Contactos</div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <TextInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'email'
                            )}
                            placeholder="Inserir..."
                            value={email}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <TextInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'phone'
                            )}
                            placeholder="Ex: 211001010"
                            value={phone}
                            isoptional={true}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <PhoneInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'mobile'
                            )}
                            value={mobile}
                            onChange={(newValue) => {
                              setMobile(newValue);
                              if (usertype === 'seller') {
                                setIsButtonSendSMS(true);
                                setShowSMSField(false);
                              }
                            }}
                            error={error}
                          />
                        </div>

                        <div className="simulator-text m-t-md-s">Morada</div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <TextInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'address'
                            )}
                            placeholder="Inserir..."
                            value={address}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <InputMask
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'postal_code'
                            )}
                            mask="9999-999"
                            placeholder="0000-000"
                            value={postal_code}
                            onChange={(e) => handleInputChange(e)}
                            onBlur={(e) => {
                              if (
                                !e.target.value.includes('_') &&
                                e.target.value !== ''
                              )
                                isPostalCodeValid(
                                  e.target.value,
                                  'postal_code'
                                );
                            }}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                          <TextInput
                            inputSpecs={getSpecsByNamespace(
                              'policyholderproperty_specs',
                              'city'
                            )}
                            placeholder="Inserir..."
                            value={city}
                            onChange={(e) => handleInputChange(e)}
                            error={error}
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"></div>
                      </div>
                    </>
                  )}
                  {insuree_is_driver === 'nao' && (
                    <div className="form-separator m-t-l"></div>
                  )}
                  <div className="row">
                    <div
                      className={`simulator-text color-blue ${
                        insuree_is_driver === 'nao' ? 'm-t-l' : ''
                      }`}
                    >
                      Dados Pessoais do Condutor
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_name'
                        )}
                        placeholder="Inserir..."
                        value={driver_name}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                      {
                        <div className="driver-name-disclaimer-text">
                          <span>
                            {
                              textRefobject[firstStepSpecs.disclaimer.title][
                                defaultLanguage
                              ]
                            }
                            :{' '}
                          </span>
                          {
                            textRefobject[firstStepSpecs.disclaimer.text][
                              defaultLanguage
                            ]
                          }
                        </div>
                      }
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <NumberInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_nif'
                        )}
                        placeholder="Inserir..."
                        value={driver_nif}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <DatePicker
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_birthdate'
                        )}
                        dateFormat="dd-MM-y"
                        value={driver_birthdate}
                        dateSetterFunction={setDriverBirthdate}
                        error={error}
                        setError={setError}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <SelectInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_marital_state'
                        )}
                        value={driver_marital_state}
                        placeholder="Selecionar..."
                        optionData="data"
                        optionLabel="label"
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <DatePicker
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_license_date'
                        )}
                        dateFormat="MM-y"
                        value={driver_license_date}
                        dateSetterFunction={setDriverLicenseDate}
                        error={error}
                        setError={setError}
                      />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_license_number'
                        )}
                        placeholder="Ex: L-12345678 0"
                        value={driver_license_number}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="simulator-text m-t-md-s">Contactos</div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_email'
                        )}
                        placeholder="Inserir..."
                        value={driver_email}
                        readOnly={true}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_phone'
                        )}
                        placeholder="Ex: 211001010"
                        value={driver_phone}
                        isoptional={true}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <PhoneInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_mobile'
                        )}
                        value={driver_mobile}
                        onChange={(newValue) => {
                          setDriverMobile(newValue);
                          if (usertype === 'seller') {
                            setIsButtonSendSMS(true);
                            setShowSMSField(false);
                          }
                        }}
                        error={error}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="simulator-text m-t-md-s">Morada</div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_address'
                        )}
                        placeholder="Inserir..."
                        value={driver_address}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <InputMask
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_postal_code'
                        )}
                        mask="9999-999"
                        placeholder="0000-000"
                        value={driver_postal_code}
                        onChange={(e) => handleInputChange(e)}
                        readOnly={true}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                      <TextInput
                        inputSpecs={getSpecsByNamespace(
                          'protectedassetproperty_specs',
                          'driver_city'
                        )}
                        placeholder="Inserir..."
                        value={driver_city}
                        onChange={(e) => handleInputChange(e)}
                        error={error}
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12"></div>
                    <div className="simulator-text m-t-md-s">
                      Entidade Financeira
                    </div>

                    <RadioInput
                      inputSpecs={getSpecsByNamespace(
                        'protectedassetproperty_specs',
                        'has_financial_entity'
                      )}
                      optionData="data"
                      optionLabel="label"
                      value={hasFinancialEntity}
                      onChange={(e) => setHasFinancialEntity(e.target.value)}
                      error={error}
                    />
                    {hasFinancialEntity === '1' && (
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 form-group">
                        <SelectWithAutoComplete
                          inputSpecs={getSpecsByNamespace(
                            'protectedassetproperty_specs',
                            'financial_entity'
                          )}
                          options={financialEntities}
                          setValue={setFinancialEntity}
                          value={financialEntity}
                          error={error}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-12 form-group m-t-xl">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="receive_email_marketing"
                        className="form-check-input"
                        name="receive_email_marketing"
                        checked={
                          receive_email_marketing === true ? 'checked' : ''
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label className="form-check-label">
                        {
                          textRefobject[thirdStepSpecs.checkboxes[0].text][
                            defaultLanguage
                          ]
                        }
                      </label>
                    </div>
                  </div>
                  <div className="col-12 form-group m-b-xl">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="privacy_policy_consent"
                        className={`form-check-input ${
                          privacy_policy_consent === false &&
                          error.name === '' &&
                          error.value !== ''
                            ? 'invalid'
                            : ''
                        }`}
                        name="privacy_policy_consent"
                        checked={
                          privacy_policy_consent === true ? 'checked' : ''
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label className="form-check-label d-block">
                        {
                          textRefobject[thirdStepSpecs.checkboxes[1].text][
                            defaultLanguage
                          ]
                        }{' '}
                        <Link target="_blank" to={privacy_policy_url}>
                          {privacy_policy_name}
                        </Link>{' '}
                      </label>
                    </div>
                  </div>
                  {showSMSField === true && (
                    <div className="field-item">
                      <div className="custom-field">
                        <TextInput
                          inputSpecs={{
                            ...getSpecsByNamespace(
                              'quoteproperty_specs',
                              'digital_signature_received'
                            ),
                            ...{
                              label: 'SMS',
                            },
                          }}
                          placeholder="Inserir..."
                          value={digital_signature_received}
                          onChange={(e) => handleInputChange(e)}
                          error={error}
                        />
                      </div>
                      <div className="custom-field">
                        <button
                          type="button"
                          onClick={sendSmsCode}
                          className="resend-btn"
                        >
                          Enviar novo código por SMS
                        </button>
                      </div>
                    </div>
                  )}
                  <span className="d-flex flex-column">
                    <SubmitButton
                      isDisabled={isThirdStepButtonDisabled}
                      customStyle={{ width: '200px' }}
                      customClassName={'d-none d-md-block'}
                      text={
                        isButtonSendSMS
                          ? `${
                              textRefobject[thirdStepSpecs.buttons[0].label][
                                defaultLanguage
                              ]
                            }`
                          : `${
                              textRefobject[thirdStepSpecs.buttons[1].label][
                                defaultLanguage
                              ]
                            }`
                      }
                    />
                  </span>
                </form>
              </div>
              <div className={`box-wrapper ${getDisplayClass('block', 'pc')}`}>
                <div className="box-wrapper-text d-flex align-items-center">
                  <span className="me-3">4</span>
                  <p className="m-0">
                    {textRefobject[fourthStepSpecs.title][defaultLanguage]}
                  </p>
                </div>
              </div>
            </div>
          )}

          {step === 4 && (
            <>
              <div
                className={`payment-header d-flex align-items-center ${getDisplayClass(
                  'block',
                  'mobile'
                )}`}
              >
                <img
                  src={require('../../assets/frontend/images/payment-gateway-icon.png')}
                  alt=""
                  width="30px"
                />
                <span className="payment-gateway-text">habit</span>
              </div>
              <div className="step-four">
                <div
                  className={`payment-header-heading ${getDisplayClass(
                    'block',
                    'mobile'
                  )}`}
                >
                  {
                    textRefobject[fourthStepSpecs.fields[0].label][
                      defaultLanguage
                    ]
                  }
                </div>
                <div
                  className={`box-wrapper mb-3 ${getDisplayClass(
                    'block',
                    'pc'
                  )}`}
                >
                  <div className="box-wrapper-text active d-flex align-items-center">
                    <i className="fas fa-check-circle me-3"></i>
                    <p className="m-0 color-blue">
                      {textRefobject[stepOneTitleTag][defaultLanguage]}
                    </p>
                    {blockEdit ? null : (
                      <button
                        type="click"
                        onClick={() => {
                          setStep(1);
                        }}
                        className="ms-auto m-0 editor-btn text-decoration-none "
                      >
                        <i className="fas fa-pen"></i>{' '}
                        {textRefobject[editButtonLabel][defaultLanguage]}
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={`box-wrapper mb-3 ${getDisplayClass(
                    'block',
                    'pc'
                  )}`}
                >
                  <div className="box-wrapper-text active d-flex align-items-center">
                    <i className="fas fa-check-circle me-3"></i>
                    <p className="m-0 color-blue">
                      {' '}
                      {textRefobject[secondStepSpecs.title][defaultLanguage]}
                    </p>
                    {blockEdit ? null : (
                      <button
                        type="click"
                        onClick={() => {
                          setStep(2);
                        }}
                        className="ms-auto m-0 editor-btn text-decoration-none "
                      >
                        <i className="fas fa-pen"></i>{' '}
                        {textRefobject[editButtonLabel][defaultLanguage]}
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={`box-wrapper mb-3 ${getDisplayClass(
                    'block',
                    'pc'
                  )}`}
                >
                  <div className="box-wrapper-text active d-flex align-items-center">
                    <i className="fas fa-check-circle me-3"></i>
                    <p className="m-0 color-blue">
                      {textRefobject[thirdStepSpecs.title][defaultLanguage]}
                    </p>
                    {blockEdit ? null : (
                      <button
                        type="click"
                        onClick={() => {
                          if (usertype === 'seller') setIsButtonSendSMS(true);
                          setStep(3);
                        }}
                        className="ms-auto m-0 editor-btn text-decoration-none "
                      >
                        <i className="fas fa-pen"></i>{' '}
                        {textRefobject[editButtonLabel][defaultLanguage]}
                      </button>
                    )}
                  </div>
                </div>

                <div className="text-wrapper main-content">
                  <div className="left-content-heading d-flex align-items-center">
                    <span className="me-3">4</span>
                    {textRefobject[fourthStepSpecs.title][defaultLanguage]}
                  </div>
                  <SmartlinkComponent
                    hash={paymentHash}
                    prePaymentMethod={prePaymentMethod}
                    onPaymentSuccess={onPaymentSuccess}
                  />
                </div>
              </div>
            </>
          )}
          <section className={`footer ${getDisplayClass('block', 'pc')}`}>
            {/* {globalDisclaimer ? (
            <p className="m-0">{globalDisclaimer.text}</p>
          ) : (
            ''
          )} */}
            {productDisclaimer ? (
              <p className="m-0">{productDisclaimer.text}</p>
            ) : (
              ''
            )}
          </section>
        </div>
        <div className={`${getDisplayClass('block', 'pc')} right-content`}>
          <div className="right-content-container">
            <div className="row px-0">
              <div className="right-content-heading col-auto">
                {
                  textRefobject[theme.form_sections.sidebar_section.title][
                    defaultLanguage
                  ]
                }
              </div>
              <TooltipButton
                className="right-content-heading col-auto"
                dropdownText={tooltipTexts.simulationInfo}
              />
            </div>
            {usertype !== 'lead' ? (
              <>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      textRefobject[
                        theme.form_sections.sidebar_section.total_amount_label
                      ][defaultLanguage]
                    }
                    disabled
                  />
                  <span
                    className={
                      total_amount === '0.00'
                        ? 'input-group-text grayinput'
                        : 'input-group-text'
                    }
                  >
                    {productsCheckedWithUI[chosen_product]
                      ? `${total_amount} €`
                      : ''}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}

            <label>Produto</label>
            <h5 style={{ color: secondaryColor }}>
              {
                theme.bio_sections.texts[theme.bio_sections.bio_header.title][
                  defaultLanguage
                ]
              }
            </h5>
            {usertype !== 'lead' ? (
              <>
                <label>Documentos</label>
                <ul className="nav mb-4 d-flex flex-column">
                  {documents.map(function (value, index) {
                    var uri = value.uri;
                    return (
                      <li key={index}>
                        <a href={uri} target="_blank">
                          <i className="me-1"></i> {value.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              ''
            )}
            <h4 style={{ color: secondaryColor }}>Habit Seguros</h4>
          </div>
        </div>

        {/* mobile */}
        {step !== 'versionList' && (
          <div
            className={`right-content ${getDisplayClass('block', 'mobile')}`}
          >
            <div className="right-content-heading">Informação Legal</div>
            <label className="p-1">
              Esta informação não dispensa a consulta da informação
              pré-contratual e contratual legalmente exigida:
            </label>
            {usertype !== 'lead' ? (
              <>
                <label>Documentos</label>
                <ul className="nav mb-4 d-flex flex-column">
                  {documents.map(function (value, index) {
                    var uri = value.uri;
                    return (
                      <li key={index}>
                        <a href={uri} target="_blank">
                          {value.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              ''
            )}
            <h4>Habit Seguros</h4>
          </div>
        )}
        {step !== 'versionList' && (
          <section
            className={`pt-0 px-3 footer ${
              step === 3 ? ' padding-bottom-150' : ''
            } ${getDisplayClass('block', 'mobile')}`}
          >
            {/* {globalDisclaimer ? (
              <p className="m-0">{globalDisclaimer.text}</p>
            ) : (
              ''
            )} */}
            {productDisclaimer ? (
              <p className="m-0 mb-3">{productDisclaimer.text}</p>
            ) : (
              ''
            )}
            {step === 1 && (
              <div
                className="mobile-steps"
                style={{ backgroundColor: '#F3F4F5' }}
                ref={mobileButtonRef}
              >
                {getItemLocalStorage(AUTOKEY_TYPE) !== 'lead' && (
                  <div
                    className={`form-group py-1 col-12 submit-button-padding justify-content-center align-self-end my-2 ${getDisplayClass(
                      'flex',
                      'mobile'
                    )}`}
                  >
                    {
                      <>
                        <button
                          onClick={handleSubmitFirstStep}
                          className={`btn py-2 col-12 ${
                            emptyMandatoryFieldsStepOne.length > 0 &&
                            'grey-button'
                          }`}
                        >
                          {
                            textRefobject[firstStepSpecs.buttons[0].label][
                              defaultLanguage
                            ]
                          }
                        </button>
                      </>
                    }
                  </div>
                )}

                {getItemLocalStorage(AUTOKEY_TYPE) === 'lead' && (
                  <div
                    className={`form-group py-2 col-12 submit-button-padding justify-content-center align-self-end ${getDisplayClass(
                      'flex',
                      'mobile'
                    )}`}
                  >
                    {type === '' ||
                    manufacturer_month === '' ||
                    manufacturer_year === '' ||
                    brand === '' ||
                    model === '' ||
                    properties_version === '' ||
                    is_imported === '' ||
                    has_trailer === '' ||
                    driver_nif === '' ||
                    driver_birthdate === '' ||
                    driver_gender === '' ||
                    driver_license_date === '' ||
                    driver_postal_code === '' ||
                    driver_email === '' ||
                    client_email === '' ||
                    client_mobile === '' ||
                    privacy_policy_consent === false ? (
                      <button type="button" className="btn col-12 grey-button">
                        ENVIAR SIMULAÇÃO
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="first_step"
                        onClick={handleSubmitFirstStep}
                        className="btn col-12"
                        style={{ background: primaryColor }}
                      >
                        ENVIAR SIMULAÇÃO
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}

            {step === 2 && (
              <div
                className="mobile-steps"
                style={{ backgroundColor: '#F3F4F5' }}
                ref={mobileButtonRef}
              >
                <div
                  className={`form-group col-12 row justify-content-between submit-button-padding align-self-end mt-1 ${getDisplayClass(
                    'flex',
                    'mobile'
                  )}`}
                >
                  {chosen_product === '' ||
                  payment_frequency === '' ||
                  policy_start_date === '' ||
                  !productsCheckedWithUI[chosen_product] ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setStep(1);
                        }}
                        className="btn col-4 white-button"
                      >
                        {textRefobject[editButtonLabel][defaultLanguage]}
                      </button>
                      <RefreshRateButton
                        customClassName="col-7"
                        activeTextColor={'#000'}
                        refreshing={isTableRefreshing}
                        refreshRate={async () =>
                          await updatePropertiesSecondStep(false)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setStep(1);
                        }}
                        className="btn col-4 white-button"
                      >
                        {textRefobject[editButtonLabel][defaultLanguage]}
                      </button>
                      <button
                        type="button"
                        className="btn col-7"
                        onClick={(e) => handleSubmitSecondStep(e)}
                        style={{ background: primaryColor }}
                      >
                        {
                          textRefobject[secondStepSpecs.buttons[0].label][
                            defaultLanguage
                          ]
                        }
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="mobile-steps bg-white" ref={mobileButtonRef}>
                <div className="mobile-price-bar d-flex justify-content-between submit-button-padding pt-3">
                  <div className="mobile-price-heading" value="">
                    Valor a Pagar
                  </div>
                  <div className="price-text">
                    <span className="price-num"> {total_amount} €</span>
                  </div>
                </div>
                {isButtonSendSMS === false && (
                  <div
                    className={`form-group py-2 px-1 col-12 row justify-content-between submit-button-padding align-self-end${getDisplayClass(
                      'block',
                      'mobile'
                    )}`}
                  >
                    {insuree_is_driver === '' ||
                    ((name === '' ||
                      birthdate === '' ||
                      nif === '' ||
                      marital_state === '' ||
                      gender === '' ||
                      email === '' ||
                      mobile === '' ||
                      address === '' ||
                      postal_code === '' ||
                      city === '') &&
                      insuree_is_driver === 'nao') ||
                    driver_name === '' ||
                    driver_nif === '' ||
                    driver_birthdate === '' ||
                    driver_marital_state === '' ||
                    driver_license_date === '' ||
                    driver_license_number === '' ||
                    driver_email === '' ||
                    driver_postal_code === '' ||
                    driver_city === '' ||
                    privacy_policy_consent === false ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            setStep(2);
                          }}
                          className="btn col-4 white-button"
                        >
                          Anterior
                        </button>
                        <button type="button" className="btn col-7 grey-button">
                          {
                            textRefobject[thirdStepSpecs.buttons[1].label][
                              defaultLanguage
                            ]
                          }
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            setStep(2);
                          }}
                          className="btn col-4 white-button"
                        >
                          Anterior
                        </button>
                        <button
                          type="button"
                          onClick={handleSubmitThirdStep}
                          className="btn col-7"
                          style={{ background: primaryColor }}
                        >
                          Continuar
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </section>
        )}
      </div>
    </Fragment>
  );
};

export default SimulationForm;
