const SubmitButton = ({ isDisabled, text, customClassName, customStyle }) => {
  const buttonStyle = {
    backgroundColor: isDisabled ? 'var(--grey300)' : 'var(--color-primary)',
    ...customStyle,
  };

  const className = customClassName ? customClassName : '';

  return (
    <button type="submit" style={buttonStyle} className={`btn ${className}`}>
      {text}
    </button>
  );
};

export default SubmitButton;
