import withFormLayout from '../HOC/withFormLayout';
import React from 'react';
import { useState } from 'react';

const SelectWithAutoComplete = ({
  // from HOC
  styles,
  inputRef,
  namespace,

  // mandatory on FE
  value,
  setValue,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [commandFilter, setCommandFilter] = useState('');

  const selectedOption = options.find((option) => option.data === value);
  return (
    <div className="d-flex flex-column position-relative">
      <div
        className={`select-trigger form-select ${styles} ${
          isOpen ? 'open' : ''
        } truncate-text`}
        ref={inputRef}
        name={namespace}
        id={namespace}
        onClick={() => setIsOpen(() => !isOpen)}
      >
        {selectedOption?.label || 'Selecionar...'}
      </div>
      {isOpen && (
        <div
          className={`d-flex flex-column select-dropdown ${
            isOpen ? 'open' : ''
          }`}
        >
          <input
            type="text"
            className="muted-input"
            value={commandFilter}
            placeholder="Procurar..."
            onChange={(e) => setCommandFilter(e.target.value)}
          />
          <div className="scrollable-area">
            {options
              .filter((option) =>
                option.label.toUpperCase().includes(commandFilter.toUpperCase())
              )
              .map((option) => (
                <option
                  key={option.data}
                  className={`select-option ${
                    option.data === value ? 'selected' : ''
                  }`}
                  onClick={() => {
                    setIsOpen(false);
                    setValue(option.data);
                  }}
                >
                  {option.label}
                </option>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default withFormLayout(SelectWithAutoComplete);
