import { Fragment, useState } from 'react';
import { useTheme } from '../../../../contexts/ThemeContext';
import { Modal } from 'react-bootstrap';

const TooltipButtonWithModal = ({ chosenProductCoverages, modalTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  const {
    '--color-secondary': openColor,
    '--color-radiobuttons-checkboxes': closedColor,
  } = theme.colors;

  const TooltipSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      onClick={() => setIsOpen(true)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49996 1.83317C4.37035 1.83317 1.83329 4.37023 1.83329 7.49984C1.83329 10.6295 4.37035 13.1665 7.49996 13.1665C10.6296 13.1665 13.1666 10.6295 13.1666 7.49984C13.1666 4.37022 10.6296 1.83317 7.49996 1.83317ZM0.416626 7.49984C0.416626 3.58782 3.58794 0.416504 7.49996 0.416504C11.412 0.416504 14.5833 3.58782 14.5833 7.49984C14.5833 11.4119 11.412 14.5832 7.49996 14.5832C3.58795 14.5832 0.416626 11.4119 0.416626 7.49984ZM6.79163 4.6665C6.79163 4.2753 7.10876 3.95817 7.49996 3.95817H7.50704C7.89824 3.95817 8.21538 4.2753 8.21538 4.6665C8.21538 5.05771 7.89824 5.37484 7.50704 5.37484H7.49996C7.10876 5.37484 6.79163 5.05771 6.79163 4.6665ZM6.08329 7.49984C6.08329 7.10863 6.40042 6.7915 6.79163 6.7915H7.49996C7.89116 6.7915 8.20829 7.10863 8.20829 7.49984V9.62484C8.59949 9.62484 8.91663 9.94197 8.91663 10.3332C8.91663 10.7244 8.59949 11.0415 8.20829 11.0415H7.49996C7.10876 11.0415 6.79163 10.7244 6.79163 10.3332V8.20817C6.40042 8.20817 6.08329 7.89104 6.08329 7.49984Z"
        fill={isOpen ? openColor : closedColor}
      />
    </svg>
  );

  if (
    chosenProductCoverages.filter(
      ({ description }) => description !== undefined && description !== ''
    ).length === 0
  )
    return <></>;

  return (
    <>
      <TooltipSVG />
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="coverage-modal-title">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {chosenProductCoverages
            .filter(
              (coverage) =>
                coverage.description !== undefined &&
                coverage.description !== ''
            )
            .map(({ name, description, sub_coverages: subCoverages }) => (
              <Fragment key={name}>
                <p className="coverage-modal-description">
                  <span>{name}</span>
                  <br />
                  {description}
                </p>
                {subCoverages?.map(({ name, description }) => (
                  <p key={name} className="ms-3 subcoverage-modal-description">
                    <span>{name}</span>
                    <br />
                    {description}
                  </p>
                ))}
              </Fragment>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TooltipButtonWithModal;
