import { Swiper, SwiperSlide } from 'swiper/react';
import './coverageTable.css';
import 'swiper/css';
import 'swiper/css/free-mode';

import MobileCoverageButton from './MobileCoverageButton';
import { isNullOrUndefined } from '../utils';

const ButtonCaroussel = ({
  productsArray,
  paymentFrequency,
  selectedProduct,
  setSelectedProduct,
  simulatedQuoteProperties,
  setTotalAmount,
  productsCheckedWithUI,
  refreshing,
  refreshRate,
}) => {
  const checkProps = (props) => {
    const propsArray = Object.values(props);
    const hasNullOrUndefined = propsArray.some((prop) =>
      isNullOrUndefined(prop)
    );
    return !hasNullOrUndefined;
  };
  if (
    !checkProps({
      productsArray,
      paymentFrequency,
      selectedProduct,
      setSelectedProduct,
      simulatedQuoteProperties,
      setTotalAmount,
    })
  )
    return null;

  const getProductInfo = (productData, selectedFrequency) => {
    const rateByProductObject = simulatedQuoteProperties.find(
      (property) => property.namespace === 'rate_by_product'
    );
    const paymentFrequencyObject = simulatedQuoteProperties.find(
      (property) => property.namespace === 'payment_frequency'
    );

    if (!checkProps({ rateByProductObject, paymentFrequencyObject }))
      return undefined;

    const productInfo = {};

    productInfo.key = productData;
    productInfo.name = productsArray.find(
      (matchProduct) => matchProduct.data === productData
    ).label;
    productInfo.frequency = paymentFrequencyObject.options.find(
      (frequency) => frequency.data === selectedFrequency
    ).label;

    const parsedRateByProduct = JSON.parse(
      rateByProductObject.data.replace(/\\/g, '')
    );
    productInfo.rate =
      parsedRateByProduct[productData][selectedFrequency].first_installment;
    productInfo.secondInstallment =
      parsedRateByProduct[productData][selectedFrequency].other_installments;

    return productInfo;
  };

  if (getProductInfo(selectedProduct, paymentFrequency) === undefined)
    return <></>;

  return (
    <div className="d-flex mb-3 ignore-padding-right">
      <Swiper slidesPerView={'auto'} spaceBetween={10} className="mySwiper">
        {productsArray.map((product) => (
          <SwiperSlide key={product.data}>
            <MobileCoverageButton
              isSelected={product.data === selectedProduct}
              hasProperRate={productsCheckedWithUI[product.data]}
              refreshing={refreshing}
              refreshRate={refreshRate}
              setSelectedProduct={setSelectedProduct}
              productInfo={getProductInfo(product.data, paymentFrequency)}
              setTotalAmount={setTotalAmount}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ButtonCaroussel;
