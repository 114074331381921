import { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { isNullOrUndefined } from '../Form/utils';

const StyledSelect = ({
  optionsArray,
  value,
  setValue,
  fontSize,
  screenCut,
  customClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const { theme } = useTheme();
  const {
    colors: { '--color-radiobuttons-checkboxes': UIColor },
  } = theme;
  const selectStyle = {
    color: UIColor,
    textAlign: 'right',
    fontFamily: 'Regular',
    fontSize: fontSize ? fontSize : '16px',
    fontStyle: 'normal',
    border: 'none',
    backgroundColor: 'transparent',
  };

  const checkProps = (props) => {
    const propsArray = Object.values(props);
    const hasNullOrUndefined = propsArray.some((prop) =>
      isNullOrUndefined(prop)
    );
    return !hasNullOrUndefined;
  };
  if (!checkProps({ optionsArray, value, setValue })) return null;

  const chosenOption = optionsArray.find((option) => option.data === value);
  if (isNullOrUndefined(chosenOption)) return null;

  const arrowDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
    >
      <path
        d="M4.51543 5L0.794898 0.499999L8.23596 0.5L4.51543 5Z"
        fill={UIColor}
      />
    </svg>
  );

  const arrowUp = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
    >
      <path
        d="M4.51543 0L0.794898 4.500001L8.23596 4.5L4.51543 0Z"
        fill={UIColor}
      />
    </svg>
  );

  return (
    <>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        ref={dropdownRef}
        className={`d-none d-${screenCut}-flex flex-column align-items-end ${customClass}`}
      >
        <span
          className="w-100 d-flex align-items-center"
          style={selectStyle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="m-0 me-1">{chosenOption.label}</p>
          {isOpen ? arrowUp : arrowDown}
        </span>
        <div
          className={`select-content-dropdown ${isOpen ? 'd-block' : 'd-none'}`}
        >
          {optionsArray.map((option) => (
            <option
              className={`select-content-option ${
                option.data === value ? 'selected' : ''
              }`}
              key={option.data}
              onClick={() => {
                setValue(option.data);
                setIsOpen(false);
              }}
              onTouchStart={() => {
                setValue(option.data);
                setIsOpen(false);
              }}
            >
              {option.label}
            </option>
          ))}
        </div>
      </div>

      <div
        className={`d-flex d-${screenCut}-none align-items-center ${customClass}`}
      >
        <select
          className="w-100 d-flex align-items-center select-content-header me-2"
          value={value}
          onMouseDown={() => {
            setIsOpen(true);
          }}
          onChange={(e) => {
            const foundOption = optionsArray.find(
              (option) => String(e.target.value) === String(option.data)
            );
            setValue(foundOption.data);
            setIsOpen(false);
          }}
          style={selectStyle}
        >
          {optionsArray.map((option) => (
            <option
              className={`select-content-option ${
                option.data === value ? 'selected' : ''
              }`}
              key={option.data}
              value={option.data}
            >
              {option.label}
            </option>
          ))}
        </select>
        {isOpen ? arrowUp : arrowDown}
      </div>
    </>
  );
};

export default StyledSelect;
