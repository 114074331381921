import Home from '../components/frontend/Home';
import Bio from '../components/frontend/Bio';
import Login from '../components/frontend/Login';
import SimulationForm from '../components/frontend/SimulationForm';
import SimulationSuccess from '../components/frontend/SimulationSuccess';
import SimulationError from '../components/frontend/SimulationError';
import PrivacyPolicy from '../components/frontend/PrivacyPolicy';

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/bio', exact: true, name: 'Bio', component: Bio },
  { path: '/login', exact: true, name: 'Login', component: Login },
  {
    path: '/simulation-form',
    exact: true,
    name: 'SimulationForm',
    component: SimulationForm,
  },
  {
    path: '/success',
    exact: true,
    name: 'SimulationSuccess',
    component: SimulationSuccess,
  },
  {
    path: '/error',
    exact: true,
    name: 'SimulationError',
    component: SimulationError,
  },
  {
    path: '/privacy-policy',
    exact: true,
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

export default routes;
