import { Modal } from 'react-bootstrap';
import { useTheme } from '../../../contexts/ThemeContext';
import { useEffect } from 'react';
import image from '../../../assets/frontend/images/registration_mismatch.png';
import { makeJSXFromTextWithLineBreaks } from '../../../utils/functions';

const RegistrationModal = ({ isOpen, setIsOpen, setStep }) => {
  const { theme } = useTheme();
  const {
    colors: { '--color-radiobuttons-checkboxes': UIColor },
  } = theme;

  const setCssVariables = (theme) => {
    const root = document.documentElement;
    for (const [key, value] of Object.entries(theme.colors)) {
      root.style.setProperty(key, value);
    }
    for (const [key, value] of Object.entries(theme.fonts)) {
      root.style.setProperty(key, value);
    }
    root.style.setProperty(
      '--border-radius-button',
      theme.border_radius.button
    );
  };

  const pencilSVG = (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88545 3.05214L10.9478 5.11453M9.76045 2.17714C10.33 1.60762 11.2533 1.60762 11.8228 2.17714C12.3923 2.74665 12.3923 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76045 2.17714Z"
        stroke={UIColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const texts = {
    title: 'Dados não coincidem!',
    description:
      'Os dados da viatura não coincidem com a matrícula.\nDeve fazer nova simulação.',
    buttonText: 'Voltar ao Início',
    editRegistration: 'Editar Matrícula',
  };

  useEffect(() => {
    setCssVariables(theme);
  }, [theme]);

  return (
    <Modal
      show={isOpen}
      backdrop="static"
      keyboard={false}
      animation={false}
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center registration-modal-container">
        <img
          src={image}
          alt="registration mismatch"
          className="img-registration"
        />
        <p className="registration-modal-text-title">{texts.title}</p>
        {makeJSXFromTextWithLineBreaks(
          texts.description,
          'registration-modal-text-description'
        )}
        <button
          className="registration-modal-button"
          onClick={() => {
            setStep(1);
            setIsOpen(false);
          }}
        >
          {texts.buttonText}
        </button>
        <div
          onClick={() => setIsOpen(false)}
          className="d-flex registration-modal-edit-registration"
        >
          {pencilSVG}
          <p className="registration-modal-edit-registration-text">
            {texts.editRegistration}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RegistrationModal;
