import InputMaskComponent from 'react-input-mask';
import withFormLayout from '../HOC/withFormLayout';

const InputMask = ({
  //mandatory
  value,
  mask,
  onChange,
  placeholder,
  //optional
  readOnly,
  onBlur,
  //comes from HOC
  namespace,
  styles,
}) => {
  return (
    <InputMaskComponent
      className={`form-control ${styles}`}
      mask={mask}
      placeholder={placeholder}
      name={namespace}
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default withFormLayout(InputMask);
