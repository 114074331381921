import React from 'react';
import { ReactComponent as BadgeCheck } from './SVGs/BadgeCheck.svg';
import { ReactComponent as InfoBadge } from './SVGs/InfoBadge.svg';
import { ReactComponent as ErrorCircle } from './SVGs/ErrorCircle.svg';
import { DISCOUNT_STATES as RESULTS } from '../../../utils/constants';

const ResultMessage = ({ result, discount }) => {
  const { SUCCESS, NOT_FOUND, BEST_OFFER } = RESULTS;
  switch (result) {
    case SUCCESS:
      return (
        <div className="result-message success">
          <BadgeCheck className="d-none d-md-block" />
          <p>
            {discount > 0 ? (
              <>
                <b>{`Baixou ${Math.round(discount)}% `}</b>no valor do prémio!
              </>
            ) : (
              `O seu histórico de matrículas permitiu baixar o valor do prémio!`
            )}
          </p>
        </div>
      );

    case NOT_FOUND:
      return (
        <div className="result-message error">
          <ErrorCircle />
          <p>
            Não foram encontrados dados referentes a esta matrícula.{' '}
            <b>Tente de novo!</b>
          </p>
        </div>
      );

    case BEST_OFFER:
      return (
        <div className="result-message info">
          <InfoBadge />
          <p>
            Esta é nossa melhor oferta, tendo em conta o seu histórico de
            seguros Auto!
          </p>
        </div>
      );
    default:
      return <h3>Inserir mais Matrículas</h3>;
  }
};

export default ResultMessage;
