import React from 'react';
import { ReactComponent as DiscountIcon } from './SVGs/Discount.svg';
import { useTheme } from '../../../contexts/ThemeContext';
import { lightenHexColor } from '../../../utils/functions';

const AskDiscountPanel = ({ createNewList }) => {
  const { theme } = useTheme();
  const primary = theme?.colors?.['--color-primary'] ?? '#fdbe14';
  const lightenedPrimary = lightenHexColor(primary, 0.89);

  return (
    <div className="ask-discount-banner">
      <div
        className="primary-background"
        style={{ backgroundColor: lightenedPrimary }}
      />
      <div className="icon-container p-0">
        <DiscountIcon className="discount-icon" />
      </div>
      <h1>
        <b>Baixe até 50% do prémio,</b> inserindo as suas matrículas antigas!
      </h1>
      <h2>
        Para que possamos validar a sua experiência de condução e dar-lhe o
        preço mais ajustado
      </h2>
      <div className="button-container p-0">
        <button className="btn btn-warning" onClick={createNewList}>
          + Matrículas
        </button>
      </div>
    </div>
  );
};

export default AskDiscountPanel;
