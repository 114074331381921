import ProductHeader from './ProductHeader';
import './coverageTable.css';
import CoverageCell from './CoverageCell';
import CoverageLabel from './CoverageLabel';
import { isNullOrUndefined } from '../utils';
import { useRef, useEffect, useState, Fragment } from 'react';
import TooltipButtonWithModal from './TooltipButtonWithModal';
import { useTheme } from '../../../../contexts/ThemeContext';
import { addOpacityToHexColor } from '../../../../utils/functions';

const CoverageTable = ({
  paymentFrequency,
  coveragesByProductArray,
  simulatedQuoteProperties,
  chosenProduct,
  setChosenProduct,
  setTotalAmount,
  UICoveragesByQuote,
  setUICoveragesByQuote,
  refreshing,
  refreshRate,
  productsCheckedWithUI,
}) => {
  const { theme } = useTheme();
  const headerRef = useRef(null);
  const [isHeaderOnTop, setIsHeaderOnTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerRect = headerRef.current.getBoundingClientRect();
        const headerIsInView = headerRect.top === 0;
        setIsHeaderOnTop(headerIsInView);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const checkProps = (props) => {
    const propsArray = Object.values(props);
    const hasNullOrUndefined = propsArray.some(
      (prop) =>
        isNullOrUndefined(prop) || (Array.isArray(prop) && prop.length === 0)
    );
    return !hasNullOrUndefined;
  };

  if (
    !checkProps({
      paymentFrequency,
      coveragesByProductArray,
      simulatedQuoteProperties,
      chosenProduct,
      setChosenProduct,
      setTotalAmount,
    })
  )
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );

  const {
    colors: { '--color-radiobuttons-checkboxes': UIColor },
  } = theme;

  const extractUniqueCoverages = (coveragesByProduct) => {
    const uniqueCoverages = [];
    Object.values(coveragesByProduct).forEach((product) => {
      product.forEach((coverage) => {
        const isExistingCoverage = uniqueCoverages.some(
          (existingCoverage) => existingCoverage.code === coverage.code
        );
        if (!isExistingCoverage) uniqueCoverages.push(coverage);
      });
    });
    return uniqueCoverages.sort((a, b) => a.display_order - b.display_order);
  };

  const chosenProductObject = simulatedQuoteProperties.find(
    (property) => property.namespace === 'chosen_product'
  );
  if (chosenProductObject === undefined) return <></>;
  const productsArray = chosenProductObject.options;

  const uniqueCoverages = extractUniqueCoverages(coveragesByProductArray);
  const getProductInfo = (productData, selectedFrequency) => {
    const rateByProductObject = simulatedQuoteProperties.find(
      (property) => property.namespace === 'rate_by_product'
    );
    const paymentFrequencyObject = simulatedQuoteProperties.find(
      (property) => property.namespace === 'payment_frequency'
    );

    const productInfo = {};

    productInfo.key = productData;
    productInfo.name = productsArray.find(
      (matchProduct) => matchProduct.data === productData
    ).label;
    productInfo.frequency = paymentFrequencyObject.options.find(
      (frequency) => frequency.data === selectedFrequency
    ).label;

    const parsedRateByProduct = JSON.parse(
      rateByProductObject.data.replace(/\\/g, '')
    );
    productInfo.rate =
      parsedRateByProduct[productData][selectedFrequency].first_installment;
    productInfo.secondInstallment =
      parsedRateByProduct[productData][selectedFrequency].other_installments;

    return productInfo;
  };

  const selectedProductCoverages = coveragesByProductArray[chosenProduct];

  const isAditionalCoverage = (cellsSpecs, coveragesByShownProduct) => {
    if (cellsSpecs === undefined) return false;
    if (!cellsSpecs?.is_base_coverage) return true;

    return coveragesByShownProduct.some((coverageThatOverridesBaseCoverage) =>
      coverageThatOverridesBaseCoverage.incompatibility.some(
        (foundIncompatibility) =>
          foundIncompatibility.coverage_code === cellsSpecs.code
      )
    );
  };

  const handleCheckboxChange = (cellsCoverageCode, cellsProductCode) => {
    /**
     * These checkboxes are related to a table where each column shows a
     *  variety of coverages from a product.
     *
     * each coverage can be a base Coverage (coverage.is_base_coverage: true)
     * and this means it is selected by default.
     *
     * every coverage has an array of incompatible coverages
     * (coverage.incompatibility)
     *
     * If a checked coverage is incompatible with another, it forces the other
     * coverage to be unchecked.
     *
     * If a base Coverage can be checked and it's not incompatible with any
     * selected coverage, it HAS TO BE checked
     * **/

    const updatedState = { ...UICoveragesByQuote };
    const coveragesSpecs = coveragesByProductArray[cellsProductCode];
    const isCheckboxChecked =
      updatedState[cellsProductCode].find(
        (coverage) => coverage.code === cellsCoverageCode
      )?.selected === true;

    const getOverriddenCoveragesCodes = (coveragesSpecs, currentState) => {
      return coveragesSpecs.reduce((codes, currentCoverage) => {
        const isCoverageSelected =
          currentState[cellsProductCode].find(
            (matchCoverage) => matchCoverage.code === currentCoverage.code
          )?.selected === true;
        if (isCoverageSelected) {
          currentCoverage.incompatibility.forEach((coverageToBeOverridden) => {
            if (!codes.includes(coverageToBeOverridden.coverage_code))
              codes.push(coverageToBeOverridden.coverage_code);
          });
        }
        return codes;
      }, []);
    };

    const getUncheckedBaseCoverages = (coveragesSpecs, currentState) => {
      return coveragesSpecs.filter(
        (coverageSpecs) =>
          coverageSpecs.is_base_coverage &&
          !currentState[cellsProductCode].find(
            (baseCoverage) => baseCoverage.code === coverageSpecs.code
          )?.selected
      );
    };

    const updateCoveragesChecksOnFilter = (currentState, filter, checkBool) => {
      return currentState.map((coverage) => {
        if (filter(coverage)) return { ...coverage, selected: checkBool };
        return coverage;
      });
    };

    if (isCheckboxChecked) {
      updatedState[cellsProductCode] = updateCoveragesChecksOnFilter(
        updatedState[cellsProductCode],
        (coverage) => coverage.code === cellsCoverageCode,
        false
      );
      const overriddenCoveragescodes = getOverriddenCoveragesCodes(
        coveragesSpecs,
        updatedState
      );
      const uncheckedBaseCoverages = getUncheckedBaseCoverages(
        coveragesSpecs,
        updatedState
      );
      updatedState[cellsProductCode] = updateCoveragesChecksOnFilter(
        updatedState[cellsProductCode],
        (coverage) =>
          uncheckedBaseCoverages.some(
            (matchCoverage) => matchCoverage.code === coverage.code
          ) && !overriddenCoveragescodes.includes(coverage.code),
        true
      );
    }

    if (!isCheckboxChecked) {
      const isCoverageIncompatibleWithClickedCell = (coverage) => {
        const clickedCell = coveragesSpecs.find(
          (clickedCoverage) => clickedCoverage.code === cellsCoverageCode
        );
        const incompatibleCodes = clickedCell?.incompatibility?.map(
          (incompatibleCoverage) => incompatibleCoverage.coverage_code
        );
        return incompatibleCodes.includes(coverage.code);
      };

      updatedState[cellsProductCode] = updateCoveragesChecksOnFilter(
        updatedState[cellsProductCode],
        (coverage) => coverage.code === cellsCoverageCode,
        true
      );
      updatedState[cellsProductCode] = updateCoveragesChecksOnFilter(
        updatedState[cellsProductCode],
        isCoverageIncompatibleWithClickedCell,
        false
      );
      const overriddenCoveragescodes = getOverriddenCoveragesCodes(
        coveragesSpecs,
        updatedState
      );
      const uncheckedBaseCoverages = getUncheckedBaseCoverages(
        coveragesSpecs,
        updatedState
      );
      updatedState[cellsProductCode] = updateCoveragesChecksOnFilter(
        updatedState[cellsProductCode],
        (coverage) =>
          uncheckedBaseCoverages.some(
            (matchCoverage) => matchCoverage.code === coverage.code
          ) && !overriddenCoveragescodes.includes(coverage.code),
        true
      );
    }
    setUICoveragesByQuote(updatedState);
  };

  const screenCut = (() => {
    if (productsArray.length < 4) return 'md';
    if (productsArray.length === 4) return 'lg';
    return 'xl';
  })();

  return (
    <>
      <table className={`d-none d-${screenCut}-block main-table-wrapper`}>
        <thead
          className={`coverage-table-head d-block ${
            isHeaderOnTop ? 'head-shadow' : ''
          }`}
          ref={headerRef}
        >
          <tr className="d-flex flex-row">
            <th
              className={`d-none d-${screenCut}-flex justify-content-start align-items-center description-header`}
            >
              Coberturas
            </th>
            {productsArray.map((product, productIndex) => (
              <Fragment key={product.data}>
                <ProductHeader
                  isSelected={product.data === chosenProduct}
                  hasProperRate={productsCheckedWithUI[product.data]}
                  refreshing={refreshing}
                  refreshRate={refreshRate}
                  setSelectedProduct={setChosenProduct}
                  productInfo={getProductInfo(product.data, paymentFrequency)}
                  setTotalAmount={setTotalAmount}
                />
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody className="d-block">
          {uniqueCoverages.map((shownCoverage, coverageIndex) => {
            const typeIntoCode = { C: 'capital_option', R: 'rate_option' };
            const optionCode = typeIntoCode[shownCoverage.type];

            return [
              <tr className="d-flex flex-row" key={shownCoverage.code}>
                <CoverageLabel
                  label={shownCoverage.name}
                  description={shownCoverage.description}
                  screenCut={screenCut}
                />
                {productsArray.map((product, productIndex) => {
                  const cellsState = UICoveragesByQuote[product.data]?.find(
                    (coverage) => coverage.code === shownCoverage.code
                  );
                  const selectedCoverageData = cellsState
                    ? cellsState[optionCode]
                    : 0;
                  const cellsSpecs = coveragesByProductArray[
                    product.data
                  ]?.find(
                    (matchCoverage) => matchCoverage.code === shownCoverage.code
                  );
                  const codesFromSamePack = coveragesByProductArray[
                    product?.data
                  ]
                    ?.filter((coverageFromPack) => {
                      const cellHasPack =
                        cellsSpecs?.linked_options_pack === true;
                      return cellHasPack
                        ? coverageFromPack?.linked_options_pack === true
                        : coverageFromPack.code === shownCoverage.code;
                    })
                    ?.map((coverage) => coverage.code);
                  return (
                    <Fragment key={product.data}>
                      <CoverageCell
                        isLast={coverageIndex === uniqueCoverages.length - 1}
                        selected={product.data === chosenProduct}
                        coverage={cellsSpecs}
                        cellsState={cellsState}
                        coverageCode={shownCoverage.code}
                        productData={product.data}
                        optionCode={optionCode}
                        coveragecodesFromSamePack={
                          codesFromSamePack === undefined
                            ? []
                            : codesFromSamePack
                        }
                        selectedCoverageData={selectedCoverageData}
                        setUICoveragesByQuote={setUICoveragesByQuote}
                        hasOptionalCheckbox={isAditionalCoverage(
                          cellsSpecs,
                          coveragesByProductArray[product.data]
                        )}
                        handleCheckboxChange={handleCheckboxChange}
                        refreshing={refreshing}
                        screenCut={screenCut}
                      />
                    </Fragment>
                  );
                })}
              </tr>,
              ...shownCoverage.sub_coverages
                .sort((a, b) => a.display_order - b.display_order)
                .map((subCoverage) => (
                  <tr key={subCoverage.code} className="d-flex flex-row">
                    <td className="subcoverage-label">{subCoverage.name}</td>
                    {productsArray.map((product, productIndex) => {
                      const coverageData = UICoveragesByQuote[
                        product.data
                      ].find(
                        (matchCoverage) =>
                          matchCoverage.code === shownCoverage.code
                      );
                      const optionCode =
                        shownCoverage.type === 'C'
                          ? 'capital_option'
                          : 'rate_option';
                      const chosenOption = coverageData[optionCode];
                      const chosenOptionData = subCoverage.options.find(
                        (option) => option.option_related === chosenOption
                      );

                      return (
                        <td
                          key={subCoverage.code + product.data}
                          className={`
                            d-${screenCut}-flex align-items-center justify-content-center subcoverage-cell`}
                          style={{
                            backgroundColor:
                              product.data === chosenProduct
                                ? addOpacityToHexColor(UIColor, 15)
                                : 'var(--color-grey100)',
                          }}
                        >
                          <p>
                            {chosenOptionData?.value
                              ? chosenOptionData.value
                              : 'X'}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                )),
            ];
          })}
        </tbody>
      </table>

      <table className={`d-block d-${screenCut}-none mobile-table-wrapper`}>
        <thead className="d-block">
          <tr className="d-flex tr-small flex-row justify-content-start align-items-center coverage-header-small">
            <th className="me-3">Coberturas Incluídas</th>
            <TooltipButtonWithModal
              chosenProductCoverages={coveragesByProductArray[
                chosenProduct
              ].filter(
                (coverageSpecs) =>
                  !isAditionalCoverage(
                    coverageSpecs,
                    coveragesByProductArray[chosenProduct]
                  )
              )}
              modalTitle={'Coberturas Incluídas'}
            />
          </tr>
        </thead>
        <tbody className="d-block mb-3">
          {selectedProductCoverages
            .filter(
              (coverageSpecs) =>
                !isAditionalCoverage(coverageSpecs, selectedProductCoverages)
            )
            .map(
              ({
                code: coverageCode,
                name: coverageLabel,
                description,
                sub_coverages: subCoverages,
                is_base_coverage: isBaseCoverage,
              }) => {
                const coverageState = UICoveragesByQuote[chosenProduct].find(
                  (coverage) => coverage.code === coverageCode
                );
                const cellSpecs = coveragesByProductArray[chosenProduct].find(
                  (matchCoverage) => matchCoverage.code === coverageCode
                );

                const optionCode =
                  cellSpecs.type === 'C' ? 'capital_option' : 'rate_option';

                const selectedCoverageCode = coverageState[optionCode]
                  ? coverageState[optionCode]
                  : 0;
                const codesFromSamePack = coveragesByProductArray[chosenProduct]
                  .filter((coverageFromPack) => {
                    const cellHasPack = cellSpecs?.linked_options_pack === true;
                    return cellHasPack
                      ? coverageFromPack?.linked_options_pack === true
                      : coverageFromPack.code === coverageCode;
                  })
                  .map((coverage) => coverage.code);

                const subCoveragesFromSelectedCoverage = subCoverages.filter(
                  ({ options }) => {
                    const coverageHasThisSubCoverage = options.some(
                      ({ option_related }) =>
                        option_related === selectedCoverageCode
                    );
                    return coverageHasThisSubCoverage;
                  }
                );
                return (
                  <Fragment key={coverageCode}>
                    <tr
                      className={`d-flex${
                        subCoverages.length > 0 ? '' : ' tr-small'
                      }`}
                      key={coverageCode}
                    >
                      <CoverageLabel
                        isBaseCoverage={true}
                        label={coverageLabel}
                        description={description}
                        subCoverages={subCoverages}
                        coverageState={coverageState}
                        productCode={chosenProduct}
                        handleCheckboxChange={handleCheckboxChange}
                        screenCut={screenCut}
                      />
                      <CoverageCell
                        isLast={false}
                        selected={true}
                        coverage={cellSpecs}
                        cellsState={coverageState}
                        coverageCode={coverageCode}
                        productData={chosenProduct}
                        optionCode={optionCode}
                        selectedCoverageData={selectedCoverageCode}
                        setUICoveragesByQuote={setUICoveragesByQuote}
                        handleCheckboxChange={handleCheckboxChange}
                        hasOptionalCheckbox={false}
                        refreshing={refreshing}
                        coveragecodesFromSamePack={codesFromSamePack}
                        screenCut={screenCut}
                      />
                    </tr>
                    {subCoverages.length > 0 &&
                      subCoveragesFromSelectedCoverage.map(
                        ({ code, name, options }, index) => {
                          const { value: info } = options.find(
                            ({ option_related }) =>
                              option_related === selectedCoverageCode
                          );
                          const isLast =
                            index ===
                            subCoveragesFromSelectedCoverage.length - 1;
                          return info !== null ? (
                            <tr
                              key={code}
                              className={`d-flex flex-column align-items-start subcoverages ${
                                isLast ? ' tr-small' : ''
                              }`}
                            >
                              <div className={`title`}>{`✓ ${name}:`}</div>
                              <div>{`${info}`}</div>
                            </tr>
                          ) : null;
                        }
                      )}
                  </Fragment>
                );
              }
            )}
        </tbody>
        <thead className="d-block">
          <tr className="d-flex tr-small flex-row justify-content-start align-items-center coverage-header-small">
            <th className="me-3">Coberturas Opcionais</th>
            <TooltipButtonWithModal
              chosenProductCoverages={coveragesByProductArray[
                chosenProduct
              ].filter((coverageSpecs) =>
                isAditionalCoverage(
                  coverageSpecs,
                  coveragesByProductArray[chosenProduct]
                )
              )}
              modalTitle={'Coberturas Opcionais'}
            />
          </tr>
        </thead>
        <tbody className="d-block">
          {selectedProductCoverages
            .filter((coverageSpecs) =>
              isAditionalCoverage(coverageSpecs, selectedProductCoverages)
            )
            .map(
              ({
                code: coverageCode,
                name: coverageLabel,
                description,
                sub_coverages: subCoverages,
                is_base_coverage: isBaseCoverage,
              }) => {
                const coverageState = UICoveragesByQuote[chosenProduct].find(
                  (coverage) => coverage.code === coverageCode
                );
                const cellSpecs = coveragesByProductArray[chosenProduct].find(
                  (matchCoverage) => matchCoverage.code === coverageCode
                );
                const optionCode =
                  cellSpecs.type === 'C' ? 'capital_option' : 'rate_option';

                const selectedCoverageData = coverageState[optionCode]
                  ? coverageState[optionCode]
                  : 0;
                const codesFromSamePack = coveragesByProductArray[chosenProduct]
                  .filter((coverageFromPack) => {
                    const cellHasPack = cellSpecs?.linked_options_pack === true;
                    return cellHasPack
                      ? coverageFromPack?.linked_options_pack === true
                      : coverageFromPack.code === coverageCode;
                  })
                  .map((coverage) => coverage.code);
                return (
                  <tr className="d-flex tr-small" key={coverageCode}>
                    <CoverageLabel
                      isBaseCoverage={false}
                      label={coverageLabel}
                      description={description}
                      subCoverages={subCoverages}
                      coverageState={coverageState}
                      productCode={chosenProduct}
                      handleCheckboxChange={handleCheckboxChange}
                      refreshing={refreshing}
                      screenCut={screenCut}
                    />
                    <CoverageCell
                      isLast={false}
                      selected={true}
                      coverage={cellSpecs}
                      cellsState={coverageState}
                      coverageCode={coverageCode}
                      productData={chosenProduct}
                      optionCode={optionCode}
                      selectedCoverageData={selectedCoverageData}
                      setUICoveragesByQuote={setUICoveragesByQuote}
                      handleCheckboxChange={handleCheckboxChange}
                      hasOptionalCheckbox={true}
                      refreshing={refreshing}
                      coveragecodesFromSamePack={codesFromSamePack}
                      screenCut={screenCut}
                    />
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </>
  );
};

export default CoverageTable;
