import { useRef, useState } from 'react';

const HabitTag = ({}) => {
  const [showHiddenText, setShowHiddenText] = useState(false);
  const tagRef = useRef(null);

  const handleClickInside = () => {
    const showTextOnBeforeClick = showHiddenText;
    setShowHiddenText(!showHiddenText);
    if (showTextOnBeforeClick)
      document.removeEventListener('click', handleClickOutside);
  };

  const handleClickOutside = (event) => {
    const wasClickOnTag = tagRef && tagRef.current.contains(event.target);
    if (!wasClickOnTag) {
      setShowHiddenText(false);
      document.removeEventListener('click', handleClickOutside);
    }
  };

  if (showHiddenText) document.addEventListener('click', handleClickOutside);

  return (
    <div
      className={`habit-tag ${showHiddenText ? 'clicked' : ''}`}
      ref={tagRef}
    >
      <div className="habit-svg-container" onClick={handleClickInside}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="116"
          viewBox="0 0 13 116"
          fill="none"
        >
          <g clipPath="url(#clip0_232_1084)">
            <path
              d="M7.01922 114.358L10.1214 114.358L10.1214 115.403L2.19727 115.403L2.19727 112.481C2.19727 111.614 2.41859 110.934 2.86124 110.443C3.30388 109.951 3.88985 109.705 4.61913 109.705C5.38832 109.705 5.98063 109.946 6.39607 110.426C6.8115 110.907 7.01922 111.595 7.01922 112.492L7.01922 114.358ZM6.16477 114.358L6.16477 112.481C6.16477 111.922 6.03324 111.494 5.77019 111.196C5.50714 110.899 5.12708 110.75 4.63001 110.75C4.15834 110.75 3.781 110.899 3.498 111.196C3.21499 111.494 3.06805 111.902 3.05716 112.421L3.05716 114.358L6.16477 114.358Z"
              fill="#9CACBB"
            />
            <path
              d="M7.12278 108.532C6.54589 108.532 6.02704 108.419 5.56626 108.192C5.10547 107.965 4.7499 107.65 4.49955 107.245C4.2492 106.841 4.12402 106.379 4.12402 105.86C4.12402 105.058 4.40158 104.41 4.95671 103.914C5.51183 103.419 6.25018 103.171 7.17176 103.171L7.24251 103.171C7.81578 103.171 8.33008 103.281 8.78543 103.501C9.24077 103.72 9.59544 104.034 9.84942 104.442C10.1034 104.85 10.2304 105.319 10.2304 105.849C10.2304 106.647 9.95282 107.294 9.3977 107.789C8.84257 108.285 8.10785 108.532 7.19353 108.532L7.12278 108.532ZM7.24251 107.52C7.8956 107.52 8.41988 107.368 8.81536 107.066C9.21084 106.763 9.40858 106.357 9.40858 105.849C9.40858 105.338 9.20812 104.931 8.8072 104.63C8.40627 104.329 7.8448 104.178 7.12278 104.178C6.47695 104.178 5.95357 104.332 5.55265 104.638C5.15173 104.945 4.95127 105.352 4.95127 105.86C4.95127 106.357 5.14901 106.758 5.54449 107.063C5.93997 107.368 6.50597 107.52 7.24251 107.52Z"
              fill="#9CACBB"
            />
            <path
              d="M8.73327 96.3608L4.23242 95.2288L4.23242 94.222L10.1211 95.9363L10.1211 96.7527L5.65833 98.184L10.1211 99.5773L10.1211 100.394L4.23242 102.103L4.23242 101.101L8.64075 99.9419L4.23242 98.5704L4.23242 97.7595L8.73327 96.3608Z"
              fill="#9CACBB"
            />
            <path
              d="M10.2304 90.4207C10.2304 91.2189 9.96824 91.8684 9.44396 92.3691C8.91967 92.8698 8.21851 93.1201 7.34047 93.1201L7.15543 93.1201C6.57128 93.1201 6.04972 93.0085 5.59075 92.7854C5.13177 92.5623 4.77257 92.2502 4.51315 91.8493C4.25373 91.4484 4.12402 91.0139 4.12402 90.5459C4.12402 89.7803 4.37619 89.1853 4.88051 88.7608C5.38484 88.3363 6.10687 88.124 7.04659 88.124L7.46565 88.124L7.46565 92.1133C8.04617 92.0988 8.51512 91.9291 8.87251 91.6044C9.22989 91.2797 9.40858 90.867 9.40858 90.3663C9.40858 90.0107 9.33602 89.7096 9.19089 89.4628C9.04576 89.2161 8.85346 89.0002 8.61399 88.8152L9.09292 88.2002C9.85123 88.6936 10.2304 89.4338 10.2304 90.4207ZM4.95127 90.5459C4.95127 90.9522 5.09912 91.2933 5.39482 91.569C5.69052 91.8448 6.10505 92.0153 6.63841 92.0806L6.63841 89.1308L6.56221 89.1308C6.05063 89.1599 5.65424 89.2977 5.37305 89.5445C5.09186 89.7912 4.95127 90.125 4.95127 90.5459Z"
              fill="#9CACBB"
            />
            <path
              d="M5.13631 83.7786C5.11091 83.931 5.09821 84.0961 5.09821 84.2739C5.09821 84.9342 5.3794 85.3823 5.94178 85.6182L10.1215 85.6182L10.1215 86.625L4.23287 86.625L4.23287 85.6454L4.91317 85.629C4.38707 85.2989 4.12402 84.8308 4.12402 84.2249C4.12402 84.029 4.14942 83.8802 4.20022 83.7786L5.13631 83.7786Z"
              fill="#9CACBB"
            />
            <path
              d="M10.2304 80.1795C10.2304 80.9777 9.96824 81.6272 9.44396 82.1279C8.91967 82.6286 8.21851 82.8789 7.34047 82.8789L7.15543 82.8789C6.57128 82.8789 6.04972 82.7673 5.59075 82.5442C5.13177 82.3211 4.77257 82.009 4.51315 81.6081C4.25373 81.2072 4.12402 80.7727 4.12402 80.3047C4.12402 79.5391 4.37619 78.9441 4.88051 78.5196C5.38484 78.0951 6.10687 77.8828 7.04659 77.8828L7.46565 77.8828L7.46565 81.8721C8.04617 81.8576 8.51512 81.6879 8.87251 81.3632C9.22989 81.0385 9.40858 80.6258 9.40858 80.1251C9.40858 79.7695 9.33602 79.4683 9.19089 79.2216C9.04576 78.9749 8.85346 78.759 8.61399 78.574L9.09292 77.959C9.85123 78.4524 10.2304 79.1926 10.2304 80.1795ZM4.95127 80.3047C4.95127 80.711 5.09912 81.0521 5.39482 81.3278C5.69052 81.6036 6.10505 81.7741 6.63841 81.8394L6.63841 78.8896L6.56221 78.8896C6.05063 78.9187 5.65424 79.0565 5.37305 79.3033C5.09186 79.55 4.95127 79.8838 4.95127 80.3047Z"
              fill="#9CACBB"
            />
            <path
              d="M7.12791 76.6279C6.22448 76.6279 5.49792 76.4139 4.94824 75.9857C4.39856 75.5576 4.12372 74.997 4.12372 74.304C4.12372 73.6147 4.35955 73.0686 4.83123 72.6659L1.76172 72.6659L1.76172 71.659L10.1212 71.659L10.1212 72.5842L9.48991 72.6332C9.98335 73.036 10.2301 73.5965 10.2301 74.3149C10.2301 74.997 9.9507 75.5531 9.39195 75.983C8.83319 76.413 8.10391 76.6279 7.20411 76.6279L7.12791 76.6279ZM7.2422 75.6211C7.9098 75.6211 8.43227 75.4832 8.80961 75.2075C9.18695 74.9317 9.37562 74.5508 9.37562 74.0646C9.37562 73.426 9.08899 72.9598 8.51572 72.6659L5.81086 72.6659C5.25573 72.967 4.97817 73.4296 4.97817 74.0537C4.97817 74.5471 5.16865 74.9317 5.54962 75.2075C5.93059 75.4832 6.49478 75.6211 7.2422 75.6211Z"
              fill="#9CACBB"
            />
            <path
              d="M7.2422 61.6837C8.14201 61.6837 8.86494 61.8905 9.41099 62.3042C9.95705 62.7178 10.2301 63.2729 10.2301 63.9695C10.2301 64.7133 9.96702 65.2884 9.44093 65.6948L10.1212 65.7437L10.1212 66.6689L1.76172 66.6689L1.76172 65.6621L4.88021 65.6621C4.37588 65.2557 4.12372 64.6952 4.12372 63.9804C4.12372 63.2656 4.39402 62.7042 4.93463 62.296C5.47524 61.8878 6.21541 61.6837 7.15512 61.6837L7.2422 61.6837ZM7.12791 62.6906C6.44217 62.6906 5.91245 62.823 5.53874 63.0879C5.16503 63.3527 4.97817 63.7337 4.97817 64.2308C4.97817 64.8947 5.28657 65.3718 5.90338 65.6621L8.45041 65.6621C9.06722 65.3537 9.37562 64.873 9.37562 64.2199C9.37562 63.7373 9.18876 63.3618 8.81505 63.0933C8.44134 62.8248 7.87896 62.6906 7.12791 62.6906Z"
              fill="#9CACBB"
            />
            <path
              d="M8.6462 58.2154L4.23242 56.8439L4.23242 55.7663L11.03 58.1337C12.0096 58.5002 12.4994 59.0825 12.4994 59.8807L12.4831 60.0712L12.4123 60.4468L11.596 60.4468L11.6177 60.1746C11.6177 59.8336 11.5488 59.5678 11.4109 59.3773C11.2731 59.1868 11.0209 59.0299 10.6544 58.9066L10.0558 58.6834L4.23242 60.7842L4.23242 59.6848L8.6462 58.2154Z"
              fill="#9CACBB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.87319 2.92262L9.87319 1.338L11.4974 1.338L11.4974 2.9251L11.4997 2.9251L11.4951 4.53702L6.21297 4.52926L6.20973 5.75488L4.75886 5.75085L4.76243 4.52523L3.00797 4.52058L2.83301 2.8947L4.76729 2.89997L4.77214 1.08823L6.22302 1.09226L6.21783 2.91735L9.87319 2.92262Z"
              fill="#1F324D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.82061 6.75488C4.13373 6.75844 4.12822 8.75843 2.8151 8.75488C1.50198 8.75132 1.50749 6.75132 2.82061 6.75488ZM4.7091 8.60737L11.4951 8.62646L11.4997 6.93214L4.71363 6.91306L4.7091 8.60737Z"
              fill="#1F324D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.83586 15.3911L5.58829 15.4013C4.90024 15.0025 4.52468 13.8929 4.5269 13.1811C4.53229 11.2101 5.72552 9.7492 8.02262 9.75556C10.212 9.76163 11.505 11.2573 11.4997 13.2416C11.4974 14.0628 11.2252 14.897 10.4262 15.415L11.3453 15.5269L11.3409 17.0889L1.83301 17.0624L1.83586 15.3911ZM8.00772 15.2987C9.21047 15.3018 9.96951 14.4007 9.97269 13.3468C9.97585 12.2793 9.18036 11.4288 8.0185 11.4253C6.81576 11.4221 6.07034 12.2684 6.06716 13.336C6.06368 14.3898 6.84555 15.2955 8.00772 15.2987Z"
              fill="#1F324D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.66754 20.0231L4.67196 18.4216L11.3086 18.4404L11.3045 20.0145L10.3349 20.0939C11.1147 20.4793 11.4886 21.5347 11.5 22.2875C11.5081 24.2863 10.2926 25.7628 7.96392 25.7549C5.6753 25.7481 4.48159 24.1979 4.50021 22.2266C4.50274 21.3233 4.92232 20.4621 5.58311 20.0805L4.66754 20.0231ZM7.96897 24.0844C9.23467 24.0879 9.98954 23.2006 9.99365 22.0917C10.0009 19.4632 5.97586 19.452 5.96859 22.0802C5.96576 23.1891 6.70359 24.0809 7.96897 24.0844Z"
              fill="#1F324D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.83753 31.7427L5.61414 31.7544C4.83024 31.1544 4.59814 30.2443 4.60008 29.4851C4.60558 27.5868 5.91735 26.7496 7.91573 26.7552L11.4997 26.7647L11.4948 28.4189L7.9251 28.4069C6.68444 28.4034 6.15656 29.0385 6.15656 29.9206C6.15656 31.1471 6.99251 31.7557 8.01238 31.7582L11.4861 31.7677L11.4812 33.4219L1.83301 33.3966L1.83753 31.7427Z"
              fill="#1F324D"
            />
            <path
              d="M1.87197 44.0862C2.44578 43.0923 3.71662 42.7518 4.71048 43.3256L10.7001 46.7838C11.694 47.3576 12.0345 48.6284 11.4607 49.6223C10.8869 50.6161 9.61606 50.9566 8.6222 50.3828L2.63255 46.9247C1.63869 46.3509 1.29817 45.0801 1.87197 44.0862Z"
              fill="#FBBD18"
            />
            <path
              d="M1.87197 37.7171C2.44578 36.7232 3.71662 36.3827 4.71048 36.9565L10.7001 40.4146C11.694 40.9884 12.0345 42.2593 11.4607 43.2531C10.8869 44.247 9.61606 44.5875 8.6222 44.0137L2.63255 40.5556C1.63869 39.9818 1.29817 38.7109 1.87197 37.7171Z"
              fill="#FBBD18"
            />
          </g>
          <defs>
            <clipPath id="clip0_232_1084">
              <rect
                width="115.111"
                height="11.6667"
                fill="white"
                transform="translate(0.833008 115.978) rotate(-90)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="tag-hidden-text-container">
        <p className="tag-hidden-text">
          A Habit, cria a estrutura certa para soluções de seguros inovadoras
          que irão proporcionam uma experiência única ao cliente para um
          potencial de vendas otimizado. Saiba mais em{' '}
          <a
            href="https://www.habit.io/"
            target="_blank"
            className="tag-hidden-text-link"
          >
            habit.io
          </a>
        </p>
      </div>
    </div>
  );
};

export default HabitTag;
