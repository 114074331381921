import { useTheme } from '../../../contexts/ThemeContext';
import { getDefaultLanguageFromData } from '../../../utils/functions';
import { AUTOKEY_TYPE, getItemLocalStorage } from '../../../utils/helper';
import TileComponent from './BioBodyTileComponent';

const Body = ({ documents }) => {
  const { theme } = useTheme();
  const defaultLanguage = getDefaultLanguageFromData(theme);
  const { section_title, tiles } = theme.bio_sections.tiles_section;
  const {
    insurance_information,
    legal_information_title,
    legal_information_text,
  } = theme.bio_sections.texts;
  const userType = getItemLocalStorage(AUTOKEY_TYPE);

  return (
    <>
      <div className="d-flex flex-column d-md-none body-container-mobile">
        <p className="pre-tiles-text-mobile">
          {theme.bio_sections.texts[section_title][defaultLanguage]}
        </p>
        <div className="d-flex flex-wrap justify-content-between">
          {tiles
            .toSorted((tileA, tileB) => tileA.order_index - tileB.order_index)
            .map((tile) => {
              return (
                <TileComponent
                  key={tile.order_index}
                  TileSpecs={tile}
                  defaultLanguage={defaultLanguage}
                />
              );
            })}
        </div>
        <div>
          <p className="insurance-information mb-5">
            {insurance_information[defaultLanguage]}
          </p>
        </div>
        {userType !== 'lead' && (
          <div>
            <p className="legal-information-title mb-0">
              {legal_information_title[defaultLanguage]}
            </p>
            <p className="legal-information-text">
              {legal_information_text[defaultLanguage]}
            </p>
            <ul className="ps-3 m-0 mb-5">
              {documents.map(function (value, index) {
                var uri = value.uri;
                return (
                  <li key={index}>
                    <a
                      className="document-container-link"
                      href={uri}
                      target="_blank"
                    >
                      {value.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      <div className="d-none d-md-block body-container">
        <div className="pre-tiles-text">
          {theme.bio_sections.texts[section_title][defaultLanguage]}
        </div>
        <div className="d-flex flex-wrap justify-content-between">
          {tiles
            .toSorted((tileA, tileB) => tileA.order_index - tileB.order_index)
            .map((tile) => {
              return (
                <TileComponent
                  key={tile.order_index}
                  TileSpecs={tile}
                  defaultLanguage={defaultLanguage}
                />
              );
            })}
        </div>
        <div>
          <p className="insurance-information mb-5">
            {insurance_information[defaultLanguage]}
          </p>
        </div>
        {userType !== 'lead' && (
          <div>
            <p className="legal-information-title mb-0">
              {legal_information_title[defaultLanguage]}
            </p>

            <p className="legal-information-text">
              {legal_information_text[defaultLanguage]}
            </p>
            <ul className="ps-3 m-0 mb-5">
              {documents.map(function (value, index) {
                var uri = value.uri;
                return (
                  <li key={index}>
                    <a
                      className="document-container-link"
                      href={uri}
                      target="_blank"
                    >
                      {value.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Body;
