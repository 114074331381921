import { BrowserRouter } from 'react-router-dom';
import AppLayout from './layouts/frontend/AppLayout';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import routes from './routes/routes';
import React from 'react';
import { useTheme } from './contexts/ThemeContext';
import { addOpacityToHexColor } from './utils/functions';

function App() {
  const { theme } = useTheme();

  const needsBasename = () => {
    const location = window.location;

    const needsBasenameArray = [
      'distributors.habit.io',
      'distributors.qa.habit.io',
      'distributors.stg.habit.io',
      'distributors.integrations.habit.io',
    ];

    return needsBasenameArray.includes(location.hostname);
  };

  const hasFontAvailable = (data) => {
    return data !== null && data !== undefined && data !== '';
  };

  const { hasSlimStyle, border_radius, colors, fonts, CONFIG } = theme;
  const { BASENAME } = CONFIG;

  const appStyleVariables = {
    '--color-primary': colors['--color-primary'],
    '--color-secondary': colors['--color-secondary'],
    '--color-header-text': colors['--color-header-text'],
    '--color-tile-title': colors['--color-tile-title'],
    '--color-tile-text': colors['--color-tile-text'],
    '--color-tile-shape': colors['--color-tile-shape'],
    '--color-links': colors['--color-links'],
    '--color-radiobuttons-checkboxes':
      colors['--color-radiobuttons-checkboxes'],
    '--color-box-shadow': addOpacityToHexColor(
      colors['--color-radiobuttons-checkboxes'],
      15
    ),

    '--border-radius-button': border_radius.button,
    '--border-radius-tile': border_radius.tile,
    '--border-radius-background': border_radius.background
      ? border_radius.background
      : '0px',

    '--font-primary': 'Regular',
    '--font-bold': hasFontAvailable(fonts.bold) ? 'Bold' : 'Regular',
    // prettier-ignore
    '--font-medium': hasFontAvailable(fonts.medium) ? 'Medium' : 'Regular',
    '--font-light': hasFontAvailable(fonts.light) ? 'Light' : 'Regular',

    '--padding-left-content': hasSlimStyle ? '20px 20px 0px' : '85px 100px 0px',
    '--padding-box-wrapper': hasSlimStyle ? '30px 20px' : '25px 65px',
    '--padding-vertical-text-wrapper': hasSlimStyle ? '30px' : '50px',
    '--padding-right-container': hasSlimStyle
      ? '20px 10px 20px 10px'
      : '20px 15px 20px 15px',
    '--min-width-right-content': hasSlimStyle ? '330px' : '400px',
  };

  return (
    <BrowserRouter basename={needsBasename() ? '/' + BASENAME : '/'}>
      <style type="text/css">{`
      @font-face {
        font-family: 'Regular';
        src: url('${fonts.regular}') format('opentype');
      }
      ${
        hasFontAvailable(fonts.bold)
          ? `@font-face {
        font-family: 'Bold';
        src: url('${fonts.bold}') format('opentype');
      }`
          : ''
      }
      ${
        hasFontAvailable(fonts.medium)
          ? `@font-face {
        font-family: 'Medium';
        src: url('${fonts.medium}') format('opentype');
      }`
          : ''
      }
      ${
        hasFontAvailable(fonts.light)
          ? `@font-face {
        font-family: 'Light';
        src: url('${fonts.light}') format('opentype');
      }`
          : ''
      }
      `}</style>
      <div className="App" style={appStyleVariables}>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    name={route.name}
                    element={<route.component />}
                  />
                )
              );
            })}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
