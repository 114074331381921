import { useState, useRef } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';

const TooltipButton = ({ dropdownText, className }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const tooltipButtonRef = useRef(null);
  const tooltipTextRef = useRef(null);
  const { theme } = useTheme();

  const {
    '--color-secondary': openColor,
    '--color-radiobuttons-checkboxes': closedColor,
  } = theme.colors;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) document.removeEventListener('click', handleClickOutside);
  };

  const handleClickOutside = (event) => {
    const wasClickOnButton =
      tooltipButtonRef && tooltipButtonRef.current.contains(event.target);
    const wasClickOnText =
      tooltipTextRef && tooltipTextRef.current.contains(event.target);

    if (!(wasClickOnButton || wasClickOnText)) {
      setShowDropdown(false);
      document.removeEventListener('click', handleClickOutside);
    }
  };

  if (showDropdown) document.addEventListener('click', handleClickOutside);

  const { title, body } = dropdownText;
  const hasTitle = !!title;

  return (
    <div className={`${className}`}>
      <button
        onClick={toggleDropdown}
        ref={tooltipButtonRef}
        className="reset-button-style"
        type="button"
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5003 2.83333C6.26614 2.83333 2.83366 6.26582 2.83366 10.5C2.83366 14.7342 6.26614 18.1667 10.5003 18.1667C14.7345 18.1667 18.167 14.7342 18.167 10.5C18.167 6.26582 14.7345 2.83333 10.5003 2.83333ZM0.916992 10.5C0.916992 5.20727 5.2076 0.916667 10.5003 0.916667C15.7931 0.916667 20.0837 5.20728 20.0837 10.5C20.0837 15.7928 15.7931 20.0833 10.5003 20.0833C5.2076 20.0833 0.916992 15.7928 0.916992 10.5ZM9.54199 6.66667C9.54199 6.13739 9.97105 5.70833 10.5003 5.70833H10.5099C11.0392 5.70833 11.4682 6.13739 11.4682 6.66667C11.4682 7.19594 11.0392 7.625 10.5099 7.625H10.5003C9.97105 7.625 9.54199 7.19594 9.54199 6.66667ZM8.58366 10.5C8.58366 9.97073 9.01272 9.54167 9.54199 9.54167H10.5003C11.0296 9.54167 11.4587 9.97073 11.4587 10.5V13.375C11.9879 13.375 12.417 13.8041 12.417 14.3333C12.417 14.8626 11.9879 15.2917 11.4587 15.2917H10.5003C9.97105 15.2917 9.54199 14.8626 9.54199 14.3333V11.4583C9.01272 11.4583 8.58366 11.0293 8.58366 10.5Z"
            fill={showDropdown ? openColor : closedColor}
          />
        </svg>
      </button>
      {showDropdown && (
        <div className="dropdown-menu dropdown-content" ref={tooltipTextRef}>
          {hasTitle && <div className="fweight500">{title}</div>}
          <div className="fweight400">{body}</div>
        </div>
      )}
    </div>
  );
};

export default TooltipButton;
