import React from 'react';
import { Outlet } from 'react-router-dom';

import '../../assets/frontend/css/bootstrap.min.css';
import '../../assets/frontend/css/style.css';
import '../../assets/frontend/css/media.css';

const AppLayout = () => {
  return (
    <>
      <main className="wrapper form-sec">
        <Outlet />
      </main>
    </>
  );
};

export default AppLayout;
