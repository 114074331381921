import { Fragment } from 'react';

export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const getDefaultLanguageFromData = (data) => {
  const textsObject = data.bio_sections.texts;
  const firstTextKey = Object.keys(textsObject)[0];
  const firstText = textsObject[firstTextKey];
  const firstLanguage = Object.keys(firstText)[0];

  const { default_language } = data;
  const defaultLanguage = default_language ? default_language : firstLanguage;

  return defaultLanguage;
};

export const checkTextBeforeDisplay = (
  textsObject,
  textReference,
  defaultLanguage
) => {
  const textToRender = textsObject[textReference];
  if (textToRender === '' || isNullOrUndefined(textToRender)) {
    return '';
  }

  if (textToRender[defaultLanguage] === undefined) {
    return textToRender[Object.keys(textToRender)[0]];
  }
  return textToRender[defaultLanguage];
};

export const updateErrorState = (
  previousErrorState,
  namespace,
  errorMessages
) => {
  const updatedErrorState = { ...previousErrorState };
  const errorKey = namespace || 'root';
  const namespaceErrors = updatedErrorState[errorKey] || [];

  errorMessages.forEach((errorMessage) => {
    if (!namespaceErrors.includes(errorMessage))
      namespaceErrors.push(errorMessage);
  });
  updatedErrorState[errorKey] = namespaceErrors;

  return updatedErrorState;
};

export const clearErrorStateNamespace = (previousErrorState, namespace) => {
  const updatedErrorState = { ...previousErrorState };
  delete updatedErrorState[namespace];
  return updatedErrorState;
};

export const clearErrorNamespaces = (previousErrorState, namespacesArray) => {
  const updatedErrorState = { ...previousErrorState };
  namespacesArray.forEach((namespace) => {
    delete updatedErrorState[namespace];
  });
  return updatedErrorState;
};

export const handleResponseError = (
  errorState,
  errorText,
  checkFields,
  standardMessage
) => {
  const initialState = { ...errorState };
  const containsColon = errorText.includes(':');
  if (containsColon) {
    const [errorMessage, namespace] = errorText.split(':');
    return updateErrorState(initialState, namespace.trim(), [
      errorMessage.trim(),
    ]);
  }
  const largestToShortestNamespaces = checkFields
    .slice()
    .sort((a, b) => b.length - a.length);
  const foundError = largestToShortestNamespaces.find((namespace) => {
    const regex = new RegExp(namespace, 'g');
    return errorText.match(regex);
  });
  if (!foundError) console.error(errorText);
  return foundError
    ? updateErrorState(initialState, foundError, [errorText])
    : updateErrorState(initialState, 'root', [standardMessage]);
};

export const makeJSXFromTextWithLineBreaks = (text, customClassname) => {
  const lines = text.split('\n');
  return (
    <p className={customClassname}>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </p>
  );
};

export const arrayToCSV = (array) =>
  array
    .filter((item) => item.trim() !== '')
    .toSorted()
    .join(',');

export const CSVToArray = (csv) =>
  csv.split(',').filter((item) => item.trim() !== '');

//COLOR FUNCTIONS

/**
 * Interpolates between two intermediary color values based on the given hue reference.
 *
 * @param {number} lowPivot - The low intermediary value.
 * @param {number} highPivot - The high intermediary value.
 * @param {number} hueRef - The hue reference in the range [0, 1].
 * @returns {number} The interpolated color component value.
 */
function hueToHexSingleColor(lowPivot, highPivot, hueRef) {
  //getting first arc
  while (hueRef < 0) hueRef += 1;
  while (hueRef > 1) hueRef -= 1;

  if (hueRef < 1 / 6) return lowPivot + (highPivot - lowPivot) * 6 * hueRef;
  if (hueRef < 1 / 2) return highPivot;
  if (hueRef < 2 / 3)
    return lowPivot + (highPivot - lowPivot) * (2 / 3 - hueRef) * 6;
  return lowPivot;
}

/**
 * Converts RGB color values to HSL.
 *
 * @param {number} red - The red component [0, 255].
 * @param {number} green - The green component [0, 255].
 * @param {number} blue - The blue component [0, 255].
 * @returns {Array} The HSL representation [hue, saturation, lightness].
 */
export const rgbToHsl = (red, green, blue) => {
  const normalizedRed = red / 255;
  const normalizedGreen = green / 255;
  const normalizedBlue = blue / 255;

  const maxValue = Math.max(normalizedRed, normalizedGreen, normalizedBlue);
  const minValue = Math.min(normalizedRed, normalizedGreen, normalizedBlue);
  const isShadeOfGrey = minValue === maxValue;

  let hue, saturation;
  const luminosity = (maxValue + minValue) / 2;

  if (isShadeOfGrey) return [0, 0, luminosity];

  const range = maxValue - minValue;
  saturation =
    luminosity > 0.5
      ? range / (2 - maxValue - minValue)
      : range / (maxValue + minValue);

  switch (maxValue) {
    case normalizedRed:
      hue =
        (normalizedGreen - normalizedBlue) / range +
        (normalizedGreen < normalizedBlue ? 6 : 0);
      break;
    case normalizedGreen:
      hue = (normalizedBlue - normalizedRed) / range + 2;
      break;
    case normalizedBlue:
      hue = (normalizedRed - normalizedGreen) / range + 4;
      break;
    default: // default is mathematically impossible
      break; //   just calming ESlinter's nerves
  }

  hue /= 6;

  return [hue, saturation, luminosity];
};

/**
 * Converts HSL color values to RGB.
 *
 * @param {number} hue - The hue value [0, 1].
 * @param {number} saturation - The saturation value [0, 1].
 * @param {number} lightness - The lightness value [0, 1].
 * @returns {Array} The RGB representation [red, green, blue].
 */
export const hslToRgb = (hue, saturation, lightness) => {
  let red, green, blue;
  const isShadeOfGrey = saturation === 0;

  if (isShadeOfGrey) {
    red = green = blue = Math.round(lightness * 255);
    return [red, green, blue];
  }

  const highPivot =
    lightness < 0.5
      ? lightness * (1 + saturation)
      : lightness + saturation - lightness * saturation;
  const lowPivot = 2 * lightness - highPivot;

  red = hueToHexSingleColor(lowPivot, highPivot, hue + 1 / 3);
  green = hueToHexSingleColor(lowPivot, highPivot, hue);
  blue = hueToHexSingleColor(lowPivot, highPivot, hue - 1 / 3);

  return [
    Math.round(red * 255),
    Math.round(green * 255),
    Math.round(blue * 255),
  ];
};

/**
 * Lightens a hex color by a given amount.
 *
 * @param {string} hexColor - The hex color string (e.g., "#RRGGBB").
 * @param {number} amount - The amount to lighten [0, 1].
 * @returns {string} The lightened hex color string.
 */
export const lightenHexColor = (hexColor, amount) => {
  const hexWithoutHash = hexColor.replace('#', '');

  const sixDigitHex =
    hexWithoutHash.length === 3 ? hexWithoutHash.repeat(2) : hexWithoutHash;

  const red = parseInt(sixDigitHex.slice(0, 2), 16);
  const green = parseInt(sixDigitHex.slice(2, 4), 16);
  const blue = parseInt(sixDigitHex.slice(4, 6), 16);

  let [hue, saturation, lightness] = rgbToHsl(red, green, blue);

  lightness += (1 - lightness) * amount;
  if (lightness > 1) lightness = 1;

  const [newRed, newGreen, newBlue] = hslToRgb(hue, saturation, lightness);

  const RR = newRed.toString(16).padStart(2, '0');
  const GG = newGreen.toString(16).padStart(2, '0');
  const BB = newBlue.toString(16).padStart(2, '0');

  return `#${RR}${GG}${BB}`;
};

export const addOpacityToHexColor = (hex, opacity) => {
  const hexWithoutHash = hex.replace('#', '');

  const sixDigitHex =
    hexWithoutHash.length === 3 ? hexWithoutHash.repeat(2) : hexWithoutHash;

  const isOpacitybetweenOneAndHundred = opacity >= 0 && opacity <= 100;
  if (!isOpacitybetweenOneAndHundred) {
    throw new Error('Opacity must be between 0 and 100');
  }

  const hexaDecimalOpacity = Math.round(opacity * 2.55).toString(16);

  return `#${sixDigitHex}${hexaDecimalOpacity}`;
};

export const dateToString = (date) => {
  const dateFormat = typeof date === 'string' ? new Date(date) : date;
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0'); // January is 0, so we add 1
  const yyyy = dateFormat.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

export const stringToDate = (dateString) => {
  const [yyyy, mm, dd] = dateString.split('-');
  return new Date(yyyy, mm - 1, dd); // JavaScript months are 0-based, so subtract 1 from mm
};
