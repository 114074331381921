import { ReactComponent as RefreshSymbol } from './SVGs/RefreshSymbol.svg';

const RefreshButton = ({ isRefreshing, needsRefreshing, onClick }) => {
  return (
    <button
      type="button"
      className={`button-refresh-ratebase ${
        isRefreshing ? 'refreshing' : needsRefreshing ? 'able' : ''
      } ms-auto`}
      onClick={onClick}
      disabled={!needsRefreshing}
    >
      RECALCULAR PRÉMIO
      <RefreshSymbol />
    </button>
  );
};

export default RefreshButton;
