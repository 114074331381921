const CloseButtonMobile = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="12"
         height="12"
         viewBox="0 0 12 12"
         fill="none"
         onClick={ onClick }
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M0.29289 0.29289C0.68342 -0.09763 1.31658 -0.09763 1.70711 0.29289L6 4.58579L10.2929 0.29289C10.6834 -0.09763 11.3166 -0.09763 11.7071 0.29289C12.0976 0.68342 12.0976 1.31658 11.7071 1.70711L7.4142 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.4142L1.70711 11.7071C1.31658 12.0976 0.68342 12.0976 0.29289 11.7071C-0.09763 11.3166 -0.09763 10.6834 0.29289 10.2929L4.58579 6L0.29289 1.70711C-0.09763 1.31658 -0.09763 0.68342 0.29289 0.29289Z" 
            fill="#4A5568"
      />
    </svg>
  );
};

export default CloseButtonMobile;
