import { Modal } from "react-bootstrap";
import CloseButtonMobile from "../shared/CloseButtonMobile";

const VersionListModal = ({
  isOpen,
  setIsOpen,
  versionList,
  propertiesVersion,
  handleInputChange,
  formatPriceInEuros,
}) => {
  return (
    <Modal centered fullscreen={true} show={isOpen} animation={false}>
      <div className="mobile-version-main">
        <div className="mobile-version-header">
          <p className="mobile-version-title">Selecionar Versão</p>
          <div className="close-button-container">
            <CloseButtonMobile onClick={() => setIsOpen(false)} />
          </div>
        </div>
        <div className="mobile-version-container">
          {versionList.map(
            ({ version, tylaCode, cc, hp, doors, seats, price }) => (
              <div key={tylaCode}>
                <div className="row p-2 ">
                  <input
                    className={`col-1 mobile-version-radio`}
                    type="radio"
                    name="properties_version"
                    checked={propertiesVersion === tylaCode}
                    id={tylaCode}
                    onChange={(e) => {
                      handleInputChange(e);
                      setIsOpen(false);
                    }}
                  />
                  <label
                    className="col mobile-version-label"
                    htmlFor={tylaCode}
                  >
                    {version}
                  </label>
                </div>
                <div className="row bottom-line">
                  <div className="col">
                    <p className="fweight500">Cc</p>
                    <p className="fweight400">:</p>
                    <p className="fweight600">{` ${cc}`}</p>
                  </div>
                  <div className="col">
                    <p className="fweight500">CV</p>
                    <p className="fweight400">:</p>
                    <p className="fweight600">{` ${hp}`}</p>
                  </div>
                  <div className="col">
                    <p className="fweight500">Portas</p>
                    <p className="fweight400">:</p>
                    <p className="fweight600">{` ${doors}`}</p>
                  </div>
                  <div className="col">
                    <p className="fweight500">Lugares</p>
                    <p className="fweight400">:</p>
                    <p className="fweight600">{` ${seats}`}</p>
                  </div>
                  <div className="col">
                    <p className="fweight500">Valor</p>
                    <p className="fweight400">:</p>
                    <p className="fweight600">
                      {` ${formatPriceInEuros(price)}`}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VersionListModal;
