import {
  setItemLocalStorage,
  clearLocalStorage,
  AUTOKEY_TYPE,
  AUTOKEY_INVITE,
  AUTOKEY_QUOTEID,
  AUTOKEY_FINGERPRINT,
  AUTOKEY_X_FINGERPRINT,
  AUTOKEY_ACCESSTOKEN,
  AUTOKEY_SALESCHANNEL,
  AUTOKEY_DISTRIBUTORSELLERID,
  AUTOKEY_DISTRIBUTIONPOINTID,
  AUTOKEY_REDIRECT_URL,
} from '../../utils/helper';
import React, { Fragment, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);

  useEffect(() => {
    setisLoader(false);
    clearLocalStorage();
    const queryParameters = new URLSearchParams(window.location.search);
    const type = queryParameters.get('type');
    const invite = queryParameters.get('invite');
    const quote_id = queryParameters.get('quote_id');
    const fingerprint = queryParameters.get('fingerprint');
    const access_token = queryParameters.get('access_token');
    const sales_channel = queryParameters.get('sales_channel');
    const distributorseller_id = queryParameters.get('distributorseller_id');
    const distributionpoint_id = queryParameters.get('distributionpoint_id');

    const redirectURL = window.location.href;
    setItemLocalStorage(AUTOKEY_REDIRECT_URL, redirectURL);

    if (type === 'user' || type === 'lead' || type === 'seller') {
      setItemLocalStorage(AUTOKEY_TYPE, type);
      setItemLocalStorage(AUTOKEY_INVITE, invite);
      setItemLocalStorage(AUTOKEY_QUOTEID, quote_id);
      setItemLocalStorage(AUTOKEY_ACCESSTOKEN, access_token);
      setItemLocalStorage(AUTOKEY_SALESCHANNEL, sales_channel);
      setItemLocalStorage(AUTOKEY_DISTRIBUTORSELLERID, distributorseller_id);
      setItemLocalStorage(AUTOKEY_DISTRIBUTIONPOINTID, distributionpoint_id);

      setItemLocalStorage(
        type === 'user' ? AUTOKEY_FINGERPRINT : AUTOKEY_X_FINGERPRINT,
        fingerprint
      );
    } else {
      clearLocalStorage();
    }

    if (type === 'user') {
      navigate('/simulation-form');
    } else {
      navigate('/bio', {
        state: {
          component: 'Login',
        },
      });
    }
  }, [navigate]);

  return (
    <Fragment>
      <ToastContainer />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
    </Fragment>
  );
};

export default Login;
