import { checkTextBeforeDisplay } from '../../../utils/functions';
import DynamicColorSVG from './DynamicColorSVG';
import { useTheme } from '../../../contexts/ThemeContext';

const TileComponent = ({ TileSpecs, defaultLanguage }) => {
  const { theme } = useTheme();
  const iconColor = theme.colors['--color-tile-title'];
  const {
    tile_type,
    tile_icon,
    tile_title,
    tile_subtitle,
    tile_info_text,
    tile_bullet_points,
  } = TileSpecs;

  const applyCheckToText = (textReference) =>
    checkTextBeforeDisplay(
      theme.bio_sections.texts,
      textReference,
      defaultLanguage
    );
  const checkedTitle = applyCheckToText(tile_title);
  const checkedSubtitle = applyCheckToText(tile_subtitle);
  const checkedInfoText = applyCheckToText(tile_info_text);
  const checkedBulletPoints = tile_bullet_points.map((bullet) =>
    applyCheckToText(bullet)
  );

  const convertToBullet = (text) => {
    if (text.startsWith('*')) {
      return (
        <li className="tile-bullets" key={text}>
          {text.slice(1).trim()}
        </li>
      );
    }
    if (text.startsWith('+')) {
      return (
        <span className="custom-plus" key={text}>
          {text.slice(1).trim()}
        </span>
      );
    }
    return <p key={text}>{text}</p>;
  };

  return (
    <>
      <div className="d-flex flex-column d-md-none tile-container-mobile w-100">
        <div className="d-flex flex-row w-100">
          <DynamicColorSVG
            src={theme.images[tile_icon]}
            newFillColor={iconColor}
            size={40}
          />
          <div className="tile-title-mobile">{checkedTitle}</div>
        </div>
        <p className="tile-subtitle">{checkedSubtitle}</p>
        <p className="tile-info-text">{checkedInfoText}</p>
        {checkedBulletPoints.length > 0 && (
          <ul className="tile-bullets-container h-100 d-flex flex-column justify-content-start">
            {checkedBulletPoints.map((bullet) => convertToBullet(bullet))}
          </ul>
        )}
      </div>

      <div
        className={`tile-container${
          tile_type === 'half_width' ? ' half-tile' : ''
        } d-none d-md-flex flex-column`}
      >
        <div className="d-flex flex-row mb-2">
          <DynamicColorSVG
            src={theme.images[tile_icon]}
            newFillColor={iconColor}
            size={50}
          />
          <div className="tile-title">{checkedTitle}</div>
        </div>
        <p className="tile-subtitle">{checkedSubtitle}</p>
        <p className="tile-info-text">{checkedInfoText}</p>
        {checkedBulletPoints.length > 0 && (
          <ul className="tile-bullets-container h-100 d-flex flex-column justify-content-start">
            {checkedBulletPoints.map((bullet) => convertToBullet(bullet))}
          </ul>
        )}
      </div>
    </>
  );
};

export default TileComponent;
