
import React from 'react';

const LoadingStateInput = () => {
  return (
    <div className="form placeholder-glow">
      <span className="placeholder col-3"/>
      <span className="placeholder mt-3 col-12"/>
    </div>
  );
};

export default LoadingStateInput;