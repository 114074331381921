import { useState, useCallback, useMemo } from 'react';
import { arrayToCSV } from '../../../utils/functions';
import { DISCOUNT_STATES } from '../../../utils/constants';

const initalCalculation = {
  registrations: [],
  discountResult: null,
};

export const useRegistrations = () => {
  const [discount, setDiscount] = useState(null);
  const [registrations, setRegistrations] = useState([]);
  const [latestCalculation, setLatestCalculation] = useState(initalCalculation);

  const needsRefreshing = useMemo(() => {
    const removeUnfinished = (registrations) =>
      registrations.filter(
        (registration) => registration && !registration.includes('_')
      );
    const ref = arrayToCSV(
      removeUnfinished(latestCalculation?.registrations ?? [])
    );
    const state = arrayToCSV(removeUnfinished(registrations ?? []));

    return ref !== state;
  }, [registrations, latestCalculation]);

  const resetResultMessage = () => {
    setLatestCalculation((prevState) => ({
      ...prevState,
      discountResult: null,
    }));
  };

  const createNewList = () => {
    setRegistrations(['']);
    resetResultMessage();
  };

  const eraseList = () => {
    setRegistrations([]);
    resetResultMessage();
  };

  const addRegistration = useCallback(() => {
    if (registrations.length < 3) {
      setRegistrations((prev) => [...prev, '']);
    }
  }, [registrations.length]);

  const updateRegistration = (index, value) => {
    const upperCaseValue = value.toUpperCase();
    setRegistrations((prev) => {
      const newRegistrations = [...prev];
      newRegistrations[index] = upperCaseValue;
      return newRegistrations;
    });
    resetResultMessage();
  };

  const closeInput = (index) => {
    setRegistrations((prev) => {
      const isProperValue = prev[index] && !prev[index].includes('_');
      const newRegistrations = [...prev];
      newRegistrations.splice(index, 1);
      if (isProperValue) resetResultMessage();
      return newRegistrations;
    });
  };

  const updateResultMessage = useCallback(
    (discountResult, discount = 0) => {
      setLatestCalculation((before) => {
        const after = {
          registrations: registrations,
          discountResult: discountResult,
        };
        return after;
      });
      if (discountResult === DISCOUNT_STATES.SUCCESS && discount > 0)
        setDiscount(discount);
    },
    [registrations]
  );

  return {
    registrations,
    needsRefreshing,
    latestCalculation,
    discount,
    addRegistration,
    updateRegistration,
    createNewList,
    eraseList,
    closeInput,
    updateResultMessage,
  };
};
