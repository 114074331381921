import axios from 'axios';
import { baseRequestHandler } from './baseRequestHandler';
import { WHITELABEL_CONFIG_URL } from '../config.js';

const getURLToWhitelabel = (DOMAIN) => `${WHITELABEL_CONFIG_URL}/${DOMAIN}`;

axios.defaults.headers.common['Content-Type'] = 'application/json';

export const getWhitelabelSpecs = baseRequestHandler((location, params) =>
  axios.get(getURLToWhitelabel(location), { params })
);

export const getSVG = baseRequestHandler((src, params) =>
  axios.get(src, { params })
);

export const vehicleByRegistrationRequest = baseRequestHandler(
  ({ url, params, config }) => axios.post(url, params, config)
);

/*
This is an example of how to use the baseRequestHandler:
  const getData = baseRequestHandler((params) => axios.get("/api/", { params }));
  const dataResponse = await getData({ foo: "foo" });

  const getSomethingWithPayloadAndHeaders = (({params, config}) => axios.get("base/api/endpoint", params, config));
  const response = await getSomethingWithPayloadAndHeaders({ params: payload, config: { headers: headers } });
*/

export const fetchThemeAsync = async () => {
  const currentHostname = window.location.hostname;

  const { code, data } = await getWhitelabelSpecs(currentHostname);

  if (code === 'error') {
    console.error('Address mapping request error:', data.error.message);
    return null;
  }
  if (code === 'success') {
    const { fe_config: configVars, white_label: whitelabel } = data;
    const newTheme = {
      ...whitelabel,
      CONFIG: {
        ...configVars,
      },
    };
    return newTheme;
  }
  return null;
};

export const SubmitContract = baseRequestHandler((src, params) =>
  axios.post(src, { params })
);
